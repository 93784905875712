import { useState, useEffect } from 'react';

// Custom hook to get the transformed hostname
function useTransformedHostname() {
  const [transformedHostname, setTransformedHostname] = useState('');

  useEffect(() => {
    const hostname = window.location.hostname;

    // Set the title based on the URL
    if (hostname === 'bewerbung100.de') {
      setTransformedHostname('Bewerbung100');
    } else if (hostname === 'bewerbr.de') {
      setTransformedHostname('Bewerbr');
    }
  }, []);

  return transformedHostname;
}

export default useTransformedHostname;