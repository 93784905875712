import React from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Title from "../OverviewDashboard/Title";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const SubscriptionModal = ({ subscriptionModal, setSubscriptionModal }) => {


  const handleSubmit = () => {
    setSubscriptionModal(false);
  };

  return (
    <div
      style={{ zIndex: 1300, height: "100%" }}
      className="fixed z-auto bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    >
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-full sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <Box       sx={{
                  p: 2,
                  bgcolor: "background.paper",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
            <Title variant="h5" component="div">
              Upgrade zu Premium
            </Title>
            <HighlightOffIcon
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
              }}
              onClick={handleSubmit}
            ></HighlightOffIcon>
          </Box>
          <div className="flex flex-col">
            <Paper>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "background.paper",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">
                  Mit Premium kannst du unbegrenzt viele Bewerbungen für nur 10€/mtl.
                  versenden.
                </Typography>
                <br></br>
                <br></br>
                <br></br>
          

                <PayPalScriptProvider
                  options={{
                    "client-id":
                      "AYEuDDk7rYSLENJrDZkIa5UMlc45Qa6CovKgNPgFPgbdR_tTOy_UuKMYxq_rzR4kqbSPSX32-W4vNfPq",
                    vault: true,
                  }}
                >
                  <PayPalButtons
                    style={{ layout: "horizontal" }}
                    createSubscription={(data, actions) => {
                      return actions.subscription.create({
                        plan_id: "P-34V680717A0887325MQVW6FY",
                      });
                    }}
                    onApprove={(data, actions) => {
                      
            
                      


                      let tokenLS = localStorage.getItem("token");
                      let usernameLS = localStorage.getItem("username");

                      

                      fetch(`https://data.bewerbr.de/api/subscriptions/${usernameLS}/${data.subscriptionID}`, {
                        method: "POST",
                

                        headers: {
                          Authorization: `Bearer ${tokenLS}`,
                          "Content-Type": "application/json",
                        },
                      })
                        .then(() => handleSubmit())
                        .then(() => {
                          alert("Du hast dich erfolgreich für Premium angemeldet.");
                        })
                        .catch((error) => {
                          alert("Es gab einen Fehler bei der Transaktion. Bitte versuche es erneut.")
                          console.error("Error:", error);
                        });

                    
                        
                      
                      // Optional message given to subscriber
                      //   fetch('/api/subscriptions', { //zu spring boot senden
                      //     method: 'POST',
                      //     body: JSON.stringify({
                      //       subscriptionID: data.subscriptionID,
                      //       payerID: data.payerID
                      //     }),
                      //     headers: {
                      //       'Content-Type': 'application/json'
                      //     }
                      //   })
                      //     .then(response => response.json())
                      //     .then(data => {
                      //       // Handle the response from the server
                      //       if (data.success) {
                      //         // Show a success message to the user
                      //         alert('Subscription created successfully!');
                      //       } else {
                      //         // Show an error message to the user
                      //         alert('There was an error creating the subscription. Please try again.');
                      //       }
                      //     });
                    }}
                    onCancel={(data, actions) => {
                      alert("Zahlvorgang abgebrochen.");
                    }}
                    onError={(err) => {
                      console.error(err);
                      alert(
                        "Es gab einen Fehler bei der Transaktion. Bitte versuche es erneut."
                      );
                    }}
                  />
                </PayPalScriptProvider>
              </Box>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
