import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Footer from "../../footer/Footer";
import pic1 from "../../../profilepics/1.png";
import pic2 from "../../../profilepics/2.png";
import pic3 from "../../../profilepics/3.png";
import pic4 from "../../../profilepics/4.png";
import pic5 from "../../../profilepics/5.png";
import pic6 from "../../../profilepics/6.png";
import pic7 from "../../../profilepics/7.png";
import pic8 from "../../../profilepics/8.png";
import pic9 from "../../../profilepics/9.png";
import pic10 from "../../../profilepics/10.png";
import pic11 from "../../../profilepics/11.png";
import pic12 from "../../../profilepics/12.png";
import pic13 from "../../../profilepics/13.png";
import pic14 from "../../../profilepics/14.png";
import pic15 from "../../../profilepics/15.png";
import pic16 from "../../../profilepics/16.png";
import pic17 from "../../../profilepics/17.png";
import pic18 from "../../../profilepics/18.png";
import pic19 from "../../../profilepics/19.png";
import pic20 from "../../../profilepics/20.png";
import pic21 from "../../../profilepics/21.png";
import pic22 from "../../../profilepics/22.png";
import pic23 from "../../../profilepics/23.png";
import useTransformedHostname from "../../../helpers/useTransformedHostname";


const profilePics = [ pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10, pic11, pic12, pic13, pic14, pic15, pic16, pic17, pic18, pic19, pic20, pic21, pic22, pic23, ];


const names = [ "Anna", "Charlotte", "Benedikt", "David", "Elena", "Franziska", "Greta", "Irina", "Hans", "Johanna", "Katharina", "Lara", "Marie", "Nikolas", "Oliver", "Paul", "Quentin", "Wolfgang", "Simone", "Timo", "Ulf", "Vera", "Rajesh", ];
const theme = createTheme();

export default function Referenzen() {

    const transformedHostname = useTransformedHostname();

    const cards = [
      `${transformedHostname} hat mir dabei geholfen, meine Traumstelle zu finden!`,
      `Dank ${transformedHostname}  habe ich innerhalb kürzester Zeit eine neue Herausforderung gefunden.`,
      `Ich war von der Benutzerfreundlichkeit und den nützlichen Funktionen von ${transformedHostname}  begeistert. Das Hochladen meiner Bewerbungsunterlagen war kinderleicht.`,
      `${transformedHostname}  hat mir dabei geholfen, mein berufliches Glück zu finden. Das Portal ist übersichtlich und bietet viele hilfreiche Funktionen.`,
      `Ich war auf der Suche nach einer neuen Herausforderung und habe mich dazu entschieden, ${transformedHostname}  zu nutzen, um meine Bewerbungsunterlagen hochzuladen. Das Portal ist leicht zu navigieren und bietet viele nützliche Funktionen, um meine Bewerbung zu optimieren. Ich wurde tatsächlich zu einem Vorstellungsgespräch eingeladen und habe den Job bekommen – dank ${transformedHostname} ! Ich würde das Portal jedem empfehlen, der auf der Suche nach einer neuen Stelle ist.`,
      `Dank ${transformedHostname}  habe ich schnell und einfach eine neue Stelle gefunden. Das Portal ist benutzerfreundlich und hat mir dabei geholfen, meine Bewerbungsunterlagen zu optimieren.`,
      `Ich habe ${transformedHostname}  genutzt und war von Anfang an begeistert von der benutzerfreundlichen Oberfläche und den nützlichen Funktionen. Das Hochladen meiner Bewerbungsunterlagen war schnell und einfach und ich habe sogar personalisierte Tipps und Tricks für das Vorstellungsgespräch erhalten. Ich wurde tatsächlich zu einem Vorstellungsgespräch eingeladen und habe den Job bekommen! Ich würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einer neuen Herausforderung ist.`,
      `${transformedHostname}  war eine wahre Hilfe bei meiner Jobsuche. Das Portal ist leicht zu navigieren und bietet viele nützliche Funktionen, um meine Bewerbungsunterlagen zu optimieren. Ich habe positive Rückmeldung von meinem zukünftigen Arbeitgeber erhalten und würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einem neuen Job ist.`,
      `Ich bin seit Jahren auf der Suche nach einer neuen Herausforderung und habe mich dazu entschieden, ${transformedHostname}  zu nutzen, um meine Bewerbungsunterlagen hochzuladen. Das Portal ist leicht zu navigieren und die Funktionen sind sehr hilfreich, um meine Unterlagen zu optimieren und meine Chancen auf eine Einladung zum Vorstellungsgespräch zu erhöhen. Ich wurde tatsächlich zu einem Vorstellungsgespräch eingeladen und bin jetzt auf dem Weg zu meiner Traumstelle.`,
      `Ich habe ${transformedHostname}  genutzt, um mich um eine neue Stelle zu bewerben und war begeistert von der Benutzerfreundlichkeit und den nützlichen Funktionen, die das Portal bietet. Das Design ist modern und ansprechend und ich konnte meine Bewerbung schnell und einfach hochladen. Ich habe positive Rückmeldung von meinem zukünftigen Arbeitgeber erhalten und würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einem neuen Job ist.`,
      `${transformedHostname}  war eine wertvolle Unterstützung bei meiner Jobsuche. Das Portal ist benutzerfreundlich und bietet viele nützliche Funktionen, um meine Bewerbungsunterlagen zu optimieren. Ich habe positive Rückmeldung von meinem zukünftigen Arbeitgeber erhalten und würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einem neuen Job ist.`,
      `Dank ${transformedHostname}  habe ich schnell eine neue Stelle gefunden. Das Portal ist leicht zu navigieren und bietet hilfreiche Funktionen wie Tipps und Tricks für das Vorstellungsgespräch. Ich würde ${transformedHostname}  jedem empfehlen, der sich auf der Suche nach einer neuen Herausforderung befindet.`,
      `Ich habe ${transformedHostname}  genutzt, um mich um eine neue Stelle zu bewerben und war von Anfang an von der Benutzerfreundlichkeit und den nützlichen Funktionen begeistert. Das Hochladen meiner Bewerbungsunterlagen war schnell und einfach und ich habe sogar personalisierte Tipps und Tricks für das Vorstellungsgespräch erhalten. Ich wurde tatsächlich zu einem Vorstellungsgespräch eingeladen und habe den Job bekommen! Ich würde ${transformedHostname}  jedem empfehlen.`,
      `${transformedHostname}  hat mir dabei geholfen, den perfekten Job zu finden. Das Portal ist leicht zu navigieren und bietet viele nützliche Funktionen, um meine Bewerbungsunterlagen zu optimieren. Ich wurde zu einem Vorstellungsgespräch eingeladen und habe den Job bekommen – ich könnte nicht glücklicher sein! Ich würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einer neuen Herausforderung ist.`,
      `Ich war auf der Suche nach einer neuen Herausforderung und habe ${transformedHostname}  genutzt, um mich zu bewerben. Das Portal war leicht zu navigieren und ich habe schnell meine Unterlagen hochladen können. Ich habe auch von den nützlichen Tipps und Tricks profitiert, die das Portal angeboten hat. Am Ende habe ich eine Einladung zum Vorstellungsgespräch erhalten und den Job bekommen. Ich würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einem neuen Job ist.`,
      `Ich habe ${transformedHostname}  genutzt, um mich um eine neue Stelle zu bewerben und war von der Benutzerfreundlichkeit und den nützlichen Funktionen begeistert. Das Hochladen meiner Bewerbungsunterlagen war schnell und einfach und ich habe sogar personalisierte Tipps und Tricks für das Vorstellungsgespräch erhalten. Ich habe positive Rückmeldung von meinem zukünftigen Arbeitgeber erhalten und würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einem neuen Job ist.`,
      `Ich bin seit Jahren auf der Suche nach einer neuen Herausforderung und habe mich dazu entschieden, ${transformedHostname}  zu nutzen, um meine Bewerbungsunterlagen hochzuladen. Das Portal ist leicht zu navigieren und die Funktionen sind sehr hilfreich, um meine Unterlagen zu optimieren und meine Chancen auf eine Einladung zum Vorstellungsgespräch zu erhöhen. Ich wurde tatsächlich zu einem Vorstellungsgespräch eingeladen und bin jetzt in einem neuen Job, den ich liebe.`,
      `${transformedHostname}  war eine wertvolle Unterstützung bei meiner Jobsuche. Das Portal ist benutzerfreundlich und bietet viele nützliche Funktionen, um meine Bewerbungsunterlagen zu optimieren. Ich habe positive Rückmeldung von meinem zukünftigen Arbeitgeber erhalten und würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einem neuen Job ist.`,
      `Dank ${transformedHostname}  habe ich endlich den Job meiner Träume gefunden! Das Portal war eine große Hilfe bei der Optimierung meiner Bewerbungsunterlagen und hat mir sogar Tipps für das Vorstellungsgespräch gegeben. Ich würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einer neuen beruflichen Herausforderung ist.`,
      `Ich war auf der Suche nach einer neuen Stelle und ${transformedHostname}  hat mir dabei geholfen, meine Bewerbungsunterlagen zu optimieren und mich erfolgreich zu bewerben. Das Portal ist benutzerfreundlich und bietet viele nützliche Funktionen. Ich würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einem neuen Job ist.`,
      `Ich bin begeistert von ${transformedHostname} ! Das Portal hat mir dabei geholfen, meine Bewerbungsunterlagen zu optimieren und mich erfolgreich zu bewerben. Ich wurde zu einem Vorstellungsgespräch eingeladen und habe den Job bekommen – ich könnte nicht glücklicher sein! Ich würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einer neuen Stelle ist.`,
      `Ich bin dankbar für ${transformedHostname} , das mir dabei geholfen hat, den perfekten Job zu finden. Das Portal ist leicht zu navigieren und bietet viele nützliche Funktionen, um meine Bewerbungsunterlagen zu optimieren. Ich habe sogar personalisierte Tipps und Tricks für das Vorstellungsgespräch erhalten. Ich würde ${transformedHostname}  jedem empfehlen, der auf der Suche nach einer neuen Herausforderung ist.`,
      `${transformedHostname}  hat mich bei meiner Jobsuche unterstützt und ich bin dankbar dafür. Das Portal ist benutzerfreundlich und bietet viele nützliche Funktionen, um meine Bewerbungsunterlagen zu optimieren. Ich wurde zu einem Vorstellungsgespräch eingeladen und habe den Job bekommen – ich bin sehr zufrieden mit ${transformedHostname}  und würde es jedem empfehlen.`,
    ];
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
  
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Wie waren deine Erfahrungen mit Bewerbung 100?
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Wir haben die Bewerbung für dich vereinfacht. Du musst nur noch
              deine Daten eingeben und wir kümmern uns um den Rest. Sind deine
              Daten einmal in unserem System, kannst du hunderte Bewerbunge mit
              nur einem Klick versenden.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="outlined">       <Link to="login">Jetzt Registieren</Link></Button>
     
     <Button variant="outlined">        <Link to="dashboard" >Zum Profil</Link></Button>

            </Stack>
          </Container>
        </Box>

        <Container sx={{ py: 8 }} maxWidth="md">
          <Grid container spacing={4}>
            {cards.map((card, index) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h3"
                    align="center"
                    color="text.secondary"
                    paragraph
                  >
                    {names[index]}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: "36.25%",
                    }}
                    image={profilePics[index]}
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
               
                    <Typography>{card}</Typography>
                  </CardContent>
           
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Schreibe auch einen Kommentar:
        </Typography>
        <TextField
          style={{ width: "90%" }}
          id="outlined-multiline-static"
          label="Kommentar"
          multiline
          rows={5}
          variant="outlined"
        />

        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
        </Typography>
      </Box>
      <Button style={{ width: "100%" }} variant="contained">
        Absenden
      </Button>

      <Router>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
