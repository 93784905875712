import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';

/* import {IntlProvider} from 'react-intl' */
import Wrapper from "./components/Wrapper"
/* 
import 'bootstrap/dist/css/bootstrap.min.css'; */
ReactDOM.render(
  <GoogleOAuthProvider clientId="987614504258-gmlhbnj67s3cujvia64vitvi6eeg3h45.apps.googleusercontent.com">

  <React.StrictMode>
    <Wrapper>
    <App />

    </Wrapper>
  </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
