//https://github.com/mui/material-ui/tree/v5.10.12/docs/data/material/getting-started/templates/dashboard
import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Orders from "../Orders";

import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

import UserProfile from "../UserProfile/UserProfile";
import Copyright from "../CopyRight";
import ApplicationTextPreview from "../ApplicationText/ApplicationTextPreview";
import SingleApplicationSender from "../SingleApplication/SingleApplicationSender";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PremiumAd from "../Premium/PremiumAd";
import MultiApplicationPaper from "../MultiApplication/MultiApplicationPaper";
import ApiService from "../../APIService";
import defaultProfilePic from "../../../profilepics/defaultprofilepic.png";
import { returnUsernameFromJWT } from "../../../helpers/jwt";
import DarkModeToggler from "../../general/DarkModeToggler";
import Box from "@mui/material/Box";
import CVGenerator from "../CVGenerator/CVGenerator";
import EmailTextBox from "../EmailTextBox/EmailTextBox";
import InfoModal from "../../../helpers/NotificationModals/InfoModal";
// import SimpleTestComponent from "../CVGenerator/SimpleTest";
// import CvPreview from "../CVGenerator/CVPreview";

/* 
import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

import { mainListItems, secondaryListItems } from '../listItems';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TemporaryDrawer from './Drawer';
import EmailIcon from '@mui/icons-material/Email';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

*/

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#81c784", // Light theme primary color
      // add light, dark, and contrastText if needed
    },
    secondary: {
      main: "#81c784", // Light theme secondary color
      // add light, dark, and contrastText if needed
    },
    // ...other colors like error, warning, info, success
  },
  // ...other theme customizations
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#388e3c", // Dark theme primary color
      // add light, dark, and contrastText if needed
    },
    secondary: {
      main: "#388e3c", // Dark theme secondary color
      // add light, dark, and contrastText if needed
    },
    // ...other colors like error, warning, info, success
  },
  // ...other theme customizations
});

/* 
const drawerWidth = 150;


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      height: drawerHeight,

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'auto',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

 */

function DashboardContent() {
  /*  const [open, setOpen] = React.useState(true);
   */
  const [userData, setUserData] = useState({});
  const [isPremium, setIsPremium] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [advice, setAdvice] = useState([]);
  const [userProfilePic, setUserProfilePic] = useState(null);

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  // Function to open the modal
  //const openInfoModal = () => setInfoModalOpen(true);

  const openInfoModal = (title, content, tipp) => {
    document.body.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    document.body.style.overflow = 'hidden';
        setModalState({
      open: true,
      title,
      content,
      tipp,
    });
  };

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    content: "",
    tipp: "",
  });

  //######################       Expanded Cards           #################################

  const [expandedUserProfile, setExpandedUserProfile] = useState(false);
  const [expandedApplicationText, setExpandedApplicationText] = useState(false);
  const [expandedSendSingleApplication, setExpandedSendSingleApplication] =
    useState(false);
  const [expandedCVGenerator, setExpandedCVGenerator] = useState(false);
  const [expandedEmailTextBox, setExpandedEmailTextBox] = useState(false);
  const [expandedMyApplications, setExpandedMyApplications] = useState(false);
  const [expandedPremiumAdd, setExpandedPremiumAdd] = useState(false);
  const [expandedApplicationsManagement, setExpandedApplicationsManagement] =
    useState(true);

  /**
   *
   * TODO: HANDLER RAUSNEHMEN UND ERSETZEN MIT setter with bei expandedApplicationsManagement
   *
   *  */
  const handleUserExpandClick = () => {
    setExpandedUserProfile(!expandedUserProfile);
  };

  const handleApplicationTextExpandClick = () => {
    setExpandedApplicationText(!expandedApplicationText);
  };

  const handleSendSingleApplicationExpandClick = () => {
    setExpandedSendSingleApplication(!expandedSendSingleApplication);
  };

  const handleCVGeneratorExpandClick = () => {
    setExpandedCVGenerator(!expandedCVGenerator);
  };

  const handleEmailTextBoxExpandClick = () => {
    setExpandedEmailTextBox(!expandedEmailTextBox);
  };

  const handleMyApplicationsExpandClick = () => {
    setExpandedMyApplications(!expandedMyApplications);
  };

  const handlePremiumAddExpandClick = () => {
    setExpandedPremiumAdd(!expandedPremiumAdd);
  };

  const updateUserProfilePic = async () => {
    let token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    setTimeout(() => {
      try {
        let url = `https://data.bewerbr.de/downloadFile/ProfilePicture_${returnUsernameFromJWT()}.jpg`;
        fetch(url, requestOptions)
          .then((response) => {
            if (response.status === 404) {
              setUserProfilePic(defaultProfilePic);
              throw new Error("Profile picture not found");
            }
            return response.blob();
          })
          .then((blob) => {
            //         if (blob.size > 100) {
            const url = URL.createObjectURL(blob);
            setUserProfilePic(url);
            /*             } else {
              setUserProfilePic(defaultProfilePic);
            } */
          })
          .then(() => {
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      } catch (error) {
        setUserProfilePic(null);
        console.error(error);
        setLoading(false);
      }
    }, 20000);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const updateUserData = async () => {
    const user = await ApiService.getUserData(returnUsernameFromJWT());
    setUserData(user.data);
    setIsPremium(user.data.user.isPremium);
    setSubscriptionID(user.data.user.subscriptionID);
    setIsActive(user.data.user.enabled);
  };

  useEffect(() => {
    updateUserData();
    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }

    ApiService.getAllApplicationsByUsername().then((data) => {
      data.data.map((advices) => {
        //if (advices.email !== "Trage die Email manuell ein") {
        setAdvice((advice) => [...advice, advices]);
        // }
      });

      updateUserProfilePic();
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  useEffect(() => {
    // Check if userData is defined before proceeding
    if (!userData) {
      return;
    }

    const iframe = document.getElementById("iframe");

    const data = {
      type: "yourCustomEventType",
      payload: {
        darkMode: localStorage.getItem("darkMode"),
        username: localStorage.getItem("username"),
        firstname: userData.firstname,
        lastname: userData.lastname,
        plz: userData.plz,
        street: userData.street,
        city: userData.city,
      },
    };

    function sendMessage() {
      if (iframe) {
        const targetOrigin = "https://lebenslaufgenerator.netlify.app"; // Update the target origin to match the Vue app's origin
        iframe.contentWindow.postMessage(data, targetOrigin);
      }
    }

    if (iframe) {
      iframe.addEventListener("load", sendMessage);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", sendMessage);
      }
    };
  }, [darkMode, userData]);

  /*   const toggleDrawer = () => {
    setOpen(open); //!open zum activieren --> open für mobil !open für pc
  };
 */
  const toUserProfile = () => {
    window.scroll({
      top: 100,
      behavior: "smooth",
    });
  };

  toUserProfile();

  return (
    <div id="userdashboard">
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* <CssBaseline /> */}

          {/*           <Drawer style={{ position: "fixed" }} variant="permanent" open={open}>
          <Divider />
            <Toolbar
              sx={{
                
                display: 'flex',
                alignItems: 'center',
                px: [1],

              }}
            >
               <Divider />
              <IconButton  >
              <Divider />

                <WidgetsIcon />
              </IconButton>

      


            </Toolbar>
            <Divider />

     
            <List component="nav" style={{ width: "100%" }}>
            <IconButton onClick={() => {
                document.getElementById('userprofileBox').scrollIntoView({
                  behavior: 'smooth'
                })
              }} >
                <AccountCircleIcon />
                <ListItemText primary="&nbsp;&nbsp;Profil"/>
              </IconButton>
              <Divider />
             <IconButton onClick={() => {
              document.getElementById('applicationTextPreviewBox').scrollIntoView({
                behavior: 'smooth'
              })
            }} >
              <AssignmentIcon />
              <ListItemText primary="&nbsp;&nbsp;Anschreiben"/>
            </IconButton>
            <Divider />
            <IconButton onClick={() => {
              document.getElementById('sendSingleApplication').scrollIntoView({
                behavior: 'smooth'
              })
            }} >
              <EmailIcon />
              <ListItemText primary="&nbsp;&nbsp;Senden"/>

            </IconButton>
              <Divider />
              <IconButton onClick={() => {
              document.getElementById('myApplicationsBox').scrollIntoView({
                behavior: 'smooth'
              })
            }} >
              
              <FormatListBulletedIcon />
              <ListItemText primary="&nbsp;&nbsp;Übersicht"/>

            </IconButton>
              
          


            </List>
          </Drawer> */}

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : "#1b1b1b",
              flexGrow: 1,
              height: "100%",
              overflowX: "hidden",
            }}
          >
            <DarkModeToggler
              darkMode={darkMode}
              toggleDarkMode={toggleDarkMode}
            />

            {/*   <Toolbar /> */}
            <Container maxWidth="lg" sx={{ mt: 6, mb: 6 }}>
              {!isActive && !loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "warning.main",
                    color: "white",
                    p: 2,
                    borderRadius: 1,
                    marginBottom: 2,
                  }}
                >
                  <Typography align="center">
                    Bitte bestätige innerhalb von 7 Tagen deine Email - dein
                    Account wird sonst deaktiviert
                  </Typography>
                </Box>
              ) : null}

              <Grid container spacing={3}>
              
                  <Grid id="userprofileBox" item xs={12}>
                    <Paper
                      style={{
                        backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
                      }}
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                        </div>

                        {/* IconButton aligned to the right */}
                        <IconButton
                          aria-label="info"
                          onClick={() =>
                            openInfoModal(
                              "Nutzerprofil",
                              "Dein Profil sollte vollständig ausgefüllt sein. Achte darauf, dass du Profilbild, Lebenslauf und Anlagen hochgeladen hast und eine Outlook Email Adresse hinterlegt ist.",
                              "Beachte: Zur Zeit sind nur Email über Outlook möglich"
                            )
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                        <InfoModal
                          title={modalState.title}
                          content={modalState.content}
                          tipp={modalState.tipp}
                          open={modalState.open}
                          setOpen={(isOpen) =>
                            setModalState({ ...modalState, open: isOpen })
                          }
                          darkMode={darkMode}
                          
                        />
                      </div>

                      <UserProfile
                        userProfilePic={userProfilePic}
                        setUserProfilePic={setUserProfilePic}
                        loading={loading}
                        setLoading={setLoading}
                        userData={userData}
                        setUserData={setUserData}
                        darkMode={darkMode}
                        darkTheme={darkTheme}
                        lightTheme={lightTheme}
                      ></UserProfile>
                    </Paper>
             
                </Grid>

                <Grid id="applicationTextPreviewBox" item xs={12}>
                  <Paper
                    style={{
                      backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
                    }}
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Place components that should be on the left side here */}
                      <div>
                        {/* This div can contain elements that should stay on the left or center */}
                      </div>

                      {/* IconButton aligned to the right */}
                      <IconButton
                        aria-label="info"
                        onClick={() =>
                          openInfoModal(
                            "Anschreiben",
                            "Dies ist dein generisches Anschreiben.",
                            "Tipp: Trage das Wort 'ARBEITGEBERNAME' ein, um den Namen des Arbeitgebers in deinem Anschreiben zu personalisieren."
                          )
                        }
                      >
                        <InfoIcon />
                      </IconButton>

                      <InfoModal
                        title={modalState.title}
                        content={modalState.content}
                        tipp={modalState.tipp}
                        open={modalState.open}
                        setOpen={(isOpen) =>
                          setModalState({ ...modalState, open: isOpen })
                        }
                        darkMode={darkMode}
                      />
                    </div>
                    <ApplicationTextPreview
                      userProfilePic={userProfilePic}
                      setUserProfilePic={setUserProfilePic}
                      loading={loading}
                      setLoading={setLoading}
                      userData={userData}
                      setUserData={setUserData}
                      darkMode={darkMode}
                      darkTheme={darkTheme}
                      lightTheme={lightTheme}
                    ></ApplicationTextPreview>
                  </Paper>
                </Grid>

                <Grid id="emailTextBox" item xs={12}>
                  <Paper
                    style={{
                      backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
                    }}
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Place components that should be on the left side here */}
                      <div>
                        {/* This div can contain elements that should stay on the left or center */}
                      </div>

                      {/* IconButton aligned to the right */}
                      <IconButton
                        aria-label="info"
                        onClick={() =>
                          openInfoModal(
                            "Email",
                            "Dies ist der Emailtext, welcher mit einer PDF (welche Anschreiben, Lebenslauf und Anlangen enthält) an die Personalabteilung gesendet wird.",
                            "Tipp: Trage das Wort 'BERUFSBEZEICHNUNG' ein, um die Berufsbezeichnung in deiner Email zu personalisieren."
                          )
                        }
                      >
                        <InfoIcon />
                      </IconButton>

                      <InfoModal
                        title={modalState.title}
                        content={modalState.content}
                        tipp={modalState.tipp}
                        open={modalState.open}
                        setOpen={(isOpen) =>
                          setModalState({ ...modalState, open: isOpen })
                        }
                        darkMode={darkMode}
                      />
                    </div>
                    <EmailTextBox
                      darkMode={darkMode}
                      userData={userData}
                      setUserData={setUserData}
                    ></EmailTextBox>
                  </Paper>
                </Grid>

                {/* Recent Orders */}
                <Grid
                  id="myApplicationsBox"
                  item
                  xs={12}
                  style={{ width: "500px" }}
                >
                  <Paper
                    style={{
                      backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
                    }}
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Place components that should be on the left side here */}
                      <div>
                        {/* This div can contain elements that should stay on the left or center */}
                      </div>

                      {/* IconButton aligned to the right */}
                      <IconButton
                        aria-label="info"
                        onClick={() =>
                          openInfoModal(
                            "Bewerberboard",
                            "Manage deine Bewerbungen im Bewerberboard",
                            "Tipp: Teste deine Bewerbungsunterlagen, indem du sie an deine eigene E-Mail-Adresse schickst."
                          )
                        }
                      >
                        <InfoIcon />
                      </IconButton>

                      <InfoModal
                        title={modalState.title}
                        content={modalState.content}
                        tipp={modalState.tipp}
                        open={modalState.open}
                        setOpen={(isOpen) =>
                          setModalState({ ...modalState, open: isOpen })
                        }
                        darkMode={darkMode}
                      />
                    </div>
                    <Orders
                      advice={advice}
                      setAdvice={setAdvice}
                      userData={userData}
                      setUserData={setUserData}
                      darkMode={darkMode}
                      darkTheme={darkTheme}
                      lightTheme={lightTheme}
                    />
                  </Paper>
                </Grid>

                <Grid id="sendSingleApplication" item xs={12}>
                  <Paper
                    style={{
                      backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
                    }}
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Place components that should be on the left side here */}
                      <div>
                        {/* This div can contain elements that should stay on the left or center */}
                      </div>

                      {/* IconButton aligned to the right */}
                      <IconButton
                        aria-label="info"
                        onClick={() =>
                          openInfoModal(
                            "Anschreiben über künstliche Intelligenz",
                            "Lasse dein Anschreiben aus perfekt zugeschnitten aus deinem Lebenslauf und der Stellenbeschreibung erstellen",
                            "Tipp: Es werden nur ungesendete Bewerbungen aus deinem Bewerberboard mit Emails angezeigt!"
                          )
                        }
                      >
                        <InfoIcon />
                      </IconButton>

                      <InfoModal
                        title={modalState.title}
                        content={modalState.content}
                        tipp={modalState.tipp}
                        open={modalState.open}
                        setOpen={(isOpen) =>
                          setModalState({ ...modalState, open: isOpen })
                        }
                        darkMode={darkMode}
                      />
                    </div>
                    <SingleApplicationSender
                      advice={advice}
                      userData={userData}
                      setUserData={setUserData}
                      darkMode={darkMode}
                      darkTheme={darkTheme}
                      lightTheme={lightTheme}
                    ></SingleApplicationSender>
                  </Paper>
                </Grid>

                <Grid
                  id="myApplicationsBox"
                  item
                  xs={12}
                  style={{ width: "500px" }}
                >
                  <Paper
                    style={{
                      backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
                    }}
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Place components that should be on the left side here */}
                      <div>
                        {/* This div can contain elements that should stay on the left or center */}
                      </div>

                      {/* IconButton aligned to the right */}
                      <IconButton
                        aria-label="info"
                        onClick={() =>
                          openInfoModal(
                            "Multibewerbung",
                            "Sende alle deine Bewerbung aus dem Bewerberboard mit einem Klick!",
                            "Tipp: Es werden nur Emails angezeigt, welche auch eine Email enthalten!"
                          )
                        }
                      >
                        <InfoIcon />
                      </IconButton>

                      <InfoModal
                        title={modalState.title}
                        content={modalState.content}
                        tipp={modalState.tipp}
                        open={modalState.open}
                        setOpen={(isOpen) =>
                          setModalState({ ...modalState, open: isOpen })
                        }
                        darkMode={darkMode}
                      />
                    </div>
                    <MultiApplicationPaper
                      userData={userData}
                      darkMode={darkMode}
                      darkTheme={darkTheme}
                      lightTheme={lightTheme}
                    ></MultiApplicationPaper>
                  </Paper>
                </Grid>

                {/*                 <Grid id="cvGeneratorBox" item xs={12}>
                  <Paper
                    style={{
                      backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
              
                    }}                    sx={{ p: 2, display: "flex", flexDirection: "column" }}

                  >
                 
                 <iframe id="iframe" style={{height:"1500px"}} src="https://lebenslaufgenerator.netlify.app" title="Lebenslaufgenerator" />
                  </Paper>
                </Grid> */}

                {/* <Grid id="cvGeneratorBox" item xs={12}>
                <Card aria-label="show more">
                  <CardHeader
                    title="Lebenslaufgenerator"
                    subheader="Du hast noch keinen Lebenslauf? Klicke dir deinen Lebenslauf schnell und einfach zusammen"
                    onClick={handleCVGeneratorExpandClick} // This function needs to be defined to handle the expand click
                    style={{
                      backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
              
                    }}                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    
                    action={
                      <ExpandMoreIcon
                        sx={{
                          transform: expandedCVGenerator
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.3s",
                        }}
                      />
                    }
                  />
                  <Collapse
                    in={expandedCVGenerator}
                    timeout="auto"
                    unmountOnExit
                  >
                    <iframe
                      id="iframe"
                      style={{ height: "1500px", width: "100%" }}
                      src="https://lebenslaufgenerator.netlify.app"
                      title="Lebenslaufgenerator"
                    />
                  </Collapse>
                </Card>
              </Grid> */}

                <Grid id="premiumAdd" item xs={12} style={{ width: "500px" }}>
                  <PremiumAd
                    darkMode={darkMode}
                    darkTheme={darkTheme}
                    lightTheme={lightTheme}
                    isPremium={isPremium}
                    subscriptionID={subscriptionID}
                  ></PremiumAd>
                </Grid>

                {/*           <Grid id="chartBox" item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid>
             
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits />
                </Paper>
              </Grid> */}
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
