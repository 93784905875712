import React, { useState } from "react";
import "../../../tailwind.css";
import ApiService from "../../APIService";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Cookies from "js-cookie";
import "./FileInputStyle.css";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { Collapse } from "@mui/material";
/* import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader"; */
import OutlookIcon from "./Outlook.svg";
import GMXIcon from "./gmx_icon.png";
import lebenslauf from "./lebenslauf.png";
import anlagen from "./anlagen.png";

// File Upload imagebutton
//https://codesandbox.io/s/lgqwn?file=/src/FileUpload/FileUpload.tsx
//
import useStyles from "../../general/Styles/MultiApplicationSenderTableStyles";
import { ThemeProvider } from "@mui/material/styles";

import Modal from "@mui/material/Modal";

/* const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
})); */

const useStyles2 = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    alignItems: "center",
    top: "0",
          overflowY: "scroll",


    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      alignItems: "center",
      justifyContent: "center",
      overflowX: "hidden",
      padding: theme.spacing(1),
      top: "0",

    },


  },
  box: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
    display: "flex",
    width: "50%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  //  overflowY: "scroll",

    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "0",
      marginRight: "0",
      overflowY: "scroll",
      overflowX: "hidden",
      padding: theme.spacing(1),
    },
  },
}));

const UserDataModal = ({ msg, userData, userEditor, setUserEditor, setUserData, darkMode, darkTheme, lightTheme, }) => {



  

  const classesChooseEmailProvider = useStyles2();
  const [showModal, setShowModal] = useState(true);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  const saveFilesToApi = async () => {
    var formdataCV = new FormData();
    formdataCV.append("file", selectedFile);
  
    let formdataAttach = new FormData();
    formdataAttach.append("file", selectedAttachment);
  
    // create an array to hold the promises
    let promises = [];
  
    // Variable to check if at least one file is selected
    let isFileSelected = false;
  
    if (selectedFile) {
      // push the promises into the array
      promises.push(ApiService.uploadCV(formdataCV));
      isFileSelected = true;
    }
  
    if (selectedAttachment) {
      // push the promises into the array
      promises.push(ApiService.uploadAttachmentsAPICall(formdataAttach));
     // isFileSelected = true;
    }
  
    try {
      // await all promises
      await Promise.all(promises);
      // if everything is successful and at least one file is selected, reload the page
      if (isFileSelected) {

        setTimeout(() => {
        window.location.reload();
        }, 2000);
      }
    } catch (err) {
      // handle the error as needed
      // console.log(err);
    }
  };
  

  const cancelModal = () => {
    setShowModal(false);
    setUserEditor(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      id: userData.id,
      username: userData.username,
      plz: data.get("plz"),
      city: data.get("ort"),
      street: data.get("strasse"),
      firstname: data.get("firstName"),
      lastname: data.get("lastName"), 
      phonenumber: data.get("phone"),
      cv: userData.cv,
      emailText: userData.emailText,
      applicationTextParagraph1: userData.applicationTextParagraph1,
      applicationTextParagraph2: userData.applicationTextParagraph2,


    };


  if (data.get("email")) {
    raw.outlookEmail = data.get("email");
    Cookies.set("senderEmail", data.get("email"));
  }

  if (data.get("password")) {
    raw.outlookPassword = data.get("password");
    Cookies.set("senderPasswort", data.get("password"));
  }

    Cookies.set("provider", "outlook");

    if (!data.get("phone")) {
      raw.phone = userData.phonenumber;
    }
    if (!data.get("plz")) {
      raw.plz = userData.plz;
    }

    if (!data.get("ort")) {
      raw.city = userData.city;
    }

    if (!data.get("strasse")) {
      raw.street = userData.street;
    }
    if (!data.get("firstName")) {
      raw.firstname = userData.firstname;
    }
    if (!data.get("lastName")) {
      raw.lastname = userData.lastname;
    }
   
    if (!data.get("cv")) {
      raw.cv = userData.cv;
    }
    if (!data.get("attachments")) {
      raw.attachments = userData.attachments;
    }
    setUserData(raw);
    raw = JSON.stringify(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://data.bewerbr.de/api/v1/employee/e1/updateEmployee",
      requestOptions
    )
      .then((response) => response.text())
      .catch((error) => console.log("error", error));

    saveFilesToApi();
    cancelModal();
    setUserEditor(false);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {showModal ? (
        <>
          <Modal
            open={true}
            aria-labelledby="exampleModalScrollableLabel"
            aria-hidden={true}
            className={classesChooseEmailProvider.root}
            id="exampleModalScrollable"
            tabIndex={-1}
          >
            <Box
              style={{ backgroundColor: `${darkMode ? "black" : "white"}` }}
              className={classesChooseEmailProvider.box}
              component="form"
              noValidate
              onSubmit={handleSubmit}
            >
              <Typography
                style={{ color: `${darkMode ? "white" : "black"}` }}
                component="h1"
                variant="h5"
              >
                Vervollständige dein Profil, um automatisch Bewerbungen zusenden
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    fullWidth
                    id="firstName"
                    label="Vorname"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="lastName"
                    label="Nachname"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="Straße"
                    label="Straße"
                    name="strasse"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="plz"
                    required
                    fullWidth
                    id="Plz"
                    label="plz"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="Stadt"
                    label="Stadt"
                    name="ort"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography
                    style={{
                      justifyContent: "center",
                      color: `${darkMode ? "white" : "black"}`,
                    }}
                    component="h1"
                    variant="h5"
                  >
                    Verbinde deine Email
                  </Typography>
                </Grid>


                <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <IconButton
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    className={classesChooseEmailProvider.button}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: 151,
                      }}
                      image={OutlookIcon}
                      alt="Outlook"
                    />
                  </IconButton>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <IconButton
                    expand={expanded}
                    onClick={() => alert("GMX wird in Kürze verfügbar sein!")}
                    aria-expanded={expanded}
                    aria-label="show more"
                    className={classesChooseEmailProvider.button}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: 151,
                      }}
                      image={GMXIcon}
                      alt="GMX"
                    />
                  </IconButton>
                </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  </Collapse>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Collapse>
                </Grid>

                {/*                         <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="phone"
                            label="Telefon"
                            name="phone"
                            autoComplete="phone"
                          />
                        </Grid> */}

                {/*  <Grid item xs={12} sm={12}>
       <Card
      sx={{
        maxWidth: "100%",
      }}
    >
      <CardHeader
        avatar={
          <IconButton
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            className={classesChooseEmailProvider.button}
          >
            <CardMedia
              component="img"
              sx={{
                width: 151,
              }}
              image={OutlookIcon}
              alt="Outlook"
            />
          </IconButton>
        }
        title="Outlook"
        subheader="Sende deine Email mit Outlook"
      />
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
        </Collapse>     

      </Card>



                </Grid> */}


                                <Grid item xs={12} sm={12}>
                  <Typography
                    style={{
                      justifyContent: "center",
                      color: `${darkMode ? "white" : "black"}`,
                    }}
                    component="h1"
                    variant="h5"
                  >
                    Lade deine Anlagen hoch
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <div className="flex">
                  <Grid item xs={12}>
  <div className="flex">
    <Grid item xs={12}>

      <IconButton
        expand={expanded}
        aria-expanded={expanded}
        aria-label="show more"
        className={classesChooseEmailProvider.button}
      >
        <input
          type="file"
          id="file"
          name="file"
          accept="application/pdf"
          style={{ display: 'none' }} // Hide the default file input
          onChange={(e) => setSelectedFile(e.target.files[0])}
        />
        <label htmlFor="file">
          <CardMedia
            component="img"
            sx={{ width: 151 }}
            image={lebenslauf}
            alt="lebenslauf"
          />
          Lebenslauf hochladen
        </label>
      </IconButton>

      <Button>{selectedFile ? selectedFile.name : null}</Button>
    </Grid>
  </div>
</Grid>
                    <div style={{ width: "5%" }}></div>
                    <Grid item xs={12}>

<IconButton
  expand={expanded}
  aria-expanded={expanded}
  aria-label="show more"
  className={classesChooseEmailProvider.button}
>
  <input
    type="file"
    id="attachment"
    name="attachment"
    accept="application/pdf"
    style={{ display: 'none' }} // Hide the default file input
    onChange={(e) => setSelectedAttachment(e.target.files[0])}
  />
  <label htmlFor="attachment">
    <CardMedia
      component="img"
      sx={{ width: 151 }}
      image={anlagen} // replace this with the actual image source for "weitere Anlagen"
      alt="weitere Anlagen"
    />
    Anlagen hochladen
  </label>
</IconButton>

<Button>{selectedAttachment ? selectedAttachment.name : null}</Button>
</Grid>

                    <hr className="solid"></hr>
                  </div>

                  <div className="flex">
                    <Grid item xs={12} sm={6}>
                      <button
                        style={{ marginTop: "6%" }}
                        className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                        type="button"
                        onClick={() => cancelModal()}
                      >
                        Abbrechen
                      </button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        type="submit"
                        fullWidth
                        color="success"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Änderungen speichern
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid></Grid>

              {/* <Grid container justifyContent="flex-end"></Grid> */}
            </Box>
          </Modal>
        </>
      ) : null}
    </ThemeProvider>
  );
};

export default UserDataModal;
