//sessionStorage.setItem("key", "value");
import React from "react";
import useStyles from "../../components/general/Styles/MultiApplicationSenderTableStyles";
import { ThemeProvider } from "@mui/material/styles";
import { Modal, Box, Paper, Button, Collapse, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import OutlookIcon from "./EmailIcons/Outlook.svg";
import { makeStyles } from "@mui/styles";
/*  import {OutlookIcon} from "../../../public/icons/outlook.svg" */
import CardActions from "@mui/material/CardActions";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import CardHeader from "@mui/material/CardHeader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import Zoom from '@mui/material/Zoom';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  button: {
    fontSize: "1rem",
    fontWeight: 500,
    width: "100%",
    color: theme.palette.text.primary,
    borderRadius: "0.375rem",
    padding: "0.75rem 1.5rem",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      boxShadow: theme.shadows[4],
    },
  },
}));

const EmailSenderCard = ({
  expanded,
  handleExpandClick,
  OutlookIcon,
  cancelModal,
}) => {
  const classesChooseEmailProvider = useStyles2();

  const outlookRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  
  const handleClick = () => {
    const outlookValue = outlookRef.current.value;
    const passwordValue = passwordRef.current.value;
  
    // Check if input fields are not empty
    if (!outlookValue.trim() || !passwordValue.trim()) {
      alert("Please fill in all required fields.");
      return;
    }
  
    // Check if the email is valid
    if (!validateEmail(outlookValue)) {
      alert("Bitte gebe eine gültige Email Adresse ein.");
      return;
    }
  
    Cookies.set("provider", "outlook");
    Cookies.set("senderEmail", outlookValue);
    Cookies.set("senderPasswort", passwordValue);
  
    alert(
      "Deine Bewerbungen werden jetzt über deinen Outlook Account versendet."
    );
    cancelModal();
  };
  
  
  
  
  
  
  

  return (
    <Card
      sx={{
        maxWidth: "100%",
      }}
    >
      <CardHeader
        avatar={
          <IconButton
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            className={classesChooseEmailProvider.button}
          >
            <CardMedia
              component="img"
              sx={{
                width: 125,
              }}
              image={OutlookIcon}
              alt="Outlook"
            />
          </IconButton>
        }
        title="Outlook"
        subheader="Sende deine Email mit Outlook"
      />
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              m: 1,
              bgcolor: "secondary.main",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Box
            component="form"
            noValidate
            sx={{
              mt: 1,
            }}
          >
            <TextField
              margin="normal"
              required
              label="Outlook"
              fullWidth
              placeholder="Outlook"
              id="outlook"
              name="outlook"
              autoComplete="email"
              autoFocus
              inputRef={outlookRef}
            />
            <TextField
              margin="normal"
              required
              label="Passwort"
              fullWidth
              name="password"
              placeholder="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
              inputRef={passwordRef}
            />

            <Tooltip TransitionComponent={Zoom}  followCursor title="Du kannst jeder Zeit einen anderen Email-Provider auswählen.">
              <Button
                fullWidth
                onClick={handleClick}
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                Outlook als Email-Provider auswählen
              </Button>
            </Tooltip>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Link
                  href="https://outlook.live.com/"
                  variant="body2"
                  target="_blank"
                  rel="noopener"
                >
                  {"Du hast noch keinen Outlook-Account?"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Collapse>
    </Card>
  );
};

export default EmailSenderCard;
