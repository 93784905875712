import React from "react";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initFirebase } from "./firebaseConfig";
import './CookieConsent.css';
export const withCookieConsent = (WrappedComponent) => {
  const handleAcceptCookie = () => {
    initFirebase();
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  return (props) => {
    const isConsent = getCookieConsentValue();

    React.useEffect(() => {
      if (isConsent === "true") {
        handleAcceptCookie();
      }
    }, []);

    return (
      <>
        <WrappedComponent {...props} />
        <CookieConsent
    overlay
    disableStyles
    location="none"
    enableDeclineButton
    onDecline={handleDeclineCookie}
    declineButtonText="Nur erforderliche Cookies"
    onAccept={handleAcceptCookie}
    buttonText="Akzeptieren"
    cookieName="cookieConsent"
    overlayClasses="overlayclass"
    className="CookieConsent"
  >
    <style>
      {`
        .CookieConsent {
          font-size: 16px;
        }
        .CookieConsent button {
          transition: 0.3s;
        }
        @media (max-width: 600px) {
          .CookieConsent {
            font-size: 14px;
          }
          .CookieConsent button {
            padding: 10px 20px;
          }
        }
      `}
    </style>
        Wir nutzen Cookies, um sicherzustellen, dass unsere Webseite einwandfrei
        funktioniert, Ihnen Funktionen von Drittanbietern zur Verfügung zu
        stellen und den Zugriff auf unsere Webseite zu analysieren.
        <br></br>
        <br></br>
        Diese Informationen können von Drittanbietern möglicherweise mit
        weiteren Daten zusammengeführt werden, die Sie ihnen bereitgestellt
        haben oder die sie im Rahmen Ihrer Nutzung ihrer Dienste gesammelt
        haben.
        <br></br>
        <br></br>
        Bitte beachten Sie: Wenn Sie bestimmte Arten von Cookies nicht
        akzeptieren, kann dies dazu führen, dass Ihnen einige Funktionen unserer
        Webseite nicht zur Verfügung stehen.
        <br></br>
        <br></br>
      </CookieConsent>
      </>
    );
  };
};
