import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import PanToolIcon from "@mui/icons-material/PanTool";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { yellow } from "@mui/material/colors";
import RocketLaunch from "@mui/icons-material/RocketLaunch";
import Grid from "@mui/material/Grid";
import { fetchEmailAndContent, postApplicationRequest, getAIApplicationCounter } from "../api";
import { returnUsernameFromJWT } from "../../../helpers/jwt.js";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { generateApplcationText, generateApplicationText } from "../api";
import AITextPreview from "../AITextPreview";
import ApplicationTextPreviewSender from "../ApplicationTextPreviewSender";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme } from "@mui/material/styles";
import LoginRequiredModal from "../../../helpers/NotificationModals/LoginRequiredModal";
import Zoom from '@mui/material/Zoom';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({
  beruf,
  titel,
  aktuellesVeroeffentlichungsdatum,
  eintrittsdatum,
  refnr,
  advice,
  arbeitgeber,
  userFirstname,
  userLastname,
  userCV,
  userData,
  externeUrl,
}) {
  const [expanded2, setExpanded] = React.useState(false);
  const [anchorElement2, setAnchorElement] = React.useState(null);

  const [email, setEmail] = React.useState("");
  let [shortText, setShortText] = React.useState("");

  const [loading, setLoading] = useState(null);
  const [AITextPreviewModalOpen, setAITextPreviwModal] = React.useState(false);
  const [linearIndeterminate, setLinearIndeterminate] = useState(false);
  const [applicationTextEditor, setApplicationTextEditor] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  let [requirementsArray, setRequirementsArray] = useState([]);

  const [loginRequiredModalOpen, setLoginRequiredModalOpen] =
    React.useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded2);
  };

  useEffect(() => {
    const refLinkUrl =
      "https://cors.bewerbr.de/www.arbeitsagentur.de/jobsuche/jobdetail/";
    let refLinkFullUrl = refLinkUrl + refnr;
    setLoading(true);
    fetchEmailAndContent(refLinkFullUrl).then(
      ({ email, shortText, requirementsFaehigkeiten }) => {
        setEmail(email);
        setShortText(shortText);
        setRequirementsArray(requirementsFaehigkeiten);
        setLoading(false);
      }
    );
    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, [refnr]);

  const handleClick = (e) => {
    if (anchorElement2 === null) {
      setAnchorElement(e.target);
    } else {
      setAnchorElement(null);
    }
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const adviceJson = advice.map((item) => {
    let username = returnUsernameFromJWT();
    return {
      refnr: item.refnr,
      beruf: item.beruf,
      titel: item.titel,
      arbeitgeber: item.arbeitgeber,
      aktuelleVeroeffentlichungsdatum: item.aktuelleVeroeffentlichungsdatum,
      eintrittsdatum: item.eintrittsdatum,
      arbeitsort_ort: item.arbeitsort.ort,
      arbeitsort_plz: item.arbeitsort.plz,
      arbeitsort_strasse: item.arbeitsort.strasse,
      arbeitsort_region: item.arbeitsort.region,
      arbeitsort_land: item.arbeitsort.land,
      arbeitsort_entfernung: item.arbeitsort.entfernung,
      arbeitsort_koordinaten_lat: item.arbeitsort.koordinaten.lat,
      arbeitsort_koordinaten_lon: item.arbeitsort.koordinaten.lon,
      modifikationsTimestamp: item.modifikationsTimestamp,
      eintrittsdatum: item.eintrittsdatum,
      logoHashId: item.logoHashId,
      hashId: item.hashId,
      externeUrl: item.externeUrl,
      username: username,
      shortText: shortText,
      email: email,
    };
  });

/*   async function sendAIApplication() {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("username") === null
    ) {
      setLoginRequiredModalOpen(
        <LoginRequiredModal
          text={
            "Du musst dich zuerst einloggen um KI Bewerbungen zu generieren und zuversenden."
          }
          subtext={
            "Um Bewerberungen generieren und versenden zu können, musst du dich zuerst einloggen und deinen Lebenslauf hochladen. Aus einer Komibination aus deinem Lebenslauf und der Stellenanzeige wird eine Bewerbung generiert. Diese kannst du dann noch bearbeiten und versenden."
          }
          redirectPath={"Login"}
          loginRequiredModal={loginRequiredModalOpen}
          setLoginRequiredModal={setLoginRequiredModalOpen}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {



      let finalOutput = "";

      const counter = await getAIApplicationCounter();
      if (counter >= 4 && !userData.user.isPremium) {
       
        alert("Du hast das tägliche Limit von deinen KI Bewerbungen erreicht. - Hole dir jetzt Premium, um weitere Anschreiben über KI zu generieren");
    
      } else {


      let checkRef;
      //document.getElementById("kiapplicationsend").setAttribute("disabled", "disabled");
      let indexedAdvice = [];

      for (let i = 0; i < adviceJson.length; i++) {
        checkRef = adviceJson[i].refnr;

        if (checkRef === refnr) {
          indexedAdvice.push(adviceJson[i]);
          var jsonArray = JSON.parse(JSON.stringify(indexedAdvice));

          await postApplicationRequest(jsonArray);

          setLinearIndeterminate(true);
          shortText = shortText.substring(0, 512);
          let question1 =
            "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
            shortText;

          let json = {
            model: "text-davinci-003",
            prompt: question1,
            temperature: 0,
            max_tokens: 2048,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
          };

          generateApplcationText(json).then((res) => {
            let parsedRes = JSON.parse(res);

            let promptConversation =
              "The following is a conversation with an AI assistant. Der Assistent schreibt Anschreiben, wo er auf den Mehrwert des Bewerbers für das Unternehmen eingeht.";
            let humanQ =
              "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
              shortText;
            let aiA = parsedRes.choices[0].text;
            let cv = userCV;
            let humanQ2 =
              "Passe das Anschreiben auf den Lebenslauf von " +
              userFirstname +
              " " +
              userLastname +
              " an: \n\n" +
              cv;

            let secondPrompt = {
              model: "text-davinci-003",
              prompt:
                promptConversation +
                " Human: " +
                humanQ +
                " AI: " +
                aiA +
                " Human: " +
                humanQ2,
              temperature: 0.9,
              max_tokens: 2048,
              top_p: 1,
              frequency_penalty: 0,
              presence_penalty: 0.6,
              stop: [" Human:", " AI:"],
            };

            generateApplcationText(secondPrompt).then((res) => {
              let applicationid = refnr + returnUsernameFromJWT();

              let parsedRes = JSON.parse(res);
              setAITextPreviwModal(
                <AITextPreview
                  AIText={parsedRes.choices[0].text}
                  email={email}
                  refnr={applicationid}
                  setAITextPreviwModal={setAITextPreviwModal}
                  userData={userData}
                  applicationData={adviceJson[i]}
                ></AITextPreview>
              );
              setLinearIndeterminate(false);
              //document.getElementById("kiapplicationsend").removeAttribute("disabled");
            });
          });
        }
      }

    }

    }
  } */

 /*  async function sendAIApplication() {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("username") === null
    ) {
      setLoginRequiredModalOpen(
        <LoginRequiredModal
          text={
            "Du musst dich zuerst einloggen um KI Bewerbungen zu generieren und zuversenden."
          }
          subtext={
            "Um Bewerberungen generieren und versenden zu können, musst du dich zuerst einloggen und deinen Lebenslauf hochladen. Aus einer Komibination aus deinem Lebenslauf und der Stellenanzeige wird eine Bewerbung generiert. Diese kannst du dann noch bearbeiten und versenden."
          }
          redirectPath={"Login"}
          loginRequiredModal={loginRequiredModalOpen}
          setLoginRequiredModal={setLoginRequiredModalOpen}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {
      const counter = await getAIApplicationCounter();
      if (counter >= 4 && !userData.user.isPremium) {
        alert("Du hast das tägliche Limit von deinen KI Bewerbungen erreicht. - Hole dir jetzt Premium, um weitere Anschreiben über KI zu generieren");
      } else if (userData.cv === null) {
        alert("Bitte füge zuerst einen Lebenslauf hinzu!");
      } else {
        setLinearIndeterminate(true);

  
        for (let i = 0; i < adviceJson.length; i++) {
          if (adviceJson[i].refnr === refnr) {
            let applicationText = adviceJson[i].shortText.substring(0, 512);
            let cv = userCV.substring(0, 1024); // Trim the CV to avoid exceeding the token limit
  
            let json = {
              model: "gpt-3.5-turbo",
              messages: [
                {
                  role: "system",
                  content: "The following is a conversation with an AI assistant. Der Assistent hilft dabei, ein aussagekräftiges Anschreiben zu verfassen, welches den Mehrwert des Bewerbers für das Unternehmen hervorhebt."
                },
                {
                  role: "user",
                  content: "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" + applicationText
                }
              ],
              temperature: 0.7,
              max_tokens: 2048,
            };
  
            generateApplicationText(json).then((res) => {
              let parsedRes = JSON.parse(res);
              let humanQ2 =
                "Passe das Anschreiben auf den Lebenslauf von " +
                userFirstname +
                " " +
                userLastname +
                " an: \n\n" +
                cv;
  
              let secondPrompt = {
                model: "gpt-3.5-turbo",
                messages: [
                  ...json.messages,
                  {
                    role: "assistant",
                    content: parsedRes.choices[0].message.content.trim()
                  },
                  {
                    role: "user",
                    content: humanQ2
                  }
                ],
                temperature: 0.7,
                max_tokens: 2048,
              };
  
              generateApplicationText(secondPrompt).then((res) => {
                let parsedRes = JSON.parse(res);
                let parsedText2 = parsedRes.choices[0].message.content;
                parsedText2 = parsedText2.replace(/^AI: /, "");
                let applicationid = refnr + returnUsernameFromJWT();

                if (parsedText2.includes("AI: ")) {
                  parsedText2 = parsedText2.substring(6);
                }
  
    

                setAITextPreviwModal(
                  <AITextPreview
                    AIText={parsedRes.choices[0].text}
                    email={email}
                    refnr={applicationid}
                    setAITextPreviwModal={setAITextPreviwModal}
                    userData={userData}
                    applicationData={adviceJson[i]}
                  ></AITextPreview>
                );
  
                setLinearIndeterminate(false);
               // document.getElementById("sendAIApplicationButton").removeAttribute("disabled");
              });
            });
          }
        }
      }
    }
  } */

  

  async function sendAIApplication() {
    if (localStorage.getItem("username") === null) {
      setLoginRequiredModalOpen(
        <LoginRequiredModal
          text={
            "Du musst dich zuerst einloggen um KI Bewerbungen zu generieren und zuversenden."
          }
          subtext={
            "Um Bewerberungen generieren und versenden zu können, musst du dich zuerst einloggen und deinen Lebenslauf hochladen. Aus einer Komibination aus deinem Lebenslauf und der Stellenanzeige wird eine Bewerbung generiert. Diese kannst du dann noch bearbeiten und versenden."
          }
          redirectPath={"Login"}
          loginRequiredModal={loginRequiredModalOpen}
          setLoginRequiredModal={setLoginRequiredModalOpen}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
    
      );
    } else {
      let checkRef;
/*       document
        .getElementById("kiapplicationsend")
        .setAttribute("disabled", "disabled"); */
      let indexedAdvice = [];
  
      for (let i = 0; i < adviceJson.length; i++) {
        checkRef = adviceJson[i].refnr;
  
        if (checkRef === refnr) {
          indexedAdvice.push(adviceJson[i]);
          var jsonArray = JSON.parse(JSON.stringify(indexedAdvice));
  
          await postApplicationRequest(jsonArray);
  
          setLinearIndeterminate(true);
          let applicationText = adviceJson[i].shortText.substring(0, 512);
  
          let question1 =
            "Write me a cover letter based on the following text: \n\n" +
            applicationText;
  
          let json = {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content: "The following is a conversation with an AI assistant. Der Assistent hilft dabei, ein aussagekräftiges Anschreiben zu verfassen, welches den Mehrwert des Bewerbers für das Unternehmen hervorhebt."
              },
              {
                role: "user",
                content: question1
              }
            ],
            temperature: 0.7,
            max_tokens: 2048,
          };
  
          generateApplicationText(json).then((res) => {
            let parsedRes = JSON.parse(res);
  
            let cv = userData.cv.substring(0, 1024); // Trim the CV to avoid exceeding the token limit
            let humanQ2 =
            "Passe das Anschreiben auf den Lebenslauf von " +

              userData.firstname +
              " " +
              userData.lastname +
              ": \n\n" +
              cv;
  
            let secondPrompt = {
              model: "gpt-3.5-turbo",
              messages: [
                ...json.messages,
                {
                  role: "assistant",
                  content: parsedRes.choices[0].message.content.trim()
                },
                {
                  role: "user",
                  content: humanQ2
                }
              ],
              temperature: 0.7,
              max_tokens: 2048,
            };
  
            generateApplicationText(secondPrompt).then((res) => {
              let parsedRes = JSON.parse(res);
  
              if (parsedRes.choices[0].message.content === null) {
                adviceJson[i].refLink = "Unfortunately, no cover letter could be generated.";
              } else {
                adviceJson[i].refLink = parsedRes.choices[0].message.content;
              }
  
              let parsedText2 = parsedRes.choices[0].message.content;
  
              parsedText2 = parsedText2.replace(/^AI: /, "");
  
              if (parsedText2.includes("AI: ")) {
                parsedText2 = parsedText2.substring(6);
              }
              let applicationid = refnr + returnUsernameFromJWT();

              setAITextPreviwModal(
                <AITextPreview
                  AIText={parsedText2}
                  email={email}
                  refnr={applicationid}
                  setAITextPreviwModal={setAITextPreviwModal}
                  userData={userData}
                  applicationData={adviceJson[i]}
                ></AITextPreview>
              );
              setLinearIndeterminate(false);
              //document.getElementById("kiapplicationsend").removeAttribute("disabled");
            });
          });
        }
      }

    }
  }
  

                  /*  generateApplcationText(secondPrompt).then((res) => {
              let applicationid = refnr + returnUsernameFromJWT();

              let parsedRes = JSON.parse(res);
              setAITextPreviwModal(
                <AITextPreview
                  AIText={parsedRes.choices[0].text}
                  email={email}
                  refnr={applicationid}
                  setAITextPreviwModal={setAITextPreviwModal}
                  userData={userData}
                  applicationData={adviceJson[i]}
                ></AITextPreview>
              );
              setLinearIndeterminate(false);
              //document.getElementById("kiapplicationsend").removeAttribute("disabled");
            });
          });
        }
      }

    } */


  const openApplicationTextEditorModal = () => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("username") === null
    ) {
      setLoginRequiredModalOpen(
        <LoginRequiredModal
          text={"Du musst dich zuerst einloggen um Bewerbungen versenden."}
          subtext={
            "Um Bewerberungen versenden zu können, musst du dich zuerst einloggen und deine Outlook E-Mail Adresse hinterlegen. Über diese werden dann Mails zu den jeweiligen Arbeitgebern geschickt."
          }
          redirectPath={"Login"}
          loginRequiredModal={loginRequiredModalOpen}
          setLoginRequiredModal={setLoginRequiredModalOpen}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {
      setApplicationTextEditor(
        <ApplicationTextPreviewSender
          applicationTextEditor={applicationTextEditor}
          setApplicationTextEditor={setApplicationTextEditor}
          refnr={refnr}
          adviceJson={adviceJson}
          email={email}
          userFirstname={userFirstname}
          userLastname={userLastname}
          userData={userData}
          darkMode={darkMode}
        />
      );
    }
  };

  async function sendApplicationRequest() {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("username")  === null
    ) {
      setLoginRequiredModalOpen(
        <LoginRequiredModal
          text={"Du musst dich zuerst einloggen um Bewerbungen vorzumerken."}
          subtext={
            "Vorgemerkte Bewerbungen kannst du zu einem späteren Zeitpunkt über das Bewerbungs-Dashboard versenden."
          }
          redirectPath={"Login"}
          loginRequiredModal={loginRequiredModalOpen}
          setLoginRequiredModal={setLoginRequiredModalOpen}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {
      let checkRef;
      let indexedAdvice = [];
      for (let i = 0; i < adviceJson.length; i++) {
        checkRef = adviceJson[i].refnr;

        if (checkRef === refnr) {
          indexedAdvice.push(adviceJson[i]);

          var jsonArray = JSON.parse(JSON.stringify(indexedAdvice));
          await postApplicationRequest(jsonArray);

          setLoginRequiredModalOpen(
            <LoginRequiredModal
              text={ "Deine Bewerbung wurde vorgemerkt. Gehe zum Dashboard um die Bewerbung zu versenden."}
              subtext={
                "Vorgemerkte Bewerbungen kannst du zu einem späteren Zeitpunkt über das Bewerbungs-Dashboard versenden."
              }
              redirectPath={"Dashboard"}
              loginRequiredModal={loginRequiredModalOpen}
              setLoginRequiredModal={setLoginRequiredModalOpen}
              darkMode={darkMode}
              darkTheme={darkTheme}
              lightTheme={lightTheme}
            />
          );
        }
      }
    }
  }
  return (
    <Card
      style={{ position: "relative", height: "auto", minHeight: "100%", overflow:"auto" }}
      sx={{ width: 700 }}
    >
      {loginRequiredModalOpen}
      <Box>
        <CardHeader
          avatar={
            <Tooltip
            TransitionComponent={Zoom}  followCursor 
            title={
              email === "Trage die Email manuell ein"
                ? "Dieser Arbeitgeber hat keine Mail hinterlegt. Klick auf MEHR und speicher die Stelle in deinem Dashboard"
                : "Klicke auf MEHR und bewerbe dich direkt mit einem manuellen oder ein vom KI generierten Anschreiben"
            }
          >
            <Avatar
              sx={{
                color: darkMode ? "white" : "white",
                width: 66,
                height: 66,
                bgcolor:
                  email === "Trage die Email manuell ein" ? yellow[800] : green[800],
              }}
            >
              {email === "Trage die Email manuell ein" ? (
                      <span>
                      {arbeitgeber.charAt(0).toUpperCase()}
                      {arbeitgeber.charAt(1).toUpperCase()}
                      {arbeitgeber.charAt(2).toUpperCase()}
                    </span>
              ) : (
           
                <span>
                {arbeitgeber.charAt(0).toUpperCase()}
                {arbeitgeber.charAt(1).toUpperCase()}
                {arbeitgeber.charAt(2).toUpperCase()}
              </span>
            
              )}
            </Avatar>
          </Tooltip>
          }
          action={
            <IconButton aria-label="settings" onClick={(e) => handleClick(e)}>
              <MoreVertIcon />
            </IconButton>
          }
          title={beruf}
          subheader={`${new Date(
            aktuellesVeroeffentlichungsdatum
          ).toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}`}
        ></CardHeader>

        <Menu
          id="simple-menu"
          anchorEl={anchorElement2}
          open={Boolean(anchorElement2)}
          onClose={handleClose}
        >
          {email !== "Trage die Email manuell ein" ? (
            <div>
              <MenuItem onClick={sendApplicationRequest}>
                <p className="text-white text-sm bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2">
                  Im Dashboard speichern
                </p>
              </MenuItem>
              <MenuItem onClick={openApplicationTextEditorModal}>
                <p className="text-white text-sm  bg-gradient-to-r from-green-500 via-green-800 to-green-500 hover:bg-gradient-to-br hover:ring-4 focus:ring-4 focus:outline-none focus:ring-green-500 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2">
                  manuelles Anschreiben
                </p>
              </MenuItem>
              <MenuItem onClick={sendAIApplication}>
                <p className="text-white text-sm  bg-gradient-to-r from-green-500 via-green-800 to-green-500 hover:bg-gradient-to-br hover:ring-4 focus:ring-4 focus:outline-none focus:ring-green-500 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2">
                  KI Anschreiben
                </p>
              </MenuItem>
            </div>
          ) : (
            <Tooltip TransitionComponent={Zoom}  followCursor title="Du findest deine Bewerbungen in deinem Dashboard">
              <MenuItem>
                <p
                  onClick={sendApplicationRequest}
                  className="text-white text-sm bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2"
                >
                  Im Dashboard speichern 
                </p>
              </MenuItem>
            </Tooltip>
          )}
        </Menu>
      </Box>

      <CardContent>
        <Typography variant="body1" color="text.secondary">
          <strong>
            {titel} zum{" "}
            {new Date(eintrittsdatum).toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}{" "}
            gesucht.
          </strong>
        </Typography>
        {linearIndeterminate ? <LinearProgress /> : null}
        <br />

        {externeUrl != null ? (
          <div>
            Du kannst dir die Stellenanzeige hier anschauen:{" "}
            <a
              href={externeUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Zur Stellenanzeige
            </a>
          </div>
        ) : null}

        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded2}
            onClick={handleExpandClick}
            aria-expanded={expanded2}
            aria-label="show more"
          >
            <ExpandMoreIcon />
            MEHR
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded2} timeout="auto" unmountOnExit>
          <CardContent>
            {/* zuviel zeug in der typograpgy */}
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ whiteSpace: "pre-line" }}
            >
  <div dangerouslySetInnerHTML={{ __html: shortText }} />



              <div className="flex-row">
                <br></br>
                {email === "Trage die Email manuell ein" ? (
                  <button
                    onClick={() => sendApplicationRequest()}
                    className="text-white text-base bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-5 py-3  text-center mr-2 mb-2"
                  >
                    Speichern & später Bewerben
                  </button>
                ) : (
                  <div className="flex flex-row">
                    <div style={{ marginRight: "15px" }}></div>
                    <Tooltip TransitionComponent={Zoom}  followCursor title="Prüfe dein Anschreiben bevor du es losschickst">
                      <button
                        className="text-white text-base	bg-gradient-to-r from-green-500 via-green-800 to-green-500 hover:bg-gradient-to-br hover:ring-4 focus:ring-4 focus:outline-none focus:ring-green-500 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2"
                        onClick={openApplicationTextEditorModal}
                      >
                        Anschreiben prüfen
                      </button>
                    </Tooltip>
                    <div style={{ width: "10%" }}></div>
                    <Tooltip TransitionComponent={Zoom}  followCursor title="Lass dein Anschreiben von einer KI generieren.">
                      <button
                        className="text-white text-base	bg-gradient-to-r from-green-500 via-green-800 to-green-500 hover:bg-gradient-to-br hover:ring-4 focus:ring-4 focus:outline-none focus:ring-green-500 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2"
                        id="kiapplicationsend"
                        color="success"
                        variant="contained"
                        onClick={sendAIApplication}
                      >
                        KI-Anschreiben generieren
                      </button>
                    </Tooltip>
                    <div style={{ width: "10%" }}></div>
                    <Tooltip TransitionComponent={Zoom}  followCursor title="Speicher die Stellenanzeige in deinem Dashboard und ergänze bei Bedarf Daten">

                    <button
                    onClick={() => sendApplicationRequest()}
                    className="text-white text-base bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-5 py-3  text-center mr-2 mb-2"
                  >
                    Speichern & später Bewerben
                  </button>
                  </Tooltip>

                    {AITextPreviewModalOpen}
                    {applicationTextEditor}
                  </div>
                )}
              </div>

              {requirementsArray.length > 0 ? (
                <Grid style={{ marginTop: "100px" }} container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      Gewünschte Kenntnisse:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="center" spacing={2}>
                      {requirementsArray.map((requirement, index) => (
                        <Grid item key={index}>
                          <button
                            style={{ maxWidth: "90%" }}
                            type="button"
                            className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                          >
                            {requirement}
                          </button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              {loading}
              {linearIndeterminate ? <LinearProgress /> : null}
              <br></br>
            </Typography>
            <div className="flex-row">
              <br></br>

              {shortText && !email === "Trage die Email manuell ein" && (
                <div className="flex flex-row">
                  <Tooltip TransitionComponent={Zoom}  followCursor title="Prüfe dein Anschreiben bevor du es losschickst">
                    <button
                      className="text-white text-base	 bg-gradient-to-r from-green-500 via-green-800 to-green-500 hover:bg-gradient-to-br hover:ring-4 focus:ring-4 focus:outline-none focus:ring-green-500 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2"
                      onClick={openApplicationTextEditorModal}
                    >
                      Anschreiben prüfen
                    </button>
                  </Tooltip>

                  <div style={{ width: "10%" }}></div>
                  <Tooltip TransitionComponent={Zoom}  followCursor title="Lass dein Anschreiben von einer KI generieren.">
                    <button
                      className="text-white text-base	bg-gradient-to-r from-green-500 via-green-800 to-green-500 hover:bg-gradient-to-br hover:ring-4 focus:ring-4 focus:outline-none focus:ring-green-500 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2"
                      id="kiapplicationsend"
                      color="success"
                      variant="contained"
                      onClick={sendAIApplication}
                    >
                      KI-Anschreiben generieren
                    </button>
                  </Tooltip>

         
                </div>
              )}
            </div>
          </CardContent>
        </Collapse>
      </CardContent>
      {AITextPreviewModalOpen}
                  {applicationTextEditor}
    </Card>
  );
}
