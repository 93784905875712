import React, { useEffect } from "react";

import Navbar2 from "./components/navbar/Navbar2";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Impressum from "./components/pages/footerpages/Impressum";
import SwipeableEdgeDrawer from "./components/AutoApplication/Filters.js";
import { history } from "./helpers/history";
import RouteGuard from "./helpers/RouteGuard";
import SignUp from "./components/pages/SignUp.js";
import SignIn from "./components/pages/SignIn.js";
import Dashboard from "./components/UserManagement/OverviewDashboard/Dashboard";
import Faq from "./components/pages/Faq";
import About from "./components/pages/footerpages/About";
import AGB from "./components/pages/footerpages/AGB";
import Kontakt from "./components/pages/footerpages/Kontakt";
import Standort from "./components/pages/footerpages/Standort";
import Datenschutz from "./components/pages/footerpages/Datenschutz";
import Support from "./components/pages/footerpages/Support";
import ForgotPassword from "./components/pages/ForgotPassword";
/* import UserDataModal from "./components/UserManagement/UserProfile/UserDataModal"; */
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";

/* import UserProfileEditor from "./components/UserManagement/UserProfile/UserDataModal";
 */import Referenzen from "./components/pages/footerpages/Referenzen";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


import { withCookieConsent } from "./CookieConsent";


const AppContent = () => {
  // Get the current URL hostname
  const hostname = window.location.hostname;



  return (
    <div
      className="mainDiv"
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: '#121212',
        overflow: 'auto',
      }}
    >
      <Router history={history}>
        <Navbar2 />

        <Switch>
          <Route exact path="/" component={SwipeableEdgeDrawer} />
          <RouteGuard exact path="/dashboard" component={Dashboard} />
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/jobfinder" component={Faq} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/referenzen" component={Referenzen}></Route>
          <Route exact path="/agb" component={AGB}></Route>
          <Route exact path="/kontakt" component={Kontakt}></Route>
          <Route exact path="/standort" component={Standort}></Route>
          <Route exact path="/datenschutz" component={Datenschutz}></Route>
          <Route exact path="/support" component={Support}></Route>
          <Route path="/forgot-password" component={ForgotPassword} />
        </Switch>
      </Router>
    </div>
  );
};

const App = withCookieConsent(AppContent);

export default App;