import React from "react";
import useStyles from "../../components/general/Styles/MultiApplicationSenderTableStyles";
import { ThemeProvider } from "@mui/material/styles";
import { Modal, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";

export const LoginRequiredModal = ({ text, subtext, redirectPath, loginRequiredModal, setLoginRequiredModal, darkMode, darkTheme, lightTheme, }) => {
  
  const classes = useStyles();

  const redirectToLogin = () => {
    window.location.href = `/${redirectPath}`;
  };

  const cancelModal = () => {
    setLoginRequiredModal(false);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Modal open={true} aria-labelledby="exampleModalScrollableLabel" aria-hidden={true} className={classes.rootModal} id="exampleModalScrollable" tabIndex={-1} >
        <Box style={{ backgroundColor: `${darkMode ? "black" : "white"}` }} className={classes.box} component="form" noValidate >
          <Typography style={{ color: `${darkMode ? "white" : "black"}` }} component="h1" variant="h5" >
            {text}
          </Typography>
          <Grid item xs={12} sm={6}>
            <div id="separator"></div>
          </Grid>
          <br/>
          <br/>

          <Typography style={{ color: `${darkMode ? "white" : "black"}` }}>
            {subtext}
          </Typography>

          <Grid item xs={12} sm={6}>
            <button style={{ marginTop: "6%" }} className="justify-left bg-transparent text-green-600 font-semibold py-2 px-4 border border-green-500 rounded hover:bg-green-600 hover:text-green-800 border-green-600" type="button"
              onClick={() => redirectToLogin()} >
              zum {redirectPath}
            </button>
            <button style={{ marginTop: "6%" }} className="justify-left bg-transparent text-yellow-600 font-semibold py-2 px-4 border border-yellow-500 rounded hover:bg-yellow-600 hover:text-yellow-800 border-yellow-600" type="button"
              onClick={() => cancelModal()} >
              Weiter
            </button>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default LoginRequiredModal;
