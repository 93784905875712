import React,{useEffect} from "react";
import { Container, Stack, Typography, Button } from "@mui/material";
import { GoogleLogin } from '@react-oauth/google';
import CircularProgress from "@mui/material/CircularProgress";
import { returnUsernameFromJWT } from "../../../helpers/jwt";
export const IntroText = ({ darkMode, darkTheme, lightTheme, userData }) => {



/* 
  const [loadingAnimation, setLoadingAnimation] = React.useState(null);





  const responseMessage = async (response) => {
    try {
      let myResponse = response;
      const idToken = myResponse.credential;
      localStorage.setItem('token', idToken);
  
      const apiResponse = await fetch(
        `https://data.bewerbr.de/api/v1/user/register`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/text' },
          body: idToken,
        }
      );
  
      if (apiResponse.status === 200) {
        const userData = await apiResponse.json();
  
        // Call the handleGoogleLogin method
        await handleGoogleLogin(response);
      } else {
        console.error('Failed to register user with status:', apiResponse.status);
      }
    } catch (error) {
      console.error('Failed to register user:', error);
      await handleGoogleLogin(response);
    }
  };
  


  const handleGoogleLogin = async (response) => {
    try {
      let myResponse = response;
  
      const idToken = myResponse.credential;
  
  
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/text");
  
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: idToken ,
        redirect: 'follow'
      };
  
      const apiResponse = await fetch("https://data.bewerbr.de/api/v1/user/googleLogin", requestOptions);
  
      const token = await apiResponse.text();
  
  
  
  
      if (token) {
  
        localStorage.setItem('token', token);    
    
    
        const username = returnUsernameFromJWT();
    
  
        localStorage.setItem('username', username);
        setLoadingAnimation(<CircularProgress />);
        setTimeout(() => {
          setLoadingAnimation(null);
           window.open("/dashboard", "_self");
        }, 500);
      } else {
        alert('Falsche Email oder Passwort');
      }
    } catch (error) {
      console.error(error);
    }
  }; */

/* const errorMessage = (error) => {
    alert(error);
}; */
  return (
    <div>
   {/*    {!localStorage.getItem('username') ? (
        <Container maxWidth="sm"> */}

{/*           <Typography
            variant="h6"
            align="center"
            color={`${darkMode ? "white" : "black"}`}
            paragraph
          >
            1.{" "}
            <Button sx={{ textTransform: "none" }} href="/signup" variant="outlined">
              Registieren  
            </Button>{" "}
            dich und logge dich ein. <br></br>
            2. Lade deine Bewerbungsunterlagen hoch. <br></br>
            3. Suche dir passende Stellen aus. <br></br>
            4. Klick auf Absenden und sende deine Bewerbungen. <br></br>
          </Typography> */}


{/* 
                <Typography
        variant="p"
        align="center"
        color={`${darkMode ? "white" : "#1b1b1b"}`}
        paragraph
      >
        1. Erstell dir einen Account und logge dich ein. <br></br>
        2. Lade deine Bewerbungsunterlagen hoch. <br></br>
        3. Suche dir passende Stellen aus. <br></br>
        4. Klick auf Absenden und sende deine Bewerbungen. <br></br>
      </Typography> */}
{/*     <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>     

        <GoogleLogin text={"Mit Google registieren"} context={"signup"} onSuccess={responseMessage} onError={errorMessage} width={"1000px"}/> 

        <Typography
          variant="p"
          align="center"
          paragraph
          color={`${darkMode ? "white" : "#1b1b1b"}`}
          style={{top:"10x", bottom:"10px"}}
        >
          oder
        </Typography>
      
        <Button href="/signup" variant="contained">
          Jetzt Registieren
        </Button>
      </div> */}
    {/*       <Stack
            sx={{
              pt: 4,
            }}
            direction="row"
            spacing={2}
            justifyContent="center"
          ></Stack>
        </Container>
      ) : (
        <Typography
        component="h5"
        variant="h5"
        align="center"
        color={`${darkMode ? "white" : "black"}`}
        gutterBottom
      >
        {'Hallo ' + userData.firstname}
      </Typography>
 
      )} */}
    </div>
  );
};

export default IntroText;
