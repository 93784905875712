import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "../../../tailwind.css";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";

import Footer from "../../footer/Footer";


export default function Kotankt() {


  return (
    <form noValidate autoComplete="off">
      <div
        className="items-center flex flex-col items-stretch justify-center"
        style={{
          backgroundColor: "white",
          margin: "10px",
          borderRadius: "10px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
        
              <TextField
                style={{ width: "100%" }}
                required
                id="standard-required"
                label="Dein Name"
                placeholder="Name"
              />
        
          </Grid>
          <Grid item xs={4}>
        
              <TextField
                style={{ width: "100%" }}
                required
                id="standard-required"
                label="E-Mail"
                placeholder="E-Mail"
              />
       
          </Grid>
          <Grid item xs={12}>
      
              <TextField
                style={{ width: "100%" }}
                required
                id="standard-multiline-flexible"
                label="Nachricht"
                multiline
                rows={5}
                placeholder="Nachricht"
              />
        
          </Grid>
        </Grid>
      </div>
      <Button variant="contained" color="primary">
        Absenden
      </Button>
      <Router>
        <Footer />
      </Router>
    </form>
  );
}
