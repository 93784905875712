import React, { useState, useEffect, useRef } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./OverviewDashboard/Title";
import TableSortLabel from "@mui/material/TableSortLabel"
import "../../tailwind.css";
import ApiService from "../APIService";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Paper  from "@mui/material/Paper";
import { ThemeProvider } from "@mui/material/styles";
import {  insertEmailByRefnr, insertPLZByRefnr, insertOrtByRefnr, insertStreetByRefnr, insertTitleByRefnr,insertCompanyByRefnr,insertContactPersonByRefnr,insertGenderByRefnr,insertCommentByRefnr,postApplicationRequest } from "../AutoApplication/api";
import Tooltip from "@mui/material/Tooltip";
import ApplicationTextPreviewSender from "../AutoApplication/ApplicationTextPreviewSender";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from "@mui/material/Button";
import Link from "react-router-dom/Link";
import TablePagination from '@mui/material/TablePagination';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { makeStyles } from '@mui/styles';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

//MAKE CELLS EDITABLE
//https://codesandbox.io/s/editable-field-material-ui-t1sq4?file=/src/index.tsx

/* function preventDefault(event) {
  event.preventDefault();
} */


const useStyles = makeStyles((theme) => ({
  fullscreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    overflow: 'auto',
    zIndex: "997",

    backgroundColor: theme.palette.background.paper,
  },
  regular: {
    width: '100%',
    overflow: 'auto',
  },
}));

export default function Orders({ advice, setAdvice, userData, setUserData, darkMode, darkTheme, lightTheme, }) {
  const classes = useStyles();


  const [open, setOpen] = useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleConfirm = () => {
  deleteAllEntrys();
  setOpen(false);
};


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isFullscreen, setIsFullscreen] = useState(false);



  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');

  const addingNewDataToAdvice = async () => {
    // Create an empty data object

    let randomString = userData.username+Math.random().toString(36).substring(7);

    const emptyData = {
      id: advice.length, // assuming id is the index in the array, adjust as needed
      refLink: null,
      email: 'Trage die Email manuell ein',
      username: randomString, // aus irgendeinem Grund wird hierrüber die applicationID gesetzt
      beruf: '',
      titel: 'Stelle hinzufügen',
      refnr: '',
      applicationid: randomString, // added this line
      status: null,
      arbeitsort_plz: '',
      arbeitsort_ort: '',
      arbeitsort_strasse: '',
      arbeitsort_region: '',
      arbeitsort_land: '',
      arbeitsort_entfernung: '',
      arbeitsort_koordinaten_lat: '',
      arbeitsort_koordinaten_lon: '',
      arbeitgeber: 'Arbeitgeber hinzufügen',
      aktuelleVeroeffentlichungsdatum: '',
      modifikationsTimestamp: '',
      eintrittsdatum: '',
      logoHashId: '',
      hashId: '',
      externeUrl: '',
      shortText: '',
      contactPerson: '',
      contactPersonGender: '',
      comment: null,
    };
  
    // Add the empty data to the start of the advice array
    setAdvice([emptyData, ...advice]);
  
    // Post the new row to the server
    await postApplicationRequest([emptyData]);
  };

  const handleSort = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  
    // sort advice array
    const sortedAdvice = [...advice].sort((a, b) => {
      if (order === 'asc') {
        return a[property] < b[property] ? -1 : 1;
      } else {
        return a[property] > b[property] ? -1 : 1;
      }
    });
    setAdvice(sortedAdvice);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const [applicationTextEditor, setApplicationTextEditor] = useState(null);

  const [email, setEmail] = useState("");

  const [street, setStreet] = useState("");
const [ort, setOrt] = useState("");
const [plz, setPLZ] = useState("");
const [title, setTitle] = useState("");
const [company, setCompany] = useState("");
const [contactPerson, setContactPerson] = useState("");
const [gender, setGender] = useState("");
const [comment, setComment] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);
  const [hover, setHover] = useState(false);


  
  const [isEditing, setIsEditing] = useState(false); //email
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [isEditingContactPerson, setIsEditingContactPerson] = useState(false);
  const [isEditingGender, setIsEditingGender] = useState(false);
  const [isEditingComment, setIsEditingComment] = useState(false);
  
  const [isEditingPlz, setIsEditingPlz] = useState(false);
const [isEditingStreet, setIsEditingStreet] = useState(false);
const [isEditingOrt, setIsEditingOrt] = useState(false);



  const inputRef = useRef(null);
  const inputRefStreet = useRef(null);
  const plzInputRef = useRef(null);	
  const ortInputRef = useRef(null);
  const titleInputRef = useRef(null);
  const companyInputRef = useRef(null);
  const contactPersonInputRef = useRef(null);
  const genderInputRef = useRef(null);
  const commentInputRef = useRef(null);
  








  
  const handleRowClick = (index) => {
    setSelectedRow(index);
  };



  const handleInput = () => {
    setIsEditing(true);
  };

  const saveEmailChanges = async () => {
    setIsEditing(false);
    const newEmail = inputRef.current.value;
    
    setEmail(newEmail); // Set email directly with the new value
    const applicationid = advice[selectedRow].applicationid;
  
    if (applicationid) {
      try {
        await insertEmailByRefnr(newEmail, applicationid); // Use newEmail instead of tempEmail
        setAdvice((prevAdvices) => {
          const updatedAdvices = [...prevAdvices];
          updatedAdvices[selectedRow].email = newEmail;
          return updatedAdvices;
        });
      } catch (error) {
        alert("Fehler beim Speichern: " + error.message);
      }
    } else {
      alert("Fehler beim Speichern.");
    }
  };

  const saveStreetChanges = async () => {
  setIsEditingStreet(false);

  const newStreet = inputRefStreet.current.value;
  setStreet(newStreet);

  const applicationid = advice[selectedRow].applicationid;

  if (applicationid) {
    try {
      await insertStreetByRefnr(newStreet, applicationid);

      setAdvice((prevAdvices) => {
        const updatedAdvices = [...prevAdvices];
        updatedAdvices[selectedRow].arbeitsort_strasse = newStreet;
        return updatedAdvices;
      });
    } catch (error) {
      alert("Fehler beim Speichern: " + error.message);
    }
  } else {
    alert("Fehler beim Speichern.");
  }
};

const savePLZChanges = async () => {
  setIsEditingPlz(false);

  const newPLZ = plzInputRef.current.value;
  setPLZ(newPLZ);

  const applicationid = advice[selectedRow].applicationid;

  if (applicationid) {
    try {
      await insertPLZByRefnr(newPLZ, applicationid);

      setAdvice((prevAdvices) => {
        const updatedAdvices = [...prevAdvices];
        updatedAdvices[selectedRow].arbeitsort_plz = newPLZ;
        return updatedAdvices;
      });
    } catch (error) {
      alert("Fehler beim Speichern: " + error.message);
    }
  } else {
    alert("Fehler beim Speichern.");
  }
};

const saveOrtChanges = async () => {
  setIsEditingOrt(false);

  const newOrt = ortInputRef.current.value;
  setOrt(newOrt);

  const applicationid = advice[selectedRow].applicationid;

  if (applicationid) {
    try {
      await insertOrtByRefnr(newOrt, applicationid);

      setAdvice((prevAdvices) => {
        const updatedAdvices = [...prevAdvices];
        updatedAdvices[selectedRow].arbeitsort_ort = newOrt;
        return updatedAdvices;
      });
    } catch (error) {
      alert("Fehler beim Speichern: " + error.message);
    }
  } else {
    alert("Fehler beim Speichern.");
  }
};

  

  const cancelEmailChanges = () => {
    setIsEditing(false);
  };

  const cancelStreetChanges = () => {
    setIsEditingStreet(false);
    };
    
    const cancelPLZChanges = () => {
      setIsEditingPlz(false);
    };
    
    const cancelOrtChanges = () => {
    setIsEditingOrt(false);
    };
    
    const handleStreetInput = () => {
    setIsEditingStreet(true);
    };
    
    const handlePLZInput = () => {
    setIsEditingPlz(true);
    };
    
    const handleOrtInput = () => {
    setIsEditingOrt(true);
    };
    const handleTitleInput = () => {
      setIsEditingTitle(true);
  };
  
  const saveTitleChanges = async () => {
      setIsEditingTitle(false);
  
      const newTitle = titleInputRef.current.value;
      setTitle(newTitle);
  
      const applicationid = advice[selectedRow].applicationid;
  
      if (applicationid) {
          try {
              await insertTitleByRefnr(newTitle, applicationid);
  
              setAdvice((prevAdvices) => {
                  const updatedAdvices = [...prevAdvices];
                  updatedAdvices[selectedRow].titel = newTitle;
                  return updatedAdvices;
              });
          } catch (error) {
              alert("Fehler beim Speichern: " + error.message);
          }
      } else {
          alert("Fehler beim Speichern.");
      }
  };
  
  const cancelTitleChanges = () => {
      setIsEditingTitle(false);
  };
  
  const handleCompanyInput = () => {
      setIsEditingCompany(true);
  };
  
  const saveCompanyChanges = async () => {
      setIsEditingCompany(false);
  
      const newCompany = companyInputRef.current.value;
      setCompany(newCompany);
  
      const applicationid = advice[selectedRow].applicationid;
  
      if (applicationid) {
          try {
              await insertCompanyByRefnr(newCompany, applicationid);
  
              setAdvice((prevAdvices) => {
                  const updatedAdvices = [...prevAdvices];
                  updatedAdvices[selectedRow].arbeitgeber = newCompany;
                  return updatedAdvices;
              });
          } catch (error) {
              alert("Fehler beim Speichern: " + error.message);
          }
      } else {
          alert("Fehler beim Speichern.");
      }
  };
  
  const cancelCompanyChanges = () => {
      setIsEditingCompany(false);
  };

  const handleContactPersonInput = () => {
    setIsEditingContactPerson(true);
};

const saveContactPersonChanges = async () => {
    setIsEditingContactPerson(false);

    const newContactPerson = contactPersonInputRef.current.value;
    setContactPerson(newContactPerson);

    const applicationid = advice[selectedRow].applicationid;

    if (applicationid) {
        try {
            await insertContactPersonByRefnr(newContactPerson, applicationid);

            setAdvice((prevAdvices) => {
                const updatedAdvices = [...prevAdvices];
                updatedAdvices[selectedRow].contactPerson = newContactPerson;
                return updatedAdvices;
            });
        } catch (error) {
            alert("Fehler beim Speichern: " + error.message);
        }
    } else {
        alert("Fehler beim Speichern.");
    }
};

const cancelContactPersonChanges = () => {
    setIsEditingContactPerson(false);
};

const handleGenderInput = () => {
    setIsEditingGender(true);
};

const saveGenderChanges = async () => {
    setIsEditingGender(false);

    const newGender = genderInputRef.current.value;
    setGender(newGender);

    const applicationid = advice[selectedRow].applicationid;

    if (applicationid) {
        try {
            await insertGenderByRefnr(newGender, applicationid);

            setAdvice((prevAdvices) => {
                const updatedAdvices = [...prevAdvices];
                updatedAdvices[selectedRow].contactPersonGender = newGender;
                return updatedAdvices;
            });
        } catch (error) {
            alert("Fehler beim Speichern: " + error.message);
        }
    } else {
        alert("Fehler beim Speichern.");
    }
};

const cancelGenderChanges = () => {
    setIsEditingGender(false);
};

const handleCommentInput = () => {
    setIsEditingComment(true);
};

const saveCommentChanges = async () => {
    setIsEditingComment(false);

    const newComment = commentInputRef.current.value;
    setComment(newComment);

    const applicationid = advice[selectedRow].applicationid;

    if (applicationid) {
        try {
            await insertCommentByRefnr(newComment, applicationid);

            setAdvice((prevAdvices) => {
                const updatedAdvices = [...prevAdvices];
                updatedAdvices[selectedRow].comment = newComment;
                return updatedAdvices;
            });
        } catch (error) {
            alert("Fehler beim Speichern: " + error.message);
        }
    } else {
        alert("Fehler beim Speichern.");
    }
};

const cancelCommentChanges = () => {
    setIsEditingComment(false);
};

  
  


  const openApplicationTextEditorModal = (advice) => {
    setApplicationTextEditor(
      <ApplicationTextPreviewSender
        applicationTextEditor={applicationTextEditor}
        setApplicationTextEditor={setApplicationTextEditor}
        refnr={advice.refnr}
        adviceJson={[advice]}
        email={advice.email}
        userFirstname={userData.firstname}
        userLastname={userData.lastname}
        userData={userData}
        applicationid={advice.applicationid}
        ansprache={advice.contactPersonGender}
      />
    );
  };

  /* https://codesandbox.io/s/evmcy7?file=/demo.js:4112-4123 */

  //Anzahl in Table anmpassen

/*   const deleteEntry = (refnr, i) => {

    setAdvice((prev) => prev.filter((advice) => advice.refnr !== refnr));
    ApiService.deleteApplication(refnr);
  }; */

  const deleteEntry = (applicationid, i) => {
     setAdvice((prev) => prev.filter((advice) => advice.applicationid !== applicationid));
     ApiService.deleteApplication(applicationid); 
   };

  const deleteAllEntrys = () => {
    setAdvice([]);
    ApiService.deleteAllApplications();
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {applicationTextEditor}
      <Paper
        style={{
          borderRadius: "25px",
          backgroundColor: `${darkMode ? "black" : "white"}`,
          overflowX: "auto",
        }}
        sx={{ p: 2, display: "flex", flexDirection: "column" }}
      >
        <Grid item xs={12}>
          <Tooltip TransitionComponent={Zoom}  followCursor title="Behalte einen Überblick über deine Bewerbungen!">
            <Title style={{ marginLeft: "5%" }}>Bewerberboard</Title>
          </Tooltip>

 

<IconButton onClick={() => addingNewDataToAdvice()}>
  <AddBoxIcon/>
  Bewerbungsdaten manuell hinzufügen
</IconButton>

          {advice.length > 0 ? (

            
            <div className={isFullscreen ? classes.fullscreen : classes.regular}>
          <IconButton onClick={() => setIsFullscreen(!isFullscreen)}>
          {isFullscreen ? (
            <div>
              <FullscreenExitIcon />
              Minimieren
            </div>
          ) : (
            <div>
              <FullscreenIcon />
              Vollbild
            </div>
          )}
        </IconButton>

            
          <Table  stickyHeader aria-label="sticky table" size="medium">
            <TableHead>
              <TableRow>
              <Tooltip TransitionComponent={Zoom}  followCursor title="Sende jetzt deine Bewerbung über deine Email">

              <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Bewerben
                </TableCell>
                </Tooltip>

                <Tooltip TransitionComponent={Zoom}  followCursor title="Klick auf die Email um diese zu verändern">
                  <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Email
                  </TableCell>
                </Tooltip>

                <Tooltip TransitionComponent={Zoom}  followCursor title="Diese Spalte wird in deinem Anschreiben als Überschrift genutzt">
                  <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                  <TableSortLabel
              active={orderBy === 'titel'}
              direction={orderBy === 'titel' ? order : 'asc'}
              onClick={handleSort('titel')}
            >
                    Beruf
                    </TableSortLabel>
                  </TableCell>
                </Tooltip>
                <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                <TableSortLabel
              active={orderBy === 'arbeitgeber'}
              direction={orderBy === 'arbeitgeber' ? order : 'asc'}
              onClick={handleSort('arbeitgeber')}>
                  Unternehmen
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Straße
                </TableCell>
                <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                <TableSortLabel
              active={orderBy === 'arbeitsort_plz'}
              direction={orderBy === 'arbeitsort_plz' ? order : 'asc'}
              onClick={handleSort('arbeitsort_plz')}
            >
                  PLZ
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                <TableSortLabel
              active={orderBy === 'arbeitsort_ort'}
              direction={orderBy === 'arbeitsort_ort' ? order : 'asc'}
              onClick={handleSort('arbeitsort_ort')}
            >
                  Ort
                  </TableSortLabel>
                </TableCell>
   {/*              <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Ansprechpartner
                </TableCell> */}
                                <Tooltip TransitionComponent={Zoom}  followCursor title="Falls du hier nichts einträgst wird jedes Mal 'Sehr geehrte Damen und Herren,' eingetragen. Dies gilt ebenfalls für deinen Emailtext an die HR">

                <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Alternative Ansprache
                </TableCell>
                </Tooltip>

                <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Kommentar
                </TableCell>
                <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Anzeige vom:
                </TableCell>
               
                <TableCell style={{ fontSize: "15px", fontWeight: "bold" }}>
                <TableSortLabel
              active={orderBy === 'status'}
              direction={orderBy === 'status' ? order : 'asc'}
              onClick={handleSort('status')}
            >
                  Status
                  </TableSortLabel>
                </TableCell>
  
                <TableCell>
                <Tooltip TransitionComponent={Zoom} followCursor title="Lösche alle Bewerbungen - bedenke das du damit auch bereits gesendete Bewerbungen wieder verschicken kannst">
  <div style={{ width: "30px", overflowWrap: "break-word" }}>
    <button
      onClick={handleClickOpen}
      type="button"
      className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
    >
      <DeleteForeverIcon></DeleteForeverIcon>
    </button>
  </div>
</Tooltip>

<Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Bist du sicher?"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Willst du wirklich alle Bewerbungen löschen? Dies kann nicht mehr Rückgängig gemacht werden
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose} color="primary">
      Abbrechen
    </Button>
    <Button onClick={handleConfirm} color="primary" autoFocus>
      Alle löschen
    </Button>
  </DialogActions>
</Dialog>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {advice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((advice, index) => (
                <TableRow
                  key={advice.id}
                  onClick={() => handleRowClick(index)}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(null)}
                  style={{
                    backgroundColor:
                      hover === index
                        ? darkMode
                          ? "#121212"
                          : "#D3D3D3"
                        : "initial",
                  }}
                >

<TableCell
                    className="items-center justify-center"
                    style={{ marginLeft: "50px", width: "30px" }}
                  >
                    <Tooltip TransitionComponent={Zoom}  followCursor title="Vorschau deines Anschreibens zum Absenden der Bewerbung">
                      <div>
                        <button
                          style={{ marginLeft: "25px" }}
                          onClick={() => openApplicationTextEditorModal(advice)} 
                          type="button"
                          className="rounded-full text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium text-sm px-2 py-2.5 text-center mr-2 mb-2 bg-green-200 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-900"
                        >
                          <ForwardToInboxIcon></ForwardToInboxIcon>
                        </button>
                      </div>
                    </Tooltip>
                  </TableCell>




                  {/* -------------------------------------------- */}

                  <TableCell>
  <div
    style={{
      backgroundColor: `${darkMode ? "#121212" : "white"}`,
      borderRadius: "8px",
      padding: "5px",
      border: "none",
      width: "80%",
      height: "80%",
      overflowWrap: "break-word",
    }}
  >
    {index === selectedRow && isEditing ? (
      <div style={{display:"flex", flexDirection:"row"
    }}>
        <input
          ref={inputRef}
          type="text"
          defaultValue={advice.email}
          onChange={handleInput}
          style={{
            border: "1px solid #aaa",
            borderRadius: "8px",
            padding: "5px",
            color: "black",
          }}
        />
        <IconButton onClick={saveEmailChanges} color="primary">
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton onClick={cancelEmailChanges} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
    ) : (
      <p
        style={{
          width: "100px",
          overflowWrap: "break-word",
          color: `${darkMode ? "white" : "black"}`,
        }}
        onClick={() => {
          if (index === selectedRow || selectedRow === null) {
            setSelectedRow(index);
            setIsEditing(true);
          }
        }}
      >
        {advice.email == " " || advice.email == null || advice.email == ""  ? 'E-Mail hinzufügen' : advice.email}
      </p>
    )}
  </div>
</TableCell>

{/* -------------------------------------------------- */}
      

<TableCell>
<div style={{ width: isEditingTitle ? "250px" : "125px", overflowWrap: "break-word" }}>
      {index === selectedRow && isEditingTitle ? (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <input
          ref={titleInputRef}
          type="text"
          defaultValue={advice.titel}
          onChange={handleTitleInput}
          style={{
            border: "1px solid #aaa",
            borderRadius: "8px",
            padding: "5px",
            color: "black",
          }}
        />
        <IconButton onClick={saveTitleChanges} color="primary">
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton onClick={cancelTitleChanges} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
    ) : (
      <p
        onClick={() => {
          if (index === selectedRow || selectedRow === null) {
            setSelectedRow(index);
            setIsEditingTitle(true);
          }
        }}
      >
        {advice.titel ? advice.titel : advice.beruf}
      </p>
    )}
  </div>
</TableCell>

<TableCell>
<div style={{ width: isEditingCompany ? "250px" : "125px", overflowWrap: "break-word" }}>
      {index === selectedRow && isEditingCompany ? (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <input
          ref={companyInputRef}
          type="text"
          defaultValue={advice.arbeitgeber}
          onChange={handleCompanyInput}
          style={{
            border: "1px solid #aaa",
            borderRadius: "8px",
            padding: "5px",
            color: "black",
          }}
        />
        <IconButton onClick={saveCompanyChanges} color="primary">
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton onClick={cancelCompanyChanges} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
    ) : (
      <p
        onClick={() => {
          if (index === selectedRow || selectedRow === null) {
            setSelectedRow(index);
            setIsEditingCompany(true);
          }
        }}
      >
        {advice.arbeitgeber}
      </p>
    )}
  </div>
</TableCell>





                  
    <TableCell>
  <div
    style={{
      backgroundColor: `${darkMode ? "#121212" : "white"}`,
      borderRadius: "8px",
      padding: "5px",
      border: "none",
      width: "80%",
      height: "80%",
      overflowWrap: "break-word",
    }}
  >
    {index === selectedRow && isEditingStreet ? (
      <div style={{display:"flex", flexDirection:"row"
    }}>
      <input
        ref={inputRefStreet}
        type="text"
     
        onChange={handleStreetInput}
        style={{
          border: "1px solid #aaa",
          borderRadius: "8px",
          padding: "5px",
          color: "black",
        }}
      />
      <IconButton onClick={saveStreetChanges} color="primary">
        <CheckCircleOutlineIcon />
      </IconButton>
      <IconButton onClick={cancelStreetChanges} color="primary">
        <CancelIcon />
      </IconButton>
    </div>
  ) : (
<p
  style={{
    width: "100px",
    overflowWrap: "break-word",
    color: `${darkMode ? "white" : "black"}`,
  }}
  onClick={() => {
    if (index === selectedRow || selectedRow === null) {
      setSelectedRow(index);
      setIsEditingStreet(true);
    }
  }}
>
  {advice.arbeitsort_strasse == " " || advice.arbeitsort_strasse == null || advice.arbeitsort_strasse == "" ? 'Straße hinzufügen' : advice.arbeitsort_strasse}
</p>

  )}
</div>
</TableCell>
<TableCell>
  <div
    style={{
      backgroundColor: `${darkMode ? "#121212" : "white"}`,
      borderRadius: "8px",
      padding: "5px",
      border: "none",
      width: "80%",
      height: "80%",
      overflowWrap: "break-word",
    }}
  >
    {index === selectedRow && isEditingPlz ? (
      <div style={{display:"flex", flexDirection:"row"}}>
        <input
          ref={plzInputRef}
          type="text"
          defaultValue={advice.arbeitsort_plz}
          onChange={handlePLZInput}
          style={{
            border: "1px solid #aaa",
            borderRadius: "8px",
            padding: "5px",
            color: "black",
          }}
        />
        <IconButton onClick={savePLZChanges} color="primary">
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton onClick={cancelPLZChanges} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
    ) : (
<p
  style={{
    width: "100px",
    overflowWrap: "break-word",
    color: `${darkMode ? "white" : "black"}`,
  }}
  onClick={() => {
    if (index === selectedRow || selectedRow === null) {
      setSelectedRow(index);
      setIsEditingPlz(true);
    }
  }}
>
  {advice.arbeitsort_plz == " " || advice.arbeitsort_plz == null || advice.arbeitsort_plz == "" ? 'PLZ hinzufügen' : advice.arbeitsort_plz}
</p>
    )}
  </div>
</TableCell>
<TableCell>
  <div
    style={{
      backgroundColor: `${darkMode ? "#121212" : "white"}`,
      borderRadius: "8px",
      padding: "5px",
      border: "none",
      width: "80%",
      height: "80%",
      overflowWrap: "break-word",
    }}
  >
    {index === selectedRow && isEditingOrt ? (
      <div style={{display:"flex", flexDirection:"row"}}>
        <input
          ref={ortInputRef}
          type="text"
          defaultValue={advice.arbeitsort_ort}
          onChange={handleOrtInput}
          style={{
            border: "1px solid #aaa",
            borderRadius: "8px",
            padding: "5px",
            color: "black",
          }}
        />
        <IconButton onClick={saveOrtChanges} color="primary">
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton onClick={cancelOrtChanges} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
    ) : (
<p
  style={{
    width: "100px",
    overflowWrap: "break-word",
    color: `${darkMode ? "white" : "black"}`,
  }}
  onClick={() => {
    if (index === selectedRow || selectedRow === null) {
      setSelectedRow(index);
      setIsEditingOrt(true);
    }
  }}
>
  {advice.arbeitsort_ort == " " || advice.arbeitsort_ort == "" || advice.arbeitsort_ort == null ? 'Ort hinzufügen' : advice.arbeitsort_ort}
</p>
    )}
  </div>
</TableCell>



<TableCell>
  <div
    style={{
      backgroundColor: `${darkMode ? "#121212" : "white"}`,
      borderRadius: "8px",
      padding: "5px",
      border: "none",
      width: "80%",
      height: "80%",
      overflowWrap: "break-word",
    }}
  >
    {index === selectedRow && isEditingGender ? (
      <div style={{display:"flex", flexDirection:"row"}}>
        <input
          ref={genderInputRef}
          type="text"
          defaultValue={advice.contactPersonGender}
          onChange={handleGenderInput}
          style={{
            border: "1px solid #aaa",
            borderRadius: "8px",
            padding: "5px",
            color: "black",
          }}
        />
        <IconButton onClick={saveGenderChanges} color="primary">
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton onClick={cancelGenderChanges} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
    ) : (
<p
  style={{
    width: "100px",
    overflowWrap: "break-word",
    color: `${darkMode ? "white" : "black"}`,
  }}
  onClick={() => {
    if (index === selectedRow || selectedRow === null) {
      setSelectedRow(index);
      setIsEditingGender(true);
    }
  }}
>
  {advice.contactPersonGender == ""  || advice.contactPersonGender == null ? 'Alternative Ansprache hinzufügen' : advice.contactPersonGender}
</p>
    )}
  </div>
</TableCell>
<TableCell>
  <div
    style={{
      backgroundColor: `${darkMode ? "#121212" : "white"}`,
      borderRadius: "8px",
      padding: "5px",
      border: "none",
      width: "80%",
      height: "80%",
      overflowWrap: "break-word",
    }}
  >
    {index === selectedRow && isEditingComment ? (
      <div style={{display:"flex", flexDirection:"row"}}>
        <input
          ref={commentInputRef}
          type="text"
          defaultValue={advice.comment}
          onChange={handleCommentInput}
          style={{
            border: "1px solid #aaa",
            borderRadius: "8px",
            padding: "5px",
            color: "black",
          }}
        />
        <IconButton onClick={saveCommentChanges} color="primary">
          <CheckCircleOutlineIcon />
        </IconButton>
        <IconButton onClick={cancelCommentChanges} color="primary">
          <CancelIcon />
        </IconButton>
      </div>
    ) : (
      <p
        style={{
          width: "100px",
          overflowWrap: "break-word",
          color: `${darkMode ? "white" : "black"}`,
        }}
        onClick={() => {
          if (index === selectedRow || selectedRow === null) {
            setSelectedRow(index);
            setIsEditingComment(true);
          }
        }}
      >
        {advice.comment == " " || advice.comment == null ? 'Kommentar hinzufügen' : advice.comment}
      </p>
    )}
  </div>
</TableCell>
<TableCell>
<div style={{ width: "58px", overflowWrap: "break-word" }}>
  {isNaN(new Date(advice.eintrittsdatum).getTime())
    ? "-"
    : new Date(advice.eintrittsdatum).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })}
</div>

                  </TableCell>









              

                  <TableCell>
                    <div style={{ width: "50px", overflowWrap: "break-word" }}>
                      {advice.status === "nicht gesendet" ||
                      advice.status === "Outlook Daten fehlerhaft" ||
                      advice.status === "Tägliches Limit 2 KI-Anschreiben erreicht" ||
                                            advice.status === "Tägliches Limit von 10 automatischen Anschreiben erreicht" ||

                      advice.status === "kein Status gesetzt!" ||
                      advice.status === "E-Mail konnte nicht gesendet werden" ||
                      advice.status ===
                        "Ungültige E-Mail-Adresse des Empfängers" ||
                      advice.status ===
                        "Ungültige E-Mail-Adresse des Absenders oder fehlende Berechtigung zum Versenden" ||
                      advice.status ===
                        "Dein Outlook Account wurde gesperrt. Logge dich bei Outlook ein und deaktiviere Sperre" ||
                      advice.status ===
                        "E-Mail konnte nicht zugestellt werden, da der Empfänger-Mail-Server den IP-Absender blockiert" ||
                      advice.status ===
                        "Lade deinen Lebenslauf und deine Anlagen als PDF hoch!" ||
                      advice.status === 400 ? (
                        <Tooltip TransitionComponent={Zoom}  followCursor title="Es gab ein Problem beim Senden dieser Bewerbung">
                          <div>
                            <button
                              type="button"
                              className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center mr-2 mb-2"
                            >
                              {advice.status}
                            </button>
                          </div>
                        </Tooltip>
                      ) : advice.status === null ? (
                        <Tooltip TransitionComponent={Zoom}  followCursor title="Deine Bewerbung wurde vorgemerkt. Du kannst diese nun mit einem manuellem oder KI Anschreiben absenden">
                          <div>
                            <button
                              type="button"
                              className="text-white text-sm bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2"
                            >
                              vorgemerkt
                            </button>
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip TransitionComponent={Zoom}  followCursor title="Deine Bewerbung wurde erfolgreich versendet">
                          <div>
                            <button
                              type="button"
                              className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center mr-2 mb-2"
                            >
                              {advice.status}
                            </button>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>

           

                  <TableCell>
                    <Tooltip TransitionComponent={Zoom}  followCursor title="Lösche diese Bewerbung">
                      <div
                        className="items-center justify-center"
                        style={{
                          marginLeft: "10%",
                          width: "30px",
                          overflowWrap: "break-word",
                        }}
                      >
                        <button
                          onClick={() => deleteEntry(advice.applicationid, advice.id)}
                          type="button"
                          className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-1 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white bg-red-200 dark:hover:bg-red-600 dark:focus:ring-red-900"
                        >
                          <DeleteForeverIcon></DeleteForeverIcon>
                        </button>
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
             <TablePagination
             rowsPerPageOptions={[5,10, 25, 100]}
             component="div"
             count={advice.length}
             rowsPerPage={rowsPerPage}
             page={page}
             onPageChange={handleChangePage}
             onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Zeilen pro Seite:"
            backIconButtonText="Vorherige Seite"
            nextIconButtonText="Nächste Seite"
            labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} von ${count !== -1 ? count : `mehr als ${to}`}`
  }
           />
           </div>
          ) : <div> Befülle jetzt dein Bewerberboard mit Stellenanzeigen und manage deine Bewerbungen: <Link to="/"><Button variant="outlined" > Zur Jobsuche </Button></Link></div> }
        </Grid>
      </Paper>
    </ThemeProvider>
  );
}
