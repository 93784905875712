import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
/* import { red, deepPurple } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert"; 
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
*/
import ApartmentIcon from "@mui/icons-material/Apartment";
import { fetchArgeLogo } from "../api";

/* const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
})); */

export default function DetailCardCompany({
  arbeitgeber,
  arbeitsortStrasse,
  arbeitsortPlz,
  arbeitsortOrt,
  logoHashId,
  argeToken,
}) {
/*   const [expanded, setExpanded] = React.useState(false);
 */  
const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (!logoHashId) {
      return;
    }
    fetchArgeLogo(argeToken, logoHashId).then((response) => {
      const imgUrl = URL.createObjectURL(response);
      setImageSrc(imgUrl);
    });
  }, []);

/*   const handleExpandClick = () => {
    setExpanded(!expanded);
  };
 */
  return (
    <Card sx={{ minHeight: 275 }}>
      <CardHeader
        avatar={<ApartmentIcon></ApartmentIcon>}
        /*         action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        } */
        title={arbeitgeber}
        /* subheader={veroeffentlichungsDatum} */
      />

      {imageSrc ? (
        <CardMedia
          component="img"
          height="150"
          width="150"
          image={imageSrc}
          alt="Kein Logo vorhanden"
        />
      ) : null}
      <CardContent>
        {/*         <Typography variant="body2" color="text.secondary">
            {arbeitgeber}
            </Typography> */}
        <Typography variant="body2" color="text.secondary">
          {arbeitsortStrasse}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {arbeitsortPlz}&nbsp;{arbeitsortOrt}
        </Typography>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions> */}
      {/*   <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

        
        </CardContent>
      </Collapse> */}
    </Card>
  );
}
