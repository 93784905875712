import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.post('https://data.bewerbr.de/api/v1/user/forgot-password', { email });
        setMessage('Password reset link sent to your email');
      } catch (error) {
        setMessage('Error while sending password reset link');
      }
    };
  
    return (
      <Container
        style={{ backgroundColor: "white", borderRadius: "25px" }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Passwort vergessen
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              label="Email"
              fullWidth
              placeholder="Email"
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => setEmail(e.target.value)}
            />
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Typography variant="body2">
                  {message}
                </Typography>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Senden
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }
  
  export default ForgotPassword;