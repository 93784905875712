import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import { Container, Stack, Typography } from "@mui/material";
import { GoogleLogin } from '@react-oauth/google';

import CircularProgress from "@mui/material/CircularProgress";
import { returnUsernameFromJWT } from "../../../helpers/jwt";
import { gridColumnVisibilityModelSelector } from '@mui/x-data-grid';


const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    position: 'absolute',
    top: '60px',
    right: '0px',
  },
  // Override the backdrop styles
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
});

function GoogleLoginPopup() {
  const [open, setOpen] = useState(true);
  const [loadingAnimation, setLoadingAnimation] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
 
  const responseMessage = async (response) => {
    try {
      let myResponse = response;
      console.log(myResponse);
      const idToken = myResponse.credential;
      console.log(idToken);
      localStorage.setItem('token', idToken);
  
      const apiResponse = await fetch(
        `https://data.bewerbr.de/api/v1/user/register`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/text' },
          body: idToken,
        }
      );
  
      if (apiResponse.status === 200) {
        const userData = await apiResponse.json();
  
        // Call the handleGoogleLogin method
        await handleGoogleLogin2(response);
      } else {
        console.error('Failed to register user with status:', apiResponse.status);
      }
    } catch (error) {
      console.error('Failed to register user:', error);
      await handleGoogleLogin2(response);
    }
  };
  


  const handleGoogleLogin2 = async (response) => {
    try {
      let myResponse = response;
  
      const idToken = myResponse.credential;
  
  
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/text");
  
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: idToken ,
        redirect: 'follow'
      };
  
      const apiResponse = await fetch("https://data.bewerbr.de/api/v1/user/googleLogin", requestOptions);
  
      const token = await apiResponse.text();
  
  
  
  
      if (token) {
  
        localStorage.setItem('token', token);    
    
    
        const username = returnUsernameFromJWT();
    
  
        localStorage.setItem('username', username);
        setLoadingAnimation(<CircularProgress />);
        setTimeout(() => {
          setLoadingAnimation(null);
           window.open("/dashboard", "_self");
        }, 500);
      } else {
        setLoadingAnimation(null);
        alert('Falsche Email oder Passwort');
                  

      }
    } catch (error) {
      console.error(error);
    }
  };

  
  const errorMessage = (error) => {
    alert(error);
};


const handleGoogleLogin = async (response) => {
  try {
    let myResponse = response;

    const idToken = myResponse.credential;


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/text");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: idToken ,
      redirect: 'follow'
    };

    const apiResponse = await fetch("https://data.bewerbr.de/api/v1/user/googleLogin", requestOptions);

    const token = await apiResponse.text();




    if (token) {

      localStorage.setItem('token', token);    
  
  
      const username = returnUsernameFromJWT();
  

      localStorage.setItem('username', username);
      setLoadingAnimation(<CircularProgress />);
      setTimeout(() => {
        setLoadingAnimation(null);
         window.open("/dashboard", "_self");
      }, 500);
    } else {
      setLoadingAnimation(null);
      alert('Falsche Email oder Passwort');
              setLoadingAnimation(null);

    }
  } catch (error) {
    console.error(error);
  }
};


  return (
    <>
{/*       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open Popup
      </Button> */}
      { !localStorage.getItem('username') ? (
      <StyledDialog
        open={open}
        onClose={handleClose} 
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ style: { backgroundColor: "transparent" } }} // Set the backdrop color to transparent
      >
        {/* <DialogTitle id="alert-dialog-title">{"Jetzt registieren"}</DialogTitle> */}
        {/* REGISTER */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Mit Google registieren?
          </DialogContentText>
          <DialogActions>
          <Button onClick={handleClose} color="primary">
          <GoogleLogin onSuccess={responseMessage} onError={errorMessage}/>

          </Button>
        </DialogActions>
        </DialogContent>
        {/* LOGIN */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du hast schon einen Account?
          </DialogContentText>
          <DialogActions>
          <Button onClick={handleClose} color="primary">
          <GoogleLogin buttonText="Mit Google einloggen" onSuccess={handleGoogleLogin} onError={errorMessage} />
          </Button>
        </DialogActions>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            jetzt nicht
          </Button>
        </DialogActions>
      </StyledDialog>) : null}
    </>
  );
}

export default GoogleLoginPopup;
