import React, { useState, useEffect } from "react";
import "../../../tailwind.css";
import ApiService from "../../APIService";
import ApplicationModal from "./ApplicationModal";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { returnUsernameFromJWT } from "../../../helpers/jwt";
import { ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import {
  BoxContainer,
  RootContainer,
} from "../../../styles/CheckUserDataStyles";

const CheckUserData = ({ selectedJobs, modal, setModal, darkMode, darkTheme, lightTheme, }) => {

/*   const classes = useStyles(); */
  //HINTERGRUND AUSBLENDEN
  const [showModal, setShowModal] = useState(true);
  const [applicationModal, setApplicationModal] = useState(null);
  /*   const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); */
  const [userData, setUserData] = useState([]);

  /*   const { width } = useWindowDimensions(); */

  ///auch lieber übergeben als neu zu laden
  useEffect(() => {


    let username = returnUsernameFromJWT();

    if(username === null || username === undefined){
      alert("Bitte einloggen");}
    else{
    const updateUserData = async () => {
      await ApiService.getUserData(username).then((response) => {
        setUserData(response.data);
      });
    };
    updateUserData();
  }
  }, []);

  //muss zu applicationmodal

  /*   function addFormDataToApi() {
    let formdataCV = new FormData();
    formdataCV.append("file", selectedFile);
    let formdataAttach = new FormData();
    formdataAttach.append("file", selectedAttachment);
    ApiService.uploadAttachmentsAPICall(formdataCV);
    ApiService.uploadAttachmentsAPICall(formdataAttach);
  } */

  const handleSubmit = (event) => {
    event.preventDefault();

    /*   addFormDataToApi(); */

    /*     const data = new FormData(event.currentTarget);
    let username = localStorage.getItem("username"); */
    setShowModal(false);

    if (
      userData.outlookEmail === "" ||
      userData.outlookPassword === "" ||
      userData.firstname === "" ||
      userData.lastname === "" ||
      userData.street === "" ||
      userData.city === "" ||
      userData.plz === ""
    ) {
      alert("Bitte Profil vervollständigen...");
    } else {
      setApplicationModal(
        <ApplicationModal
          msg={"Prüfe dein generisches Anschreiben"}
          vorname={userData.firstname}
          nachname={userData.lastname}
          strasse={userData.street}
          plz={userData.plz}
          ort={userData.city}
          email={userData.outlookEmail}
          password={userData.outlookPassword}
          applicationText1={userData.applicationTextParagraph1}
          applicationText2={userData.applicationTextParagraph2}
          applicationText3={userData.applicationTextParagraph3}
          username={userData.username}
          selectedJobs={selectedJobs}
          applicationModal={applicationModal}
          setApplicationModal={setApplicationModal}
          modal={modal}
          setModal={setModal}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    }
  };

  const cancelModal = () => {
    setModal(false);
    setShowModal(false);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {applicationModal}
      {showModal ? (
        <>
          <RootContainer darkMode={darkMode} open={true} aria-labelledby="exampleModalScrollableLabel" aria-hidden={true} id="exampleModalScrollable" tabIndex={-1} >
            <BoxContainer darkMode={darkMode} component="form" noValidate onSubmit={handleSubmit} >

              {/* Ab hier noch tailwind */}
              <Typography
                style={{ color: `${darkMode ? "white" : "black"}` }}
                component="h1"
                variant="h5"
              >
                Prüfe deine Bewerbungsdaten - Diese sind Grundlage für dein
                generisches Anschreiben
              </Typography>
              <Grid style={{ top: "10%" }} container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <p className="text-gray-500 text-xs font-bold mb-2">
                    Vorname
                  </p>
                  <TextField
                    autoComplete="given-name"
                    hiddenLabel
                    name="firstName"
                    value={userData.firstname}
                    fullWidth
                    id="firstName"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p className="text-gray-500 text-xs font-bold mb-2">
                    Nachname
                  </p>
                  <TextField
                    fullWidth
                    hiddenLabel
                    id="lastName"
                    name="lastName"
                    value={userData.lastname}
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className="text-gray-500 text-xs font-bold mb-2">Straße</p>
                  <TextField
                    hiddenLabel
                    fullWidth
                    id="Straße"
                    name="strasse"
                    value={userData.street ? userData.street : null}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p className="text-gray-500 text-xs font-bold mb-2">PLZ</p>
                  <TextField
                    autoComplete="given-name"
                    hiddenLabel
                    name="plz"
                    required
                    fullWidth
                    id="Plz"
                    value={userData.plz ? userData.plz : null}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p className="text-gray-500 text-xs font-bold mb-2">Ort</p>
                  <TextField
                    required
                    hiddenLabel
                    fullWidth
                    id="Stadt"
                    value={
                      userData.city ? userData.city : "Bitte Stadt eintragen!"
                    }
                    name="ort"
                    autoComplete="family-name"
                  />
                </Grid>
     {/*            <Grid item xs={12}>
                  <p className="text-gray-500 text-xs font-bold mb-2">
                    Outlook E-Mail
                  </p>
                  <TextField
                    hiddenLabel
                    required
                    fullWidth
                    id="email"
                    value={
                      userData.outlookEmail
                        ? userData.outlookEmail
                        : "Bitte eine Outlook Email hinterlegen"
                    }
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className="text-gray-500 text-xs font-bold mb-2">
                    Outlook Passwort
                  </p>
                  <TextField
                    hiddenLabel
                    required
                    fullWidth
                    name="password"
                    id="password"
                    value={
                      userData.outlookPassword
                        ? userData.outlookPassword
                        : "Bitte Password eintragen!"
                    }
                    autoComplete="new-password"
                  />
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <button
                    style={{ marginTop: "6%" }}
                    className="justify-left bg-transparent text-red-600 font-semibold py-2 px-4 border border-red-500 rounded hover:bg-red-600 hover:text-red-800 border-red-600"
                    type="button"
                    onClick={() => cancelModal()}
                  >
                    Abbrechen
                  </button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/*                           if (userData.outlookEmail==="" || userData.outlookPassword==="" || userData.firstname==="" || userData.lastname==="" || userData.street==="" || userData.city==="" || userData.plz==="") {
                   */}
                  {userData.plz &&
                  userData.firstname &&
                  userData.lastname &&
                  userData.street &&
                  userData.city &&
                  userData.plz ? (
                    <Button
                      color="success"
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Weiter zum Anschreiben
                    </Button>
                  ) : (
                    <Button
                      color="error"
                      type="submit"
                      href="/dashboard"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Profil vervollständigen
                    </Button>
                  )}
                </Grid>
              </Grid>
        {/*       <Grid></Grid>

              <Grid container justifyContent="flex-end"></Grid> */}
            </BoxContainer>
          </RootContainer>
        </>
      ) : null}
    </ThemeProvider>
  );
};

export default CheckUserData;
