import * as React from 'react';
import Box from '@mui/material/Box';

import useWindowDimensions from '../../helpers/UseWindowDimensions';

import Example2 from "./DesktopStartPage/AdvanvedTable";
import AdvancedTableMobile from './MobileStartPage/AdvancedTableMobile';


function SwipeableEdgeDrawer(props) {

    const {  width } = useWindowDimensions();
  
    return (

<Box sx={{ textAlign: 'center', pt: 1 }}>
 {width > 900 ? (<Example2 />):
   (<AdvancedTableMobile />)}
</Box>

    );

}

export default SwipeableEdgeDrawer;

/* 
import PropTypes from 'prop-types';
import GavelIcon from '@mui/icons-material/Gavel';
import { Global } from '@emotion/react';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SchoolIcon from '@mui/icons-material/School';
import BuildIcon from '@mui/icons-material/Build';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import HourglassFullOutlinedIcon from '@mui/icons-material/HourglassFullOutlined';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import Favorite from '@mui/icons-material/Favorite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import BusinessIcon from '@mui/icons-material/Business';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ElderlyIcon from '@mui/icons-material/Elderly';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ConstructionIcon from '@mui/icons-material/Construction'; */
// const drawerBleeding = 56;

// const Root = styled('div')(({ theme }) => ({
//   height: '100%',
//   backgroundColor:
//     theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
// }));

// /* const StyledBox = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  
// }));

// const Puller = styled(Box)(({ theme }) => ({
//   width: 30,
//   height: "100%",
//   backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
//   borderRadius: 3,
//   position: 'absolute',
//   top: 8,
//   left: 'calc(50% - 15px)',
// }));
//  */
// function SwipeableEdgeDrawer(props) {
// /*   const { window } = props;
//   const [open, setOpen] = React.useState(false); */
//   const { height, width } = useWindowDimensions();

// /* 
//   const toggleDrawer = (newOpen) => () => {
//     setOpen(newOpen);
//   };

//   const container = window !== undefined ? () => window().document.body : undefined;
//  */
//   return (
//     <Root>
//       <CssBaseline />
// {/*       <Global
//         styles={{
//           '.MuiDrawer-root > .MuiPaper-root': {
//             height: "100%",
//             overflow: 'visible',
//           },
//         }}
//       /> */}
//       <Box sx={{ textAlign: 'center', pt: 1 }}>
//        {/*  <Button onClick={toggleDrawer(true)}>Weitere Filter</Button> */}
//         {width > 900 ? (<Example2 />):
//           (<AdvancedTableMobile />)}
//       </Box>
//       {/* anchor bottom bei */}
// {/*       <SwipeableDrawer
//         container={container}
//         anchor="bottom"
//         open={open}
//         onClose={toggleDrawer(false)}
//         onOpen={toggleDrawer(true)}
//         swipeAreaWidth={drawerBleeding}
//         disableSwipeToOpen={false}
//         ModalProps={{
//           keepMounted: true,
//         }}
//       >
//         <StyledBox
//           sx={{
//             position: 'absolute',
//             top: -drawerBleeding,
//             borderTopLeftRadius: 8,
//             borderTopRightRadius: 8,
//             visibility: 'visible',
//             right: 0,
//             left: 0,
//           }}
//         >
//           <Puller />
  
//         </StyledBox>
//         <StyledBox
//           sx={{
//             px: 2,
//             pb: 2,
//             width: 'auto',
//             height: '100%',
//             overflowY: 'hidden',
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'flex-start',
//             p: 1,
//             m: 1,
//           }}
//         >
        

//           <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
//                   <FormLabel component="legend">Arbeitszeit</FormLabel>
//                   <FormGroup>
//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<BusinessIcon />} checkedIcon={<HomeWorkIcon />} />
//                       }
//                       label="Home Office"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<HourglassFullOutlinedIcon />} checkedIcon={<HourglassFullIcon />} />
//                       }
//                       label="Vollzeit"
//                     />

//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<HourglassBottomIcon />} checkedIcon={<HourglassBottomIcon />} />
//                       }
//                       label="Teilzeit"
//                     />

//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<WorkHistoryIcon />} checkedIcon={<WorkHistoryIcon />} />
//                       }
//                       label="Minijob"
//                     />

//                   </FormGroup>
//                 </FormControl>

//                 <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
//                   <FormLabel component="legend">Berufserfahrung</FormLabel>
//                   <FormGroup>
//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<ElderlyIcon />} checkedIcon={<ElderlyIcon />} />
//                       }
//                       label="Mit Berufserfahrung"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<ChildCareIcon />} checkedIcon={<ChildCareIcon />} />
//                       }
//                       label="Berufseinsteiger/innen"
//                     />

//                   </FormGroup>
//                 </FormControl>

//                 <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
//                   <FormLabel component="legend">Ausbildungsart</FormLabel>
//                   <FormGroup>
//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<SchoolIcon />} checkedIcon={<SchoolIcon />} />
//                       }
//                       label="Ausbildung"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<HistoryEduIcon />} checkedIcon={<HistoryEduIcon />} />
//                       }
//                       label="Duales Studium"
//                     />
                  

//                   </FormGroup>
//                 </FormControl>



//                 <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
//                   <FormLabel component="legend">Branche</FormLabel>
//                   <FormGroup>
//                       <FormControlLabel
//                       control={
//                         <Checkbox icon={<ConstructionIcon />} checkedIcon={<ConstructionIcon />} />
//                       }
//                       label="Handwerk"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<LaptopChromebookIcon />} checkedIcon={<LaptopChromebookIcon />} />
//                       }
//                       label="IT"
//                     />
//                                         <FormControlLabel
//                       control={
//                         <Checkbox icon={<ShoppingBagIcon />} checkedIcon={<ShoppingBagIcon />} />
//                       }
//                       label="Wirtschaft"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox icon={<LocalHospitalIcon />} checkedIcon={<LocalHospitalIcon />} />
//                       }
//                       label="Medizin"
//                     />
//                               <FormControlLabel
//                       control={
//                         <Checkbox icon={<GavelIcon />} checkedIcon={<GavelIcon />} />
//                       }
//                       label="Jura"
//                     />

//                   </FormGroup>
//                 </FormControl>

//         </StyledBox>
//       </SwipeableDrawer> */}
//     </Root>
//   );
// }

// /* SwipeableEdgeDrawer.propTypes = {
//   window: PropTypes.func,
// }; */


// export default SwipeableEdgeDrawer;


