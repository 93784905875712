import TextareaAutosize from "@mui/base/TextareaAutosize";
import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import ApiService from "../../APIService";
import Typography from "@mui/material/Typography";
import useWindowDimensions from "../../../helpers/UseWindowDimensions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, styled } from "@mui/material";
import { TextField } from "@mui/material";
import { ApplicationParagraph, ActionButtonsContainer, SuccessModalContainer, SuccessModal, SuccessModalContent, SuccessModalHeader, SuccessModalTitle, ApplicationTextContainer, StyledTextarea } from "../../../styles/ApplicationTextStyles";


const theme = createTheme({});





const ApplicationText = ({
  applicationTextEditor,
  setApplicationTextEditor,
  userData,
  setUserData,
  darkMode,
  darkTheme,
  lightTheme,
}) => {
  const [showModal, setShowModal] = useState(true);
/*   const textarea1Ref = useRef(null); */
/*   const textarea2Ref = useRef(null); */
  const { height } = useWindowDimensions();

  const [applicationText, setApplicationText] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
 // const [applicationText2, setApplicationText2] = useState("");

 useEffect(() => {
  if (
    userData.applicationTextParagraph1 !== "" &&
    userData.applicationTextParagraph1 !== null
  ) {
    setApplicationText(userData.applicationTextParagraph1);
    
    // Split the text by line breaks
    const lines = userData.applicationTextParagraph1.split('\n');
    
    // Calculate the initial character count
    let initialCount = 0;
    for (let line of lines) {
      // Add the length of the line to the count
      initialCount += line.length;

      // If the line is shorter than 66 characters, add the remaining space
      if (line.length < 66) {
        initialCount += 66 - line.length;
      }
    }
    
    setCharacterCount(initialCount);
  } else {
/*     setApplicationText(
      //...
    ); */
  }
}, [userData]);


  const closeModal = () => {
    setApplicationTextEditor(false);
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // To prevent the page from refreshing upon submit.
    ApiService.setApplicationTexts(applicationText);
    setShowModal(false);
    setApplicationTextEditor(false);
    setUserData({
      ...userData,
      applicationTextParagraph1: applicationText,
    });
  };
  

  /* --------------------------------------------------------------------------- */



  return (
<ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
  <div
    style={{ zIndex: 1300, height: "100%" }}
    className="fixed z-auto bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
  >
    <div className="fixed inset-0 transition-opacity">
      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    {showModal ? (
      <>
        <div
          className={`bg-${
            darkMode ? "black" : "white"
          } rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-full sm:w-full`}
        >
          <div
            className={`bg-${
              darkMode ? "black" : "white"
            } px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
          >
            <h3
              style={{ marginTop: "5%" }}
              className={`text-lg leading-6 font-medium text-${
                darkMode ? "white" : "black"
              }`}
            >
              Nehme Veränderungen an deinem Anschreiben vor
            </h3>
            <div className="flex flex-col">
              <Box
                style={{ width: "100%", height: "100%" }}
                component="form"
                noValidate
                onSubmit={(e) => handleSubmit(e)}
                sx={{ mt: 3 }}
                
              >

<ApplicationParagraph style={{marginTop:"20px"}} darkMode={darkMode}>
                      Sehr geehrte Damen und Herren,
                    </ApplicationParagraph> 


<TextField
 onChange={(e) => {
  setApplicationText(e.target.value);
  
  // Split the text by line breaks
  const lines = e.target.value.split('\n');
  
  // Calculate the total character count
  let count = 0;
  for (let line of lines) {
    // Add the length of the line to the count
    count += line.length;

    // If the line is shorter than 66 characters, add the remaining space
    if (line.length < 66) {
      count += 66 - line.length;
    }
  }

  setCharacterCount(count);
}}
  required
  multiline
  minRows={4}
  rowsMax={20}
  aria-label="erster Absatz"
  placeholder="Schreibe einen ersten Absatz wieso du der/die richtige für die Stelle bist."
  defaultValue={applicationText}
  autoComplete="given-name"
  name="text1"
  fullWidth
  id="text1"
  autoFocus
  style={{
    width: "100%",
    height: height * 0.45,
    overflowY: "scroll",
    backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
  }}
/>

<ApplicationParagraph darkMode={darkMode}>
                        Mit freundlichen Grüßen
                      </ApplicationParagraph>
                      {/*  <p style={{     fontFamily: "Permanent Marker, cursive", transform: "skewX(-40deg)" }}className="text-sm pt-2 text-gray-600 flex text-left justify-left justify-start"> */}
                      <p
                        className="pt-2 text-xs lg:text-sm flex text-left justify-left justify-start"
                        style={{ marginLeft: "5%", width: "90%", color: `${darkMode ? "white" : "black"}`, fontFamily: "Permanent Marker, cursive", transform: "skewX(-40deg)", }} >
                        {userData.firstname + " " + userData.lastname}
                      </p>
{characterCount > 1450 && 
  <div className="mt-4 text-red-500 font-bold">
    Warnung: Deine Anschreiben wird länger als eine Seite. Dies wird von vielen Personalern nicht gerne gesehen und kann dazu führen, dass dein Anschreiben nicht gelesen wird.
  </div>
}


                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                <button
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-800"
                        type="submit"             >
                        Anschreiben speichern
                    </button>

                  <button
                    type="button"
                    onClick={closeModal}
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-800"
                  >
                    Änderungen verwerfen
                  </button>

        
                </div>
              </Box>
            </div>
          </div>
        </div>
      </>
    ) : null}
  </div>
</ThemeProvider>



  );
};

export default ApplicationText;
