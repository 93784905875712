import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
export function CircularProgressWithLabel(props) {
  return (
    <div className="items-center flex flex-col items-stretch justify-center">
      <hr className="solid"></hr>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                top: "7%",
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${Math.round(props.value)}%`}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <CircularProgress variant="determinate" {...props} />
          </Grid>
        </Grid>
      </Box>
      <p className="text-center" style={{color: props.darkMode ? "white" : "black"}}>
  {props.loadingText}
</p>

    </div>
  );
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}
