import React, { useState, useEffect } from "react";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import ApiService from "../../APIService";
import { fetchArgeToken, fetchArgeData } from "../api";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "../../footer/Footer";
import { useHistory } from "react-router-dom";
import "../../../tailwind.css";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import LinearProgress from "@mui/material/LinearProgress";
import xpath from "../../../helpers/xpath";
import MainTableDesktop from "./MainTableDesktop";
import { returnUsernameFromJWT } from "../../../helpers/jwt";
import { columns } from "./Columns";
import Paper from "@mui/material/Paper";
import SearchBox from "./SearchBox";
import IntroText from "../SharedComponents/IntroText";
import Filter from "./Filter";

import DarkModeToggler from "../../general/DarkModeToggler";

import { ThemeProvider, createTheme } from "@mui/material/styles";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const Example2 = () => {
  const [advice, setAdvice] = useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [location, setLocation] = React.useState("");

  const [loading, setLoading] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [umkreis, setUmkreis] = useState(0);
  const [argeToken, setArgeToken] = useState(null);
  const [maxErgebnisse, setMaxErgebnisse] = useState(0);
  const [pagination, setPagination] = useState(0);

  const [userData, setUserData] = useState({
    firstname: "No Name",
    lastname: "No Name",
    cv: "no cv",
  });

  const [arbeitszeit, setArbeitszeit] = React.useState({ snw: false, vz: false, ho: false, tz: false, mj: false, });

  const handleChangeArbeitszeit = (event) => {
    setArbeitszeit({
      ...arbeitszeit,
      [event.target.name]: event.target.checked,
    });
  };

  const history = useHistory();

  useEffect(() => {
    let expandBtn = xpath(
      "/html/body/div/div/div/div/div[4]/div/div[2]/table/thead/tr/th[1]/div/div[1]/div/span/button"
    );
    expandBtn[0].click();
    if(returnUsernameFromJWT){
      updateUserData();
    }





    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  function scrollToAdvancedTable() {
/*     const advancedTable = document.getElementById("advancedTable");
    advancedTable.scrollIntoView({ behavior: "smooth" }); */
  }

  const updateUserData = async () => {
    try {
      // Store values in variables rather than calling localStorage every time

      const user = await ApiService.getUserData(returnUsernameFromJWT());

      setUserData(user.data);

    } catch (error) {
      //console.error(error);
    }
  };

  const handleSearchChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleUmkreisChange = (event) => {
    setUmkreis(event.target.value);
  };

  const handleFetchArgeToken = async () => {
    const token = await fetchArgeToken();
    setArgeToken(token);
    return token;
  };

  const handleFetchArgeData = async (token) => {
    setPagination(() => {
      const newPagination = 1;
      //scrollToAdvancedTable();
      handleFetchArgeToken().then((token) => {
        setArgeToken(token);
        fetchArgeData(
          token,
          inputValue,
          location,
          umkreis,
          newPagination,
          arbeitszeit
        ).then((data) => {
          setMaxErgebnisse(data.maxErgebnisse);
          if (data.stellenangebote) {
            setAdvice(processData(data.stellenangebote));
          } else {
            alert("Keine Stellenangebote gefunden");
          }
        });
      });
      return newPagination;
    });
  };

  const processData = (stellenangebote) => {
    return stellenangebote.map((stellenangebot) => {
      if (stellenangebot.arbeitsort && !stellenangebot.arbeitsort.entfernung) {
        stellenangebot.arbeitsort.entfernung = 0;
      }
      if (stellenangebot.arbeitsort && !stellenangebot.arbeitsort.ort) {
        stellenangebot.arbeitsort.ort = " ";
      }
      if (stellenangebot.arbeitsort && !stellenangebot.arbeitsort.strasse) {
        stellenangebot.arbeitsort.strasse = " ";
      }
      return stellenangebot;
    });
  };

  const argeApiCallBtn = () => {
    setLoading(true);
    try {
      handleFetchArgeToken().then((token) => {
        handleFetchArgeData(token);
        setLoading(false);
      });
    } catch (error) {
    //  console.log(error);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div style={{ backgroundColor: `${darkMode ? "#1b1b1b" : "white"}` }}>
        <DarkModeToggler darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

        <IntroText
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
          userData={userData}
        />

        <Paper className="content-center justify-center items-center" style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)", border: "1px solid black", marginBottom: "50px", height: "300px", width: "85%", borderRadius: 25, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: "auto", }} >
          <SearchBox
            inputValue={inputValue}
            handleSearchChange={handleSearchChange}
            argeApiCallBtn={argeApiCallBtn}
            location={location}
            handleLocationChange={handleLocationChange}
            umkreis={umkreis}
            handleUmkreisChange={handleUmkreisChange}
            KeyboardDoubleArrowDownIcon={KeyboardDoubleArrowDownIcon}
            arbeitszeit={arbeitszeit}
            handleChangeArbeitszeit={handleChangeArbeitszeit}
          />
          <Filter
            arbeitszeit={arbeitszeit}
            handleChangeArbeitszeit={handleChangeArbeitszeit}
          />
        </Paper>

        <div id="advancedTable">
          {loading ? <LinearProgress /> : null}
          <MainTableDesktop
            darkMode={darkMode}
            setDarkMode={setDarkMode}
            toggleDarkMode={toggleDarkMode}
            columns={columns}
            advice={advice}
            MRT_Localization_DE={MRT_Localization_DE}
            maxErgebnisse={maxErgebnisse}
            pagination={pagination}
            setPagination={setPagination}
            scrollToAdvancedTable={scrollToAdvancedTable}
            setArgeToken={setArgeToken}
            setAdvice={setAdvice}
            processData={processData}
            handleFetchArgeToken={handleFetchArgeToken}
            fetchArgeData={fetchArgeData}
            inputValue={inputValue}
            location={location}
            umkreis={umkreis}
            userData={userData}
            argeToken={argeToken}
          />
        </div>

        <Router history={history}>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
};
export default Example2;
