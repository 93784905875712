import React from 'react';
import { FaInstagram, FaYoutube, FaTwitter, FaTiktok } from 'react-icons/fa';
import { BrowserRouter as Router, Link } from "react-router-dom";

import { FooterContainer, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, } from './Footer.elements';
import tableIcon from "./logo.png"
import useTransformedHostname from '../../helpers/useTransformedHostname';



function Footer() {

  const transformedHostname = useTransformedHostname();
  const currentYear = new Date().getFullYear();
  const routeChange = (route) => {
    window.location.href = route;
  }


  return (


    <FooterContainer id="footer" style={{/* position:"fixed", flexDirection:"column" */ }}>




      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle >Über uns</FooterLinkTitle>
            <FooterLink onClick={() => routeChange("/about")}>Wer sind wir?</FooterLink>
            <FooterLink onClick={() => routeChange("/referenzen")}>Referenzen</FooterLink>
            <FooterLink onClick={() => routeChange("/impressum")}>Impressum</FooterLink>
            <FooterLink onClick={() => routeChange("/agb")}>AGB</FooterLink>
            <FooterLink onClick={() => routeChange("/datenschutz")}>Datenschutz</FooterLink>

          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Kontaktiere Uns</FooterLinkTitle>
            <FooterLink onClick={() => routeChange("/kontakt")}>Kontakt</FooterLink>
            <FooterLink onClick={() => routeChange("/support")}>Support</FooterLink>
            <FooterLink onClick={() => routeChange("/standort")}>Standort</FooterLink>
            {/*  <FooterLink  onClick={()=>routeChange("/spenden")}>Spenden</FooterLink> */}
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Videos</FooterLinkTitle>
            <FooterLink onClick={() => routeChange("/")}>Erste Schritte</FooterLink>
            <FooterLink  onClick={() => routeChange("/")}>Bewerbung absenden</FooterLink>
            <FooterLink  onClick={() => routeChange("/")}>Dashboard</FooterLink>
            {/* <FooterLink  onClick={() => routeChange("/")}>Premium</FooterLink> */}
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Social Media</FooterLinkTitle>
            <FooterLink href="https://www.instagram.com/bewerbr/" target="_blank" rel="noreferrer noopener">Instagram</FooterLink>
            <FooterLink href="https://www.tiktok.com/@bewerbr" target="_blank" rel="noreferrer noopener">TikTok</FooterLink>
            <FooterLink href="https://www.youtube.com/@bewerbr/" target="_blank" rel="noreferrer noopener">Youtube</FooterLink>
            <FooterLink href="https://twitter.com/@100bewerbung" target="_blank" rel="noreferrer noopener">Twitter</FooterLink>

          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to="/">

                        <img style={{ height: 40, width: 40 }} src={tableIcon} alt="Desksharing Logo" />
                       {transformedHostname}

          </SocialLogo>
          <WebsiteRights>                              {transformedHostname} © {currentYear} </WebsiteRights>
          <SocialIcons>

            <SocialIconLink href='https://www.instagram.com/bewerbr/' target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href='https://www.youtube.com/@bewerbr/' rel='noopener noreferrer' target='_blank' aria-label='Youtube' >
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink href='https://www.twitter.com/bewerbr' target='_blank' aria-label='Twitter'>
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink href='https://www.tiktok.com/@bewerbr' target='_blank' aria-label='LinkedIn'>
              <FaTiktok />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>




  );
}

export default Footer;