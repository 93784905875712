import React from 'react';
import { Skeleton } from '@mui/material';
import { ButtonStyled } from './UserProfile';
import Box from '@mui/material/Box';

export default function UserProfilePicDesktop({userProfilePic, userEditor, uploadBtn, handleSubmit, setProfilePic, uploadProfilePic, darkMode, darkTheme, lightTheme}) {




    return(
        <div className="w-full lg:w-2/5" style={{ backgroundColor:`${darkMode ? '#1b1b1b' : 'white'}`}} >
        <div className="pt-12 pb-8 items-stretch justify-center" style={{ backgroundColor:`${darkMode ? '#1b1b1b' : 'white'}`}} >
          <div className='items-center flex flex-col items-stretch justify-center' style={{ backgroundColor:`${darkMode ? '#1b1b1b' : 'white'}`}} >


            {userProfilePic === null ? (<Skeleton variant="rounded" width={"100%"} height={300} animation="wave"></Skeleton>)
              : (<img alt="profilepic" style={{borderRadius:"25px"}} className="rounded-none lg:rounded-lg shadow-2xl lg:block" src={userProfilePic} />)}

            <Box className='items-center flex flex-col items-stretch justify-center' component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>



              {!userEditor ? (
                <div className='items-center flex flex-col items-stretch justify-center' style={{ backgroundColor:`${darkMode ? '#1b1b1b' : 'white'}`}}>
                  {!uploadBtn ? (
                    <ButtonStyled className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full"
                      variant="contained"
                      component="label"
                      color="primary"
                    >
                      Profilbild hochladen

                      <input className="rounded-full h-48  w-48 border-2 border-green-500 shadow-lg mx-auto -mt-16" type="file" id="file" name="file"  /* multiple */ onChange={(e) => setProfilePic(e)} ></input>

                    </ButtonStyled>
                  ) : (
                    <button type="submit" onClick={() => uploadProfilePic()} className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full"> Bestätigen! </button>

                  )

                  }
                </div>
              ) : (<p></p>)}
            </Box>
          </div>
        </div>
      </div>

    );
}
