import React, { useState } from "react";
import "../../../tailwind.css";
import Zoom from '@mui/material/Zoom';

import Tooltip from "@mui/material/Tooltip";
const DropdownMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonName, setButtonName] = useState("Wähle eine Stelle aus...");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (value) => {
    setButtonName(value.titel);
    props.onMenuItemClick(value);
    toggleMenu();
  };

  return (
    <div style={{ width: "100%" }} className="relative inline-block text-left">
      <Tooltip TransitionComponent={Zoom}  followCursor title="Wähle eine Stelle aus dem Bewerberboard und lass dir dazu ein Anschreiben generieren - Es werden nur Stellen welche noch nicht gesendet wurden und eine Email hinterlegt haben angezeigt">
        <button
          style={{ width: "100%" }}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none"
          onClick={toggleMenu}
        >
          {buttonName}
        </button>
      </Tooltip>
      {isOpen && (
        <div
          style={{ width: "100%" }}
          className="absolute z-10 w-96 mt-2 bg-white rounded-md shadow-xl"
        >
          {props.menuItems.map((item) => (
            <p
             /*  href="/" */
              key={item.id}
              className="block px-4 py-2 text-gray-800 hover:bg-indigo-600 hover:text-blue-100"
              onClick={() => handleMenuItemClick(item)}
            >
              {item.titel} bei {item.arbeitgeber}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
