
import { createTheme } from '@mui/material/styles';
import  Modal  from "@mui/material/Modal";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
const theme = createTheme({});

const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  export const RootContainer = styled(Modal)(({ darkMode }) => ({
    top: 0,
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    overflowX: "auto",
    height: "100%",
    width: "100%",
    zIndex: 40,
    display: "flex",
    outline: "none",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100% - 3rem)",
      justifyContent: "flex-start",
    },
  }));

  export const BoxContainer = styled(Box)(({ darkMode }) => ({
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
    display: "flex",
    width: "50%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: darkMode
      ? darkTheme.palette.background.paper
      : lightTheme.palette.background.paper,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      top: "50%",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflowX: "scroll",
      marginLeft: "0",
      marginRight: "0",
    },
    }));
  
 
