import TextareaAutosize from "@mui/base/TextareaAutosize";
import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, styled } from "@mui/material";


const theme = createTheme({});

const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  export const StyledText = styled(Typography)(({ darkMode }) => ({
    fontFamily: 'Permanent Marker, cursive',
    transform: 'skewX(-40deg)',
    fontSize: theme.typography.pxToRem(14),
    paddingTop: theme.spacing(2),
    color: darkMode
    ? darkTheme.palette.text.primary
    : lightTheme.palette.text.primary,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  }));
  
  export const InvalidText = styled('a')(({ theme }) => ({
    color: 'red',
  }));



 /*  const useStyles2 = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3),
      alignItems: "center",
      top: "0",
            overflowY: "scroll",
  
  
      [theme.breakpoints.down("sm")]: {
        overflowY: "scroll",
        alignItems: "center",
        justifyContent: "center",
        overflowX: "hidden",
        padding: theme.spacing(1),
        top: "0",
  
      },
  
  
    },
    box: {
      backgroundColor: "white",
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[6],
      display: "flex",
      width: "50%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
    //  overflowY: "scroll",
  
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0",
        marginRight: "0",
        overflowY: "scroll",
        overflowX: "hidden",
        padding: theme.spacing(1),
      },
    },
  })); */

export const SuccessModalContainer = styled("div")(({ darkMode }) => ({
    overflowX: "hidden",
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: "10px",
    zIndex: 1300,
    outline: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "95vw", // Set width to 80% of viewport width
    height: "90vh",
    margin: "auto", // Center horizontally and vertically
    backgroundColor: darkMode
      ? darkTheme.palette.background.paper
      : lightTheme.palette.background.paper,
  }));


  export const SuccessModal = styled("div")(({ darkMode }) => ({
    border: "none",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    position: "relative",
    overflowX: "hidden",

    width: "95vw", // Set width to 80% of viewport width
    height: "90vh",
    my: "6",
    backgroundColor: darkMode
      ? darkTheme.palette.background.paper
      : lightTheme.palette.background.paper,
  }));

  export const SuccessModalContent = styled("div")(({ darkMode }) => ({
    border: "none",
    overflowY: "auto",

 
    width: "95vw", // Set width to 80% of viewport width
    height: "95vh",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    outline: "none",
    overflow: "hidden",
  }));

  export const SuccessModalHeader = styled("div")(({ darkMode, theme }) => ({
    border: "none",
    height: "100px",
    borderBottom: `4px solid ${darkMode ? darkTheme.palette.divider : lightTheme.palette.divider}`, // set the border color based on darkMode
    borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: 2,
    backgroundColor: darkMode ? darkTheme.palette.background.paper : lightTheme.palette.background.paper, // set the background color based on darkMode
  }));

  export const SuccessModalTitle = styled(Typography)(({ darkMode }) => ({
    color: darkMode
      ? darkTheme.palette.text.primary
      : lightTheme.palette.text.primary,
    component: "h1",
    variant: "h3",
    textAlign: "center", // center the text
    fontSize: "1rem", // increase the font size
  }));


  export  const ApplicationTextContainer = styled("div")(({ darkMode }) => ({
    padding: theme.spacing(2),
    overflow: "hidden",
    flexGrow: 1,
    backgroundColor: darkMode
      ? darkTheme.palette.background.paper
      : lightTheme.palette.background.paper,
  }));

  export const ApplicationParagraph = styled(Typography)(({ darkMode }) => ({
    color: darkMode
      ? darkTheme.palette.text.primary
      : lightTheme.palette.text.primary,
    component: "p",
    variant: "h5",
    textAlign: "left",
   paddingLeft: theme.spacing(2), // default padding for all screen sizes
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(4), // padding for desktop screens and up
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1), // padding for mobile screens and down
  },
  }));

  

/*   export const StyledTextarea = styled(TextareaAutosize)(({ darkMode }) => ({
    width: "90%",
    height: 100,
    border: `2px solid ${darkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}`,
    borderRadius: darkTheme.shape.borderRadius,
    padding: darkTheme.spacing(2),
    marginTop: darkTheme.spacing(2),
    resize: "none",
    backgroundColor: darkMode ? darkTheme.palette.background.paper : lightTheme.palette.background.paper,
    color: darkMode ? darkTheme.palette.text.primary : lightTheme.palette.text.primary,
  })); */
  export const ActionButtonsContainer = styled("div")({
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    "& button": {
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
    });
