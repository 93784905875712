import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
  
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    overflowX: "auto",
    height: "100%",
    width: "100%",
    zIndex: 40,
    display: "flex",

    outline: "none",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100% - 3rem)",
      justifyContent: "flex-start",
    },
  },
  rootModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    overflowX: "auto",
    width: "100%",
    zIndex: 999999,
    outline: "none",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "50vh",
    },
  },
  innerContainer: {
    position: "relative",
    width: "auto",
    height: "calc(100% - 3rem)",
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(6),
    pointerEvents: "none",
    overflow: "hidden",
  },
  innerPaper: {
    backgroundColor: "white",
    width: "100%",
    overflowX: "auto",
    height: "100%",
    maxHeight: "100%",
    border: "none",
    boxShadow: theme.shadows[6],
    pointerEvents: "auto",
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflowX: "scroll",
      left: `calc(50% - 450px)`,
    },
  },
  box: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
    display: "flex",
    width: "50%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      top: "50%",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflowX: "scroll",
      marginLeft: "0",
      marginRight: "0",
    },
  },
}));

export default useStyles;
