import TextareaAutosize from "@mui/base/TextareaAutosize";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ApiService from "../../APIService";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ApplicationModalOrders from "./ApplicationModalOrders";
import Cookies from "js-cookie";
import {
  StyledText,
  InvalidText,
  ApplicationParagraph,
  ActionButtonsContainer,
  SuccessModalContainer,
  SuccessModalTitle,
  SuccessModal,
  SuccessModalContent,
  SuccessModalHeader,
} from "../../../styles/ApplicationTextStyles";
import EmailSenderModal from "../../../helpers/NotificationModals/EmailSenderModal";
import Button from "@mui/material/Button";
import Zoom from '@mui/material/Zoom';


const MultiApplicationTextPreview = ({ multiApplicationTextPreviewModal, setMultiApplicationTextPreviewModal, userData, }) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const [showModal, setShowModal] = useState(true);
  const [applicationModalOrders, setApplicationModalOders] = useState(null);
  const [overlay, setOverlay] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [emailSenderModal, setEmailSenderModal] = useState(true);

  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkMode");

    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, [darkMode]);

  const closeModal = () => {
    setShowModal(false);
    setOverlay(false);
    setMultiApplicationTextPreviewModal(false);
  };

  async function openApplicationModalOrders() {



    if (
      Cookies.get("provider") == null ||
      Cookies.get("senderEmail") == null ||
      Cookies.get("senderPasswort") == null
    ) {
      setEmailSenderModal(
        <EmailSenderModal
          checkEmailCookie={"Deine Bewerbung wird gesendet..."}
          loginRequiredModal={EmailSenderModal}
          setLoginRequiredModal={setEmailSenderModal}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {
    setShowModal(false);
    setOverlay(false);
    setApplicationModalOders(
      <ApplicationModalOrders
        modal={applicationModalOrders}
        setModal={setApplicationModalOders}
        multiApplicationTextPreviewModal={multiApplicationTextPreviewModal}
        setMultiApplicationTextPreviewModal={
          setMultiApplicationTextPreviewModal
        }
        darkMode={darkMode}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
      />
    );
      }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let concatenatedString = data.get("text1"); /* + "\n" + data.get("text2"); */
    ApiService.setTmpApplicationTextParagraph(concatenatedString);
    openApplicationModalOrders();
  };

  return (
    <>
      {emailSenderModal}
      {applicationModalOrders}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "998",
          display: overlay ? "block" : "none",
        }}
      >
        {showModal ? (
          <>
            <SuccessModalContainer darkMode={darkMode}>
              <SuccessModal darkMode={darkMode}>
                <SuccessModalHeader darkMode={darkMode}>
                  <SuccessModalTitle darkMode={darkMode}>
                    Prüfe dein Anschreiben für die Multibewerbung
                  </SuccessModalTitle>
                </SuccessModalHeader>
                <SuccessModalContent darkMode={darkMode}>
                  <ApplicationParagraph
                    style={{ marginTop: "20px" }}
                    darkMode={darkMode}
                  >
                    Sehr geehrte Damen und Herren,
                  </ApplicationParagraph>

                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                  >
                    <TextareaAutosize
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="Schreibe einen ersten Absatz wieso du der/die richtige für die Stelle bist."
                      defaultValue={userData.applicationTextParagraph1}
                      autoComplete="given-name"
                      name="text1"
                      fullWidth
                      id="text1"
                      label="Absatz1"
                      autoFocus
                      style={{
                        border: `2px solid ${
                          darkMode
                            ? darkTheme.palette.primary.main
                            : lightTheme.palette.primary.main
                        }`,
                        marginLeft: "5%",
                        width: "90%",
                        height: "75%",
                        backgroundColor: `${darkMode ? "#808080" : "white"}`,
                        color: `${darkMode ? "white" : "black"}`,
                      }}
                    />

                  {/*   <TextareaAutosize
                      color="black"
                      maxRows={4}
                      aria-label="zweiter Absatz"
                      placeholder="Schreibe einen zweiten Absatz wieso du der/die richtige für die Stelle bist."
                      defaultValue={userData.applicationTextParagraph2}
                      autoComplete="given-name"
                      name="text2"
                      fullWidth
                      id="text2"
                      label="Absatz2"
                      autoFocus
                      style={{
                        border: `2px solid ${
                          darkMode
                            ? darkTheme.palette.primary.main
                            : lightTheme.palette.primary.main
                        }`,
                        marginLeft: "5%",
                        width: "90%",
                        height: "75%",
                        backgroundColor: `${darkMode ? "#808080" : "white"}`,
                        color: `${darkMode ? "white" : "black"}`,
                      }}
                    /> */}
                    <br></br>

                    <ApplicationParagraph darkMode={darkMode}>
                      Mit freundlichen Grüßen
                    </ApplicationParagraph>

                    <StyledText
                      style={{ marginLeft: "5%" }}
                      darkMode={darkMode}
                      component="p"
                    >
                      {" "}
                      {userData.firstname ? (
                        userData.firstname
                      ) : (
                        <InvalidText>Vorname ungültig</InvalidText>
                      )}{" "}
                      {userData.lastname ? (
                        userData.lastname
                      ) : (
                        <InvalidText>Nachname ungültig</InvalidText>
                      )}{" "}
                    </StyledText>

                    <ActionButtonsContainer>
                      <button
                        className="justify-left bg-transparent text-red-600 font-semibold py-2 px-4 border border-red-500 rounded hover:bg-red-600 hover:text-red-800 border-red-600"
                        type="button"
                        onClick={() => closeModal()}
                      >
                        Abbrechen
                      </button>
                      <div style={{ width: "5%" }}></div>

                      <Tooltip TransitionComponent={Zoom}  followCursor title="Sende deine Bewerbung mit allen Anlagen zur vom Arbeitgeber hinterlegten Email">
                        <button
                          className="justify-right bg-transparent text-green-600 font-semibold py-2 px-4 border border-green-500 rounded hover:bg-green-600 hover:text-green-800 rounded"
                          type="submit"
                         // onClick={() => openApplicationModalOrders()}
                        >
                                {Cookies.get("provider") == null || Cookies.get("senderEmail") == null || Cookies.get("senderPasswort") == null ? 
                        ("Email Provider auswählen") : ("Zur Multibewerbung")
                        }
                        </button>
                      </Tooltip>
                    </ActionButtonsContainer>
                  </Box>
                </SuccessModalContent>
              </SuccessModal>
            </SuccessModalContainer>
          </>
        ) : null}
      </div>
    </>
  );
};

export default MultiApplicationTextPreview;
