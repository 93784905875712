import React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";




import BusinessIcon from '@mui/icons-material/Business';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import HourglassFullOutlinedIcon from '@mui/icons-material/HourglassFullOutlined';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import NightlifeIcon from '@mui/icons-material/Nightlife';



export const Filter = ({arbeitszeit, handleChangeArbeitszeit}) => {
    return (<div >
      <FormGroup style={{ marginLeft: "30px", display: "flex", flexDirection: "row" }}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<BusinessIcon />}
              checkedIcon={<HomeWorkIcon />}
              checked={arbeitszeit.ho}
              onChange={handleChangeArbeitszeit}
              name="ho"
            />
          }
          label="Remote Work"
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<HourglassFullOutlinedIcon />}
              checkedIcon={<HourglassFullIcon />}
              checked={arbeitszeit.vz}
              onChange={handleChangeArbeitszeit}
              name="vz"
            />
          }
          label="Vollzeit"
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<HourglassBottomIcon />}
              checkedIcon={<HourglassBottomIcon />}
              checked={arbeitszeit.tz}
              onChange={handleChangeArbeitszeit}
              name="tz"
            />
          }
          label="Teilzeit"
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<WorkHistoryIcon />}
              checkedIcon={<WorkHistoryIcon />}
              checked={arbeitszeit.mj}
              onChange={handleChangeArbeitszeit}
              name="mj"
            />
          }
          label="Minijob"
        />
  
  <FormControlLabel
          control={
            <Checkbox
              icon={<NightlifeIcon />}
              checkedIcon={<NightlifeIcon />}
              checked={arbeitszeit.snw}
              onChange={handleChangeArbeitszeit}
              name="snw"
            />
          }
          label="Schicht/Nacht/Wochenende"
        />
      </FormGroup>
      {/*           <input type="checkbox" name="snw" checked={arbeitszeit.snw} onChange={handleChangeArbeitszeit} />
        <input type="checkbox" name="vz" checked={arbeitszeit.vz} onChange={handleChangeArbeitszeit} />
        <input type="checkbox" name="ho" checked={arbeitszeit.ho} onChange={handleChangeArbeitszeit} />
        <input type="checkbox" name="tz" checked={arbeitszeit.tz} onChange={handleChangeArbeitszeit} />
        <input type="checkbox" name="mj" checked={arbeitszeit.mj} onChange={handleChangeArbeitszeit} /> */}
      {
        /*           <IconButton
           onClick={()=>handleExpandClick()}
           aria-expanded={expanded}
           aria-label="show more"
         >
           {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
           <Typography variant="body2">Filter</Typography>
           {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
           </IconButton>
         <Collapse in={expanded}>
         <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
             <FormLabel component="legend">Arbeitszeit</FormLabel>
             <FormGroup>
               <FormControlLabel
                 control={
                   <Checkbox icon={<BusinessIcon />} checkedIcon={<HomeWorkIcon />} />
                 }
                 label="Remote Work"
               />
               <FormControlLabel
                 control={
                   <Checkbox icon={<HourglassFullOutlinedIcon />} checkedIcon={<HourglassFullIcon />} />
                 }
                 label="Vollzeit"
               />
                 <FormControlLabel
                 control={
                   <Checkbox icon={<HourglassBottomIcon />} checkedIcon={<HourglassBottomIcon />} />
                 }
                 label="Teilzeit"
               />
                 <FormControlLabel
                 control={
                   <Checkbox icon={<WorkHistoryIcon />} checkedIcon={<WorkHistoryIcon />} />
                 }
                 label="Minijob"
               />
               </FormGroup>
           </FormControl>
             <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
             <FormLabel component="legend">Berufserfahrung</FormLabel>
             <FormGroup>
               <FormControlLabel
                 control={
                   <Checkbox icon={<ElderlyIcon />} checkedIcon={<ElderlyIcon />} />
                 }
                 label="Mit Berufserfahrung"
               />
               <FormControlLabel
                 control={
                   <Checkbox icon={<ChildCareIcon />} checkedIcon={<ChildCareIcon />} />
                 }
                 label="Berufseinsteiger/innen"
               />
               </FormGroup>
           </FormControl>
             <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
             <FormLabel component="legend">Ausbildungsart</FormLabel>
             <FormGroup>
               <FormControlLabel
                 control={
                   <Checkbox icon={<SchoolIcon />} checkedIcon={<SchoolIcon />} />
                 }
                 label="Ausbildung"
               />
               <FormControlLabel
                 control={
                   <Checkbox icon={<HistoryEduIcon />} checkedIcon={<HistoryEduIcon />} />
                 }
                 label="Duales Studium"
               />
             
               </FormGroup>
           </FormControl>
                 <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
             <FormLabel component="legend">Branche</FormLabel>
             <FormGroup>
                 <FormControlLabel
                 control={
                   <Checkbox icon={<ConstructionIcon />} checkedIcon={<ConstructionIcon />} />
                 }
                 label="Handwerk"
               />
               <FormControlLabel
                 control={
                   <Checkbox icon={<LaptopChromebookIcon />} checkedIcon={<LaptopChromebookIcon />} />
                 }
                 label="IT"
               />
                                   <FormControlLabel
                 control={
                   <Checkbox icon={<ShoppingBagIcon />} checkedIcon={<ShoppingBagIcon />} />
                 }
                 label="Wirtschaft"
               />
               <FormControlLabel
                 control={
                   <Checkbox icon={<LocalHospitalIcon />} checkedIcon={<LocalHospitalIcon />} />
                 }
                 label="Medizin"
               />
                         <FormControlLabel
                 control={
                   <Checkbox icon={<GavelIcon />} checkedIcon={<GavelIcon />} />
                 }
                 label="Jura"
               />
               </FormGroup>
           </FormControl>
         </Collapse> */
      }
    </div>);
  }

  export default Filter;