import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import { List, ListItem, Grid } from '@mui/material';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "../footer/Footer";

import DarkModeToggler from "../general/DarkModeToggler";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});


export default function Faq() {
  const [darkMode, setDarkMode] = useState(false);

  React.useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box style={{ backgroundColor: `${darkMode ? "#1b1b1b" : "white"}` }}>
        <DarkModeToggler darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

        <Container
          style={{ backgroundColor: `${darkMode ? "#1b1b1b" : "white"}` }}
          maxWidth="sm"
        >
          <Typography
            component="h2"
            variant="h3"
            align="center"
            color="text.primary"
            gutterBottom
            style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}

          >
            Revolutioniere deine Jobsuche: Dem ultimativen KI-gestützten Jobportal und Email-Bewerbungsservice
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >

Entdecke über 1 Million Jobangebote und nutze die Kraft der KI, um personalisierte Bewerbungsschreiben zu erstellen und schicke beliebig viele Bewerbungen auf einmal direkt zu deinem Ansprechpartner!



            Wir haben die Bewerbung für dich vereinfacht. Du musst nur noch
            deine Daten eingeben und wir kümmern uns um den Rest. Sind deine
            Daten einmal in unserem System, kannst du hunderte Bewerbunge mit
            nur einem Klick versenden. 
          </Typography>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Paper>
            <br></br>

            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
       Es gibt zwei Möglichkeiten, wie Du deine
            Bewerbung erstellen kannst:
            </Typography>
            <Typography
              variant="body1"
              align="center"
              color="text.secondary"
              paragraph
            >
              1. Manuelle Erstellung: Erstelle ein manuelles Anschreiben unter
              deinem Profil und sende dieses Anschreiben an ein Unternehmen
              deiner Wahl, der Unternehmensname wird jedesmal automatisch
              angepasst.
            </Typography>
            <Typography
              variant="body1"
              align="center"
              color="text.secondary"
              paragraph
            >
              2.Erstellung mit künstlicher Intelligenz: Hier wird dein
              Anschreiben perfekt auf die Stellenanzeige und deinen Lebenslauf
              zugeschnitten. Mit einem Klick auf Autobewerbung wird eine Email
              an das Unternehmen versendet.
            </Typography>
            <br></br>
          </Paper>
        
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Paper>
            <br></br>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Wie funktioniert die automatische Bewerbung?
            </Typography>
            <Typography
              variant="body1"
              align="center"
              color="text.secondary"
              paragraph
            >
              Du musst nur deine Daten eingeben und wir kümmern uns um den Rest.
              Sobald deine Daten in unserem System sind, kannst du hunderte
              Bewerbungen mit nur einem Klick versenden.
              Im Klartext heißt das, dass im Hintergrund deine Bewerbungsunterlagen passend zur Stelle angepassen werden, zu einer PDF zusammengefügt und schließlich über deine Email an das Unternehmen versendet werden.


            </Typography>
            <br></br>
          </Paper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <Paper hover>
            <br></br>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Welche Vorteile hast du von der Nutzung?
            </Typography>
            <Typography
  variant="body1"
  align="center"
  color="text.secondary"
  paragraph
>
  Wir unterstützen dich beim Bewerbungsprozess und bieten viele
  nützliche Funktionen wie das Optimieren deiner
  Bewerbungsunterlagen und personalisierte Tipps und Tricks für das
  Vorstellungsgespräch.

  Du hast alle deine Bewerbungen auf einem Blick in deinem Dashboard, kannst deine Bewerbungen verwalten und hast die Möglichkeit, deine Bewerbungen zu bearbeiten und zu löschen.

  Falls du deinen Lebenslauf bearbeiten möchtest, kannst du dies jederzeit mit unserem Lebenslaufgenerator tun. Du kannst auch mehrere Lebensläufe erstellen und diese für verschiedene Bewerbungen verwenden.
</Typography>


<Grid container justifyContent="center">
    <List>
      <ListItem>Zugang zu 1 Million gelisteten Jobs aus dem offiziellen deutschen Jobcenter</ListItem>
      <ListItem>KI-generierte personalisierte Bewerbungsanschreiben</ListItem>
      <ListItem>Bewerbungsprozess mit nur einem Klick</ListItem>
      <ListItem>Bewerberprofilierung, um Benutzer mit den richtigen Unternehmen und Jobs zusammenzubringen</ListItem>
      <ListItem>Sichere Speicherung von Benutzerdaten auf AWS-Servern in Europa, geschützt durch die DSGVO</ListItem>
    </List>
  </Grid>
            <br></br>
          </Paper>

          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <Paper>
  <br></br>
  <Typography
    variant="h5"
    align="center"
    color="text.secondary"
    paragraph
  >
    Kostet mich das etwas?
  </Typography>
  <Typography
    variant="body1"
    align="center"
    color="text.secondary"
    paragraph
  >
    Wir bieten sowohl kostenlose als auch Premium-Abonnementpläne, um den unterschiedlichen Bedürfnissen unserer Benutzer gerecht zu werden:
  </Typography>
  <Grid container justifyContent="center">
    <List>
    <Typography
    variant="body1"
    align="center"
    color="text.secondary"
    paragraph
  >
    Kostenloser Plan:
  </Typography>
      <ListItem>2 KI-Bewerbungen</ListItem>
      <ListItem>10 allgemeine Bewerbungen</ListItem>
    </List>
  </Grid>

  <br></br>
          <br></br>

          <Typography
    variant="body1"
    align="center"
    color="text.secondary"
    paragraph
  >

Erweitere Dein Bewerbungserlebnis

{/* Wechseln Sie zu unseren Premium-Plänen und profitieren Sie von zusätzlichen Vorteilen wie:

Exklusive Bewerbungsvorlagen
Bewerbungs- und Terminverwaltung
Höheres Bewerbungslimit */}
  </Typography>

  <Typography
    variant="body1"
    align="center"
    color="text.secondary"
    paragraph
  >

    
    Premium-Plan:
  </Typography>
  <Grid container justifyContent="center">
    <List>
{/*       <ListItem>10€ - 30 allgemeine Bewerbungen, 10 KI-Bewerbungen</ListItem>
      <ListItem>20€ - 30 allgemeine Bewerbungen, 10 KI-Bewerbungen, Terminverwaltung und zusätzliche Vorlagen</ListItem>
      <ListItem>30€ - Unbegrenzte Bewerbungen, Terminverwaltung und alle Funktionen</ListItem> */}
            <ListItem>10€ mtl. für unbegrenzt viele Bewerbungen</ListItem>

    </List>
  </Grid>
  <br></br>
</Paper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <Paper>
            <br></br>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Muss ich mich registrieren?
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color="text.secondary"
              paragraph
            >
              Ja, um das Portal nutzen zu können, musst du dich registrieren
              und deine Daten eingeben. Dies ist notwendig, damit du deine
              Bewerbungen versenden kannst.
            </Typography>
            <br></br>
          </Paper>

          <br></br>
          <br></br>
          <br></br>

          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button href="/signup" variant="contained">
              Jetzt Registieren
            </Button>
            <Button href="/dashboard" variant="outlined">
              Zum Profil
            </Button>
          </Stack>
        </Container>
      </Box>

      <Router>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
