import React from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Title from "../OverviewDashboard/Title";
import Typography from "@mui/material/Typography";
import "../../../tailwind.css";
import RocketLaunch from "@mui/icons-material/RocketLaunch";
import MultiApplicationTextPreview from "../../general/MultiApplicationSenderOrders/MultiApplicationTextPreview";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";



const MultiApplicationPaper = ({ userData, darkMode, darkTheme, lightTheme }) => {

  const [ multiApplicationTextPreviewModal, setMultiApplicationTextPreviewModal, ] = React.useState(null);

  const handleSubmit = () => {
    setMultiApplicationTextPreviewModal( <MultiApplicationTextPreview multiApplicationTextPreviewModal={multiApplicationTextPreviewModal} setMultiApplicationTextPreviewModal={ setMultiApplicationTextPreviewModal } userData={userData} /> );
  };

  return (

    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>

    <Container
      style={{
        overflowX: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Title>Multi-Bewerbung</Title>
      {multiApplicationTextPreviewModal}

      <button
        onClick={() => handleSubmit()}
        style={{ marginTop: "25px", marginLeft: "25px" }}
        type="button"
        className="flex justify-center items-center rounded-full text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium text-sm px-2 py-2.5 text-center mr-2 mb-2 bg-green-500 dark:border-green-600 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-900"
      >
        <Tooltip
          title="Sende mehrere deiner vorgemerkten Bewerbungen mit nur einem Klick - es werden nur Stellen geladen welche eine Email hinterlegt haben und noch nicht gesendet worden"
          placement="top"
        >
          <Typography
            style={{ color: "white" }}
            align="center"
            component="h1"
            variant="h5"
          >
           {/*  <RocketLaunch style={{ marginRight: "10px" }} /> */}
            Multibewerbung starten
         {/*    <RocketLaunch style={{ marginLeft: "10px" }} /> */}
          </Typography>
        </Tooltip>
      </button>
    </Container>
    </ThemeProvider>
  );
};

export default MultiApplicationPaper;
