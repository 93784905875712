import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";

import CssBaseline from "@mui/material/CssBaseline";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Footer from "../../footer/Footer";
import useTransformedHostname from "../../../helpers/useTransformedHostname";
const theme = createTheme();

export default function AGB() {
  const transformedHostname = useTransformedHostname();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar></Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              AGB
            </Typography>

            <div>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Diese Allgemeinen Geschäftsbedingungen (AGB's) gelten für alle
                  Nutzer des Jobportals {transformedHostname} und regeln die Nutzung der
                  von {transformedHostname} angebotenen Dienste und Funktionen,
                  einschließlich monatlicher Abonnements, die über PayPal
                  bezahlt werden können.
                </Typography>
              </Paper>

              <br></br>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Registrierung und Nutzerkonto Die Nutzung von {transformedHostname}
                  setzt die Registrierung und Einrichtung eines Nutzerkontos
                  voraus. Der Nutzer ist verpflichtet, bei der Registrierung
                  wahrheitsgemäße Angaben zu machen und diese bei Bedarf aktuell
                  zu halten. Der Nutzer ist für die Sicherheit seiner
                  Login-Daten verantwortlich und muss diese geheim halten.
                  {transformedHostname} haftet nicht für Schäden, die durch Missbrauch
                  der Login-Daten entstehen.
                </Typography>
              </Paper>
              <br></br>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Nutzung des Portals Der Nutzer ist verpflichtet, sich an die
                  geltenden Gesetze und Regelungen zu halten und darf keine
                  illegalen oder gesetzeswidrigen Inhalte auf dem Portal
                  veröffentlichen. Der Nutzer ist ferner verpflichtet,
                  {transformedHostname} von allen Ansprüchen Dritter freizustellen, die
                  aufgrund der Nutzung des Portals entstehen.
                </Typography>
              </Paper>
              <br></br>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Haftung {transformedHostname} übernimmt keine Gewähr für die
                  Korrektheit und Vollständigkeit der auf dem Portal
                  veröffentlichten Informationen und haftet nicht für Schäden,
                  die aus der Nutzung dieser Informationen entstehen.
                </Typography>
              </Paper>
              <br></br>

              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Rückbuchungen und Streitigkeiten bei PayPal-Zahlungen Im Falle
                  von Rückbuchungen oder Streitigkeiten im Zusammenhang mit
                  PayPal-Zahlungen ist der Nutzer verpflichtet, {transformedHostname}
                  unverzüglich zu informieren und alle erforderlichen
                  Informationen zur Klärung des Sachverhalts bereitzustellen.
                  {transformedHostname} wird sich bemühen, den Sachverhalt gemeinsam mit
                  dem Nutzer und PayPal zu klären. Sollte der Nutzer eine
                  unberechtigte Rückbuchung oder einen unberechtigten Einwand
                  gegen eine Zahlung vorbringen, ist {transformedHostname} berechtigt,
                  Schadensersatzansprüche geltend zu machen und den Nutzer für
                  die entstandenen Kosten und Aufwendungen in Rechnung zu
                  stellen.
                </Typography>
              </Paper>
              <br></br>

              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Datenschutz {transformedHostname} verpflichtet sich, die gesetzlichen
                  Bestimmungen zum Datenschutz einzuhalten und die
                  personenbezogenen Daten der Nutzer vertraulich zu behandeln.
                  Die Verarbeitung der personenbezogenen Daten erfolgt
                  ausschließlich zur Erfüllung der vertraglichen Leistungen und
                  zur Verbesserung der angebotenen Dienste. Eine Weitergabe der
                  personenbezogenen Daten an Dritte erfolgt nur im Rahmen der
                  gesetzlichen Bestimmungen oder mit ausdrücklicher Zustimmung
                  des Nutzers.
                </Typography>
              </Paper>
              <br></br>

              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  &Auml;nderungen der AGB's {transformedHostname} beh&auml;lt sich das
                  Recht vor, diese AGB's jederzeit und ohne Angabe von
                  Gr&uuml;nden zu &auml;ndern. Der Nutzer wird &uuml;ber
                  &Auml;nderungen der AGB's in geeigneter Form informiert. Die
                  fortgesetzte Nutzung des Portals nach &Auml;nderungen der
                  AGB's gilt als Zustimmung zu diesen &Auml;nderungen.
                </Typography>
              </Paper>
              <br></br>

              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Beendigung der Nutzung {transformedHostname} beh&auml;lt sich das Recht
                  vor, den Zugang zum Portal jederzeit und ohne Angabe von
                  Gr&uuml;nden zu beenden. In diesem Fall werden alle Daten des
                  Nutzers gel&ouml;scht, es sei denn, es bestehen gesetzliche
                  Aufbewahrungspflichten.
                </Typography>
              </Paper>

              <br></br>

              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Salvatorische Klausel: Sollten einzelne Bestimmungen dieser
                  AGB's unwirksam oder undurchführbar sein oder werden, so wird
                  dadurch die Gültigkeit der übrigen Bestimmungen nicht berührt.
                  Anstelle der unwirksamen oder undurchführbaren Bestimmung gilt
                  eine solche für wirksam und durchführbar gehaltene Regelung
                  als vereinbart, die dem wirtschaftlichen Zweck der unwirksamen
                  oder undurchführbaren Bestimmung möglichst nahekommt. Die
                  Parteien verpflichten sich, die unwirksame oder
                  undurchführbare Bestimmung durch eine solche wirksame und
                  durchführbare Bestimmung zu ersetzen.
                </Typography>
              </Paper>
              <br></br>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Anwendbares Recht und Gerichtsstand Diese AGB's unterliegen
                  dem Recht des Landes, in dem {transformedHostname} seinen Sitz hat. In
                  diesem Fall der Freistatt Sachsen. Im Falle von Streitigkeiten
                  aus oder im Zusammenhang mit diesen AGB's oder der Nutzung des
                  Portals, ist der Gerichtsstand der Sitz von {transformedHostname},
                  sofern der Nutzer Kaufmann, juristische Person des
                  öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen
                  ist oder keinen allgemeinen Gerichtsstand im Inland hat.
                </Typography>
              </Paper>
              <br></br>
              <br></br>

              {/*               <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Diese Allgemeinen Gesch&auml;ftsbedingungen (AGB's) gelten
                  f&uuml;r alle Nutzer des Jobportals {transformedHostname} und regeln
                  die Nutzung der von {transformedHostname} angebotenen Dienste und
                  Funktionen.
                </Typography>
              </Paper>

              <br></br>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Registrierung und Nutzerkonto Die Nutzung von {transformedHostname}
                  setzt die Registrierung und Einrichtung eines Nutzerkontos
                  voraus. Der Nutzer ist verpflichtet, bei der Registrierung
                  wahrheitsgem&auml;&szlig;e Angaben zu machen und diese bei
                  Bedarf aktuell zu halten. Der Nutzer ist f&uuml;r die
                  Sicherheit seiner Login-Daten verantwortlich und muss diese
                  geheim halten. {transformedHostname} haftet nicht f&uuml;r
                  Sch&auml;den, die durch Missbrauch der Login-Daten entstehen.
                </Typography>
              </Paper>
              <br></br>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Nutzung des Portals Der Nutzer ist verpflichtet, sich an die
                  geltenden Gesetze und Regelungen zu halten und darf keine
                  illegalen oder gesetzeswidrigen Inhalte auf dem Portal
                  ver&ouml;ffentlichen. Der Nutzer ist ferner verpflichtet,
                  {transformedHostname} von allen Anspr&uuml;chen Dritter freizustellen,
                  die aufgrund der Nutzung des Portals entstehen.
                </Typography>
              </Paper>
              <br></br>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Haftung {transformedHostname} &uuml;bernimmt keine Gew&auml;hr f&uuml;r
                  die Korrektheit und Vollst&auml;ndigkeit der auf dem Portal
                  ver&ouml;ffentlichten Informationen und haftet nicht f&uuml;r
                  Sch&auml;den, die aus der Nutzung dieser Informationen
                  entstehen.
                </Typography>
              </Paper>
              <br></br>

              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  &Auml;nderungen der AGB's {transformedHostname} beh&auml;lt sich das
                  Recht vor, diese AGB's jederzeit und ohne Angabe von
                  Gr&uuml;nden zu &auml;ndern. Der Nutzer wird &uuml;ber
                  &Auml;nderungen der AGB's in geeigneter Form informiert. Die
                  fortgesetzte Nutzung des Portals nach &Auml;nderungen der
                  AGB's gilt als Zustimmung zu diesen &Auml;nderungen.
                </Typography>
              </Paper>
              <br></br>

              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Beendigung der Nutzung {transformedHostname} beh&auml;lt sich das Recht
                  vor, den Zugang zum Portal jederzeit und ohne Angabe von
                  Gr&uuml;nden zu beenden. In diesem Fall werden alle Daten des
                  Nutzers gel&ouml;scht, es sei denn, es bestehen gesetzliche
                  Aufbewahrungspflichten.
                </Typography>
              </Paper>

              <br></br>

              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography>
                  Sollten einzelne Bestimmungen dieser AGB's unwirksam sein oder
                  werden, bleibt die Wirksamkeit der &uuml;brigen Bestimmungen
                  hiervon unber&uuml;hrt. Anstelle der unwirksamen Bestimmungen
                  treten die gesetzlichen Vorschriften. Die Parteien
                  verpflichten sich, die unwirksame Bestimmung durch eine
                  wirksame Bestimmung zu ersetzen, die dem wirtschaftlichen
                  Zweck der unwirksamen Bestimmung m&ouml;glichst
                  nahekommt.&quot;
                </Typography>
              </Paper> */}
            </div>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="outlined">
                {" "}
                <Link to="login">Jetzt Registieren</Link>
              </Button>

              <Button variant="outlined">
                {" "}
                <Link to="dashboard">Zum Profil</Link>
              </Button>
            </Stack>
          </Container>
        </Box>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          {/* Footer */}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          {/* Something here to give the footer a purpose! */}
        </Typography>
        {/* <Copyright /> */}
      </Box>
      {/* End footer */}
      <Router>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
