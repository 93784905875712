import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
/* import GoogleLogin from "react-google-login"; */
import { GoogleLogin } from '@react-oauth/google';
import { returnUsernameFromJWT } from "../../helpers/jwt";

import { login } from "../../api/Auth";
import ErrorModal from "../../helpers/NotificationModals/ErrorModal";
import useTransformedHostname from "../../helpers/useTransformedHostname";

function Copyright(props) {
    const transformedHostname = useTransformedHostname();
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
      {transformedHostname}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const [errorModal, setErrorModal] = useState(null);
  const [loadingAnimation, setLoadingAnimation] = React.useState(null);

  const errorMessage = (error) => {
    alert(error);
};


const handleGoogleLogin = async (response) => {
  try {
    let myResponse = response;

    const idToken = myResponse.credential;


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/text");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: idToken ,
      redirect: 'follow'
    };

    const apiResponse = await fetch("https://data.bewerbr.de/api/v1/user/googleLogin", requestOptions);

    const token = await apiResponse.text();




    if (token) {

      localStorage.setItem('token', token);    
  
  
      const username = returnUsernameFromJWT();
  

      localStorage.setItem('username', username);
      setLoadingAnimation(<CircularProgress />);
      setTimeout(() => {
        setLoadingAnimation(null);
         window.open("/dashboard", "_self");
      }, 500);
    } else {
      setLoadingAnimation(null);
      alert('Falsche Email oder Passwort');
              setLoadingAnimation(null);

    }
  } catch (error) {
    console.error(error);
  }
};


  


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    //in api service wird username geset
    login({ username: data.get("email"), password: data.get("password") })
      .then(setLoadingAnimation(<CircularProgress />))
      .then((resp) => {
        localStorage.setItem("token", resp);
        if (resp) {
          setTimeout(() => {
             setLoadingAnimation(null);
             
             window.open("/dashboard", "_self");
          }, 500);
       
        }
        if (!resp) {
          // setErrorModal(
          //   <ErrorModal
          //     text="Falsche Email oder Passwort"
          //     subtext=""
          //     errorModal={errorModal}
          //     setErrorModal={setErrorModal}
          //   />
          // );
                       setLoadingAnimation(null);

           alert("Falsche Email oder Passwort")
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Container
      style={{ backgroundColor: "white", borderRadius: "25px" }}
      component="main"
      maxWidth="xs"
    >
      {errorModal}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Einloggen
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            label="Email"
            fullWidth
            placeholder="Email"
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            label="Passwort"
            fullWidth
            name="password"
            placeholder="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
          />
                    <Grid container>
            <Grid item xs></Grid>
            <Grid item>
            <Link href="/forgot-password" variant="body2">
              {"Passwort vergessen?"}
            </Link>
          </Grid>
          </Grid>
          {/*             <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="merken?"
            /> */}
                        <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loadingAnimation}
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Einloggen
          </Button>
          <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={errorMessage}
        />
          <Grid container>
            <Grid item xs></Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Du hast noch keinen Account?"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <br></br>
    </Container>
  );
}
