import React,{useState} from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Zoom from '@mui/material/Zoom';
import xpath from "../../../helpers/xpath";
import LinearProgress from '@mui/material/LinearProgress';

export const PrevNextBtnDesktopTable = ({ pagination, setPagination, scrollToAdvancedTable, setArgeToken, setAdvice, processData, handleFetchArgeToken, fetchArgeData, inputValue, location, umkreis, title, prevOrNext, }) => {

  // New state to control the visibility of the LinearProgress component
  const [isLoading, setIsLoading] = useState(false);

  const scrollToElement = (page) => {
    // Calculate the table row index based on the page number
    const rowIndex = (page - 1) * 50;

    // Update the XPath expression with the calculated rowIndex
    const xpathExpression = `//*[@id="advancedTable"]/div/div[2]/table/tbody/tr[${rowIndex}]/td/div/div/div/div/div/div[1]/div/div`;

    const elements = xpath(xpathExpression);

    if (elements.length > 0) {
      elements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      console.error('Element not found for the provided XPath');
    }
  };
  
  return (
    <div>
    <Tooltip TransitionComponent={Zoom} followCursor title={`Seite ${pagination + prevOrNext} laden`}>
      <IconButton
        onClick={() => {
          setIsLoading(true);
          setPagination((prevPagination) => {
            const newPagination = prevPagination + prevOrNext;
            scrollToElement(newPagination);
            try {
              handleFetchArgeToken().then((token) => {
                setArgeToken(token);
                fetchArgeData(token, inputValue, location, umkreis, newPagination).then((data) => {
                  if (data.stellenangebote) {
                    const processedData = processData(data.stellenangebote);
                    setAdvice((prevAdvice) => [...prevAdvice, ...processedData]);
                  } else {
                    alert("Keine Stellenangebote gefunden");
                  }
                  setIsLoading(false);
                });
              });
            } catch (error) {
              //console.log(error);
              setIsLoading(false);
            }
            return newPagination;
          });
        }}
      >
        {prevOrNext === -1 ? <NavigateBeforeIcon /> : <NavigateNextIcon />}{" "}
        {title}{" "}
        {prevOrNext === -1 ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
      </IconButton>
 
    </Tooltip>
         {isLoading ? <LinearProgress /> : null}
         </div>
  );
};

export default PrevNextBtnDesktopTable;



/* GO BACK */

{/*       <IconButton
        onClick={() => {
          setPagination((prevPagination) => {
            console.log("prevPagination", prevPagination);
            const newPagination = prevPagination + prevOrNext;
            console.log("newPagination", newPagination);
            scrollToElement(newPagination);
            handleFetchArgeToken().then((token) => {
              setArgeToken(token);
              fetchArgeData( token, inputValue, location, umkreis, newPagination ).then((data) => {
                if (data.stellenangebote) {
                  setAdvice({});
                  setAdvice(processData(data.stellenangebote));
                  setAdvice(data.stellenangebote);
                } else {
                  alert("Keine Stellenangebote gefunden");
                }
              });
            });
            return newPagination;
          });
        }}
      >
        {prevOrNext === -1 ? <NavigateBeforeIcon /> : <NavigateNextIcon />}{" "}
        {title}{" "}
        {prevOrNext === -1 ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
      </IconButton> */}