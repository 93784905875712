//sessionStorage.setItem("key", "value");
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Modal, Box, Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import OutlookIcon from "./EmailIcons/Outlook.svg";
import Typography from "@mui/material/Typography";
import EmailSenderCard from "./EmailSenderCard";
import { emailModalStyles } from "../../styles/EmailModalStyles";

export const EmailSenderModal = ({
  checkEmailCookie,
  loginRequiredModal,
  setLoginRequiredModal,
  darkMode,
  darkTheme,
  lightTheme,
}) => {
  const classesChooseEmailProvider = emailModalStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };



  const cancelModal = () => {
    setLoginRequiredModal(false);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Modal
        open={true}
        aria-labelledby="exampleModalScrollableLabel"
        aria-hidden={true}
        className={classesChooseEmailProvider.rootModal}
        id="exampleModalScrollable"
        tabIndex={-1}
      >
        <Box
          style={{ backgroundColor: `${darkMode ? "black" : "white"}` }}
          className={classesChooseEmailProvider.box}
          component="form"
          noValidate
        >
          <Grid item xs={12} sm={6}>
            <div id="separator"></div>
          </Grid>

          <Paper className={classesChooseEmailProvider.root}>
            <Typography className={classesChooseEmailProvider.title}>
              Du musst dich je Sitzung mindestens einmal bei deiner Email
              Adresse anmelden.
            </Typography>

            <Grid container direction="column" spacing={2} mt={2}>
              <Grid item>
                <EmailSenderCard
                  expanded={expanded}
                  handleExpandClick={handleExpandClick}
                  OutlookIcon={OutlookIcon}
                  cancelModal={cancelModal}
                />
              </Grid>

              {/* Weitere Provider.... */}

              {/*               <Grid item>
                <Button className={classesChooseEmailProvider.button}>
                  Yahoo
                </Button>
              </Grid>

              <Grid item>
                <Button className={classesChooseEmailProvider.button}>GMX</Button>
              </Grid> */}
            </Grid>
          </Paper>

          <Grid item xs={12} sm={6}>
            <button
              style={{ marginTop: "6%" }}
              className="justify-left bg-transparent text-yellow-600 font-semibold py-2 px-4 border border-yellow-500 rounded hover:bg-yellow-600 hover:text-yellow-800 border-yellow-600"
              type="button"
              onClick={() => cancelModal()}
            >
              Abbrechen
            </button>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default EmailSenderModal;
