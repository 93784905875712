import React, { useState } from "react";
import "../../../tailwind.css";

import Typography from "@mui/material/Typography";
import { returnUsernameFromJWT } from "../../../helpers/jwt.js";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { fetchEmailFromDetailSite} from "../../AutoApplication/api";
import ApplicationRequestPreview from "./ApplicationRequestPreview";
import { ThemeProvider } from "@mui/material/styles";
import ApiService from "../../APIService";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Cookies from "js-cookie";
import EmailSenderModal from "../../../helpers/NotificationModals/EmailSenderModal";
import Zoom from '@mui/material/Zoom';

import { ApplicationParagraph, ActionButtonsContainer, SuccessModalContainer, SuccessModal, SuccessModalContent, SuccessModalHeader, SuccessModalTitle, ApplicationTextContainer, StyledTextarea }from "../../../styles/ApplicationTextStyles";
const ApplicationModal = ({ msg, vorname, nachname, strasse, plz, ort, email, password, applicationText1, applicationText2, applicationText3, usernameJWT, selectedJobs, applicationModal, setApplicationModal, modal, setModal, darkMode, darkTheme, lightTheme, }) => {
  const [showAModal, setShowAModal] = useState(true);
  const [previewModal, setPreviewModal] = useState(null);
  const [loading, setLoading] = useState(null);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [emailSenderModal, setEmailSenderModal] = useState(true);

  const myRefLinks = selectedJobs.map((item) => {
    // wenn advi  ce nicht geht dann data.stellenangebote
    let refLinkUrl =
      "https://cors.bewerbr.de/www.arbeitsagentur.de/jobsuche/jobdetail/";
    let refLinkFullUrl = refLinkUrl + item.refnr;
    return refLinkFullUrl;
  });

  const cancelModal = () => {
    setShowAModal(false);
    setApplicationModal(false);
    setModal(false);
    //alert("Gehe zu deinem Profil um dein Anschreiben zu bearbeiten!");
  };

  const adviceJson = selectedJobs.map((item) => {
    let username = returnUsernameFromJWT();
    return {
      refnr: item.refnr,
      beruf: item.beruf,
      titel: item.titel,
      arbeitgeber: item.arbeitgeber,
      aktuelleVeroeffentlichungsdatum: item.aktuelleVeroeffentlichungsdatum,
      eintrittsdatum: item.eintrittsdatum,
      arbeitsort_ort: item.arbeitsort.ort,
      arbeitsort_plz: item.arbeitsort.plz,
      arbeitsort_strasse: item.arbeitsort.strasse,
      arbeitsort_region: item.arbeitsort.region,
      arbeitsort_land: item.arbeitsort.land,
      arbeitsort_entfernung: item.arbeitsort.entfernung,
      arbeitsort_koordinaten_lat: item.arbeitsort.koordinaten.lat,
      arbeitsort_koordinaten_lon: item.arbeitsort.koordinaten.lon,
      modifikationsTimestamp: item.modifikationsTimestamp,
      eintrittsdatum: item.eintrittsdatum,
      logoHashId: item.logoHashId,
      hashId: item.hashId,
      externeUrl: item.externeUrl,
      username: username,
    };
  });

  const getMailsFromSelectedJobs = async () => {
    const promises = [];

    for (let i = 0; i < myRefLinks.length; i++) {
      const url = myRefLinks[i];
      promises.push(
        new Promise(async (resolve) => {
          setTimeout(async () => {
            if (i < myRefLinks.length) {
              if (myRefLinks.length < 100) {
                let percentFactor =
                  myRefLinks.length /
                  (myRefLinks.length / (myRefLinks.length / 100)); //100%
                let percent = (i + 1) / percentFactor;
                setLoading(
                  <CircularProgressWithLabel
                    value={percent}
                    max={myRefLinks.length}
                    loadingText={"Verfügbare Mails werden geladen..."}
                    darkMode={darkMode}
                  />
                );
              } else {
                setLoading(
                  <CircularProgressWithLabel
                    value={i}
                    max={myRefLinks.length}
                    loadingText={"Verfügbare Mails werden geladen..."}
                    darkMode={darkMode}
                  />
                );
              }
            }

            await fetchEmailFromDetailSite(url).then((data) => {
              const refnr = data[0];
              const email = data[1];

              const cleanedrefnr = refnr.substring(70);

              for (let i = 0; i < adviceJson.length; i++) {
                if (adviceJson[i].refnr === cleanedrefnr) {
                  adviceJson[i].email = email;
                  break;
                }
              }

              resolve();
            });
          }, 500 * i);
        })
      );
    }

    await Promise.all(promises);
  };

  async function handleApplicationTextChange() {


    if (
      Cookies.get("provider") == null ||
      Cookies.get("senderEmail") == null ||
      Cookies.get("senderPasswort") == null
    ) {
      setEmailSenderModal(
        <EmailSenderModal
          checkEmailCookie={"Deine Bewerbung wird gesendet..."}
          loginRequiredModal={EmailSenderModal}
          setLoginRequiredModal={setEmailSenderModal}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {







/*     if (selectedJobs.length === 0) {
      alert("Bitte wähle mindestens eine Stelle aus!");
      return;
    } */

    setDisabledBtn(true);
    getMailsFromSelectedJobs().then(() => {
      setShowAModal(false);
    /*   console.log("passed Json: " + adviceJson); */
      setPreviewModal(
        <ApplicationRequestPreview
          selectedJobs={adviceJson}
          applicationModal={applicationModal}
          setApplicationModal={handleState}
          modal={modal}
          setModal={setModal}
        ></ApplicationRequestPreview>
      );
    });

  }
  }

  const handleState = (e) => {
    setShowAModal(e);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let concatenatedString = data.get("text1"); /* + "\n" + data.get("text2"); */

    ApiService.setTmpApplicationTextParagraph(concatenatedString);
    handleApplicationTextChange();
  };

  return (
    <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "998", display: "block", }} >
    {previewModal}
    {emailSenderModal}

      {showAModal ? (
        <>
      <SuccessModalContainer darkMode={darkMode}>
                <SuccessModal darkMode={darkMode}>
                  <SuccessModalHeader darkMode={darkMode}>
                    <SuccessModalTitle darkMode={darkMode}>
                      Prüfe dein Anschreiben für die Multibewerbung
                    </SuccessModalTitle>
                  </SuccessModalHeader>
                  <SuccessModalContent darkMode={darkMode}>
                    <ApplicationParagraph style={{marginTop:"20px"}} darkMode={darkMode}>
                      Sehr geehrte Damen und Herren,
                    </ApplicationParagraph>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                  >
                    <TextareaAutosize
                      maxRows={5}
                      aria-label="maximum height"
                      placeholder="Schreibe einen ersten Absatz wieso du der/die richtige für die Stelle bist."
                      defaultValue={applicationText1}
                      autoComplete="given-name"
                      name="text1"
                      fullWidth
                      id="text1"
                      label="Absatz1"
                      autoFocus
                      style={{
                        border: `2px solid ${darkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}`,
                        width: "90%",
                        height: "75%",
                        backgroundColor: `${darkMode ? "#808080" : "white"}`,
                        color: `${darkMode ? "white" : "black"}`,
                      }}
                    />

                 {/*    <TextareaAutosize
                      color="black"
                      maxRows={8}
                      aria-label="zweiter Absatz"
                      placeholder="Schreibe einen zweiten Absatz wieso du der/die richtige für die Stelle bist."
                      defaultValue={applicationText2}
                      autoComplete="given-name"
                      name="text2"
                      fullWidth
                      id="text2"
                      label="Absatz2"
                      autoFocus
                      style={{
                        border: `2px solid ${darkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}`,
                        width: "90%",
                        height: "75%",
                        backgroundColor: `${darkMode ? "#808080" : "white"}`,
                        color: `${darkMode ? "white" : "black"}`,
                      }}
                    /> */}

    {/*                 <div style={{ textAlign: "left" }}>
                      <Tooltip
                        title="Dieser Power-Abschlusssatz wird dir ein Vorstellungsgespräch verschaffen"
                        aria-label="add"
                      >
                        <p
                          className="pt-2 text-gray-600 text-xs lg:text-sm flex items-left justify-start lg:justify-start"
                          style={{ marginLeft: "5%", width: "90%" }}
                        >
                          Gerne möchte ich Sie davon überzeugen, dass die
                          --UNTERNEHMENSNAME-- mit mir eine leistungsfähige,
                          kommunikative und verlässliche Unterstützung gewinnt
                          und motiviert ist alle aufgeführten Fähigkeiten mit
                          vollem Einsatz einzubringen, um Ihrem Unternehmen zum
                          Erfolg zu verhelfen.
                        </p>
                      </Tooltip>
                    </div> */}
                    <br></br>

             
                    <ApplicationParagraph darkMode={darkMode}>
                        Mit freundlichen Grüßen
                      </ApplicationParagraph>
                      <p
                        className="pt-2 text-xs lg:text-sm flex text-left justify-left justify-start"
                        style={{ marginLeft: "5%", width: "90%", color: `${darkMode ? "white" : "black"}`, fontFamily: "Permanent Marker, cursive", transform: "skewX(-40deg)", }} >
                      {vorname + " " + nachname}
                    </p>

                    <div className="items-center flex flex-col items-stretch justify-center">
                      {loading}
                    </div>

                    <ActionButtonsContainer>
                      <button
                        className="justify-left bg-transparent text-red-600 font-semibold py-2 px-4 border border-red-500 rounded hover:bg-red-600 hover:text-red-800 border-red-600"
                        type="button"
                        onClick={() => cancelModal()}
                      >
                        Abbrechen
                      </button>
                      <div style={{ width: "5%" }}></div>

                      <Tooltip TransitionComponent={Zoom}  followCursor title="Zur Vorschau aller Stellenanzeigen, welche bei deiner Suche eine Email hinterlegt haben. Hier kannst du über Checkboxen wählen, welche Bewerbungen du losschicken möchtest">
                        <button
                          className="justify-right bg-transparent text-green-600 font-semibold py-2 px-4 border border-green-500 rounded hover:bg-green-600 hover:text-green-800 rounded"
                          type="submit"
                          disabled={disabledBtn}
                  
                        >
                          Zur Multi-Bewerbung
                        </button>
                      </Tooltip>
                      </ActionButtonsContainer>
                    </Box>
                  </SuccessModalContent>
                </SuccessModal>
              </SuccessModalContainer>
        </>
      ) : null}
    </div>
  );
};

export default ApplicationModal;
