import TextareaAutosize from "@mui/base/TextareaAutosize";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ApiService from "../APIService";
import {
  postApplicationRequest,
  sendApplicationByApplicationIdWithTmpText,
} from "../AutoApplication/api";
import { returnUsernameFromJWT } from "../../helpers/jwt";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Cookies from "js-cookie";
import {
  StyledText,
  InvalidText,
  ApplicationParagraph,
  ActionButtonsContainer,
  SuccessModalContainer,
  SuccessModal,
  SuccessModalContent,
  SuccessModalHeader,
  SuccessModalTitle,
  ApplicationTextContainer,
  StyledTextarea,
} from "../../styles/ApplicationTextStyles";
import LoginRequiredModal from "../../helpers/NotificationModals/LoginRequiredModal";
import EmailSenderModal from "../../helpers/NotificationModals/EmailSenderModal";
import Zoom from '@mui/material/Zoom';

import useWindowDimensions from "../../helpers/UseWindowDimensions";

import TextField from "@mui/material/TextField";


import CircularProgress from "@mui/material/CircularProgress";

const ApplicationTextPreviewSender = ({
  applicationTextEditor,
  setApplicationTextEditor,
  refnr,
  adviceJson,
  email,
  userFirstname,
  userLastname,
  userData,
  applicationid,
  ansprache
}) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const { height } = useWindowDimensions();
  const [showLoginRequiredModal, setShowLoginRequiredModal] = useState(true);
  const [emailSenderModal, setEmailSenderModal] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);


  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkMode");

    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, [darkMode]);


  useEffect(() => {
    // ... other useEffect logic
    console.log("TEST")

    console.log(userData)
    // Set initial character count
    if (
      userData.applicationTextParagraph1 !== "" &&
      userData.applicationTextParagraph1 !== null &&
      userData.firstname !== "No Name" &&
      userData.lastname !== "No Name"
    ) {
      const lines = userData.applicationTextParagraph1.split('\n');
      let initialCount = 0;
      for (let line of lines) {
        initialCount += line.length;
        if (line.length < 66) {
          initialCount += 66 - line.length;
        }
      }
      setCharacterCount(initialCount);
    }
  }, [userData]);


  const closeModal = () => {
    setShowModal(false);
    setApplicationTextEditor(false);
  };


  const isUserDataIncomplete = () => {


    return (
      userData.firstname === "No Name" ||
      userData.lastname === "No Name" ||
      userData.cv === "no cv" ||
      userData.cv === null ||
      userData.plz === null ||
      userData.street === null ||
      userData.city === null
    );
  };
  

  async function sendApplication() {
    if (
      localStorage.getItem("username") == null ||
      localStorage.getItem("token") == null
    ) {
      alert("Du musst dich zuerst einloggen");
    }

    ///nur öffenen wenn senderEmail and senderPasswort nicht vorhanden
    //auch button "Bewerbung absenden" anpassen je mal cookie vorhanden oder nicht
    //email provider auswählen

    if (
      Cookies.get("provider") == null ||
      Cookies.get("senderEmail") == null ||
      Cookies.get("senderPasswort") == null
    ) {
      setEmailSenderModal(
        <EmailSenderModal
          checkEmailCookie={"Deine Bewerbung wird gesendet..."}
          loginRequiredModal={EmailSenderModal}
          setLoginRequiredModal={setEmailSenderModal}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {

      if (isUserDataIncomplete()) {
  

        alert("Vervollständige bitte zuerst dein Profil - deine Bewerbung kann sonst nicht gesendet werden! Du musst ebenfalls minds. deinen Lebenslauf hochladen.");

      } else {

      //userdatacheck
  

      setIsLoading(true);


      /* falls auf direkt bewerben über suche geklickt wird --> keine applicationid --> dann erst request senden --> dann email mit anschreiben */


      if(applicationid === null || applicationid === undefined || applicationid === ""){
        const dataMap = new Map();
        let newApplicationId;
        adviceJson.forEach((adviceJson) => {
          const key = adviceJson.refnr + adviceJson.username;
          newApplicationId = key;
          dataMap.set(key, adviceJson);
        });
        const data = dataMap.get(newApplicationId);
        await postApplicationRequest([data]);
        applicationid = newApplicationId;

      }

      if (true) {
       // await postApplicationRequest([data]);
        const response = await sendApplicationByApplicationIdWithTmpText(
          email,
          Cookies.get("senderEmail"),
          Cookies.get("senderPasswort"),
          applicationid
        );

        if (response.status === 500) {
          //alert("Bewerbung konnte nicht gesendet werden. Prüfe deine Daten!")
          setShowLoginRequiredModal(
            <LoginRequiredModal
              text={
                "Deine Bewerbung konnte nicht gesendet werden. Prüfe deine Daten!"
              }
              subtext={
                "Du kannst den Status deiner Bewerbung auch im Bewerberboard einsehen."
              }
              redirectPath={"Dashboard"}
              loginRequiredModal={showLoginRequiredModal}
              setLoginRequiredModal={setShowLoginRequiredModal}
              darkMode={darkMode}
              darkTheme={darkTheme}
              lightTheme={lightTheme}
            />
          );
        } else {
          let subTextHelper = "";
          if (response.status === "Outlook Daten fehlerhaft") {
            subTextHelper =
              "Es scheint als wären deine Outlook Daten nicht korrekt. Bitte prüfe diese in deinem Profil und schicke deine Bewerbung erneut ab.";
          }
          if (response.status === "gesendet") {
            subTextHelper =
              "Deine Bewerbung wurde erfolgreich an " +
              email +
              " gesendet. Du findest diese Bewerbung nun in deinem Outlook Postausgang.";
          }

          setShowLoginRequiredModal(
            <LoginRequiredModal
              text={"Deine Bewerbung hat den Status: " + response.status + ""}
              subtext={
                subTextHelper +
                "Du kannst den Status deiner Bewerbung auch im Bewerberboard einsehen."
              }
              redirectPath={"Dashboard"}
              loginRequiredModal={showLoginRequiredModal}
              setLoginRequiredModal={setShowLoginRequiredModal}
              darkMode={darkMode}
              darkTheme={darkTheme}
              lightTheme={lightTheme}
            />
          );
          setIsLoading(false);
          setTimeout(() => {
            setShowModal(false);
            setApplicationTextEditor(false);
          }, 2000);
        }
      }
    }

  }
    /* END USER DATA CHECK */
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let concatenatedString = data.get("text1") ;    /*  + "\n" + data.get("text2"); */



    ApiService.setTmpApplicationTextParagraph(concatenatedString);



    sendApplication();
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
    <div
      style={{ zIndex: 1300, height: "100%" }}
      className="fixed z-auto bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    >
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      {emailSenderModal}
      {showLoginRequiredModal}
      {showModal ? (
        <>
          <div
            className={`bg-${
              darkMode ? "black" : "white"
            } rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-full sm:w-full`}
          >
            <div
              className={`bg-${
                darkMode ? "black" : "white"
              } px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
            >
              <h3
                style={{ marginTop: "5%" }}
                className={`text-lg leading-6 font-medium text-${
                  darkMode ? "white" : "black"
                }`}
              >
                Sende deine Bewerbung jetzt an: {email}.
              </h3>
              <div className="flex flex-col">
                <Box
                  style={{ width: "100%", height: "100%" }}
                  component="form"
                  noValidate
                  onSubmit={(e) => handleSubmit(e)}
                  sx={{ mt: 3 }}
                >
                                            <ApplicationParagraph
                    style={{ marginTop: "20px" }}
                    darkMode={darkMode}
                  >
                  {ansprache != "" ?  ansprache : null} {ansprache == null || ansprache == "" || ansprache == " " ? "Sehr geehrte Damen und Herren," : null}
                  </ApplicationParagraph>
    
    
                                   <TextField
                                    onChange={(e) => {
        // ... existing onChange logic

        // Update character count
        const lines = e.target.value.split('\n');
        let count = 0;
        for (let line of lines) {
          count += line.length;
          if (line.length < 66) {
            count += 66 - line.length;
          }
        }
        setCharacterCount(count);
      }}
                                     minRows={4}
                                     rowsMax={20}
    aria-label="maximum height"
    placeholder="Schreibe einen ersten Absatz wieso du der/die richtige für die Stelle bist."
    defaultValue={userData.applicationTextParagraph1}
    autoComplete="given-name"
    name="text1"
    fullWidth
    id="text1"
    autoFocus
    multiline
    style={{
      width: "100%",
      height: height * 0.45,
      overflowY: "scroll",
      backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
    }}
    />

<ApplicationParagraph darkMode={darkMode}>
                      Mit freundlichen Grüßen
                    </ApplicationParagraph>
                    <StyledText style={{marginLeft: "5%"}} darkMode={darkMode} component="p"> {userFirstname ? ( userFirstname ) : ( <InvalidText>Vorname ungültig</InvalidText> )}{" "} {userLastname ? ( userLastname ) : ( <InvalidText>Nachname ungültig</InvalidText> )} </StyledText>

     


     {isLoading ?<CircularProgress /> : (
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-800"
                      type="submit"
                      //onClick={() => sendApplication()}
                    >
                      {Cookies.get("provider") == null ||
                      Cookies.get("senderEmail") == null ||
                      Cookies.get("senderPasswort") == null
                        ? "Email Provider auswählen"
                        : "Bewerbung abschicken"}
                    </button>
    
                    <button
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-800"
                      type="button"
                      onClick={() => closeModal()}
                    >
                      Abbrechen
                    </button>
                  </div>
)}

                  {characterCount > 1450 && 
      <div className="mt-4 text-red-500 font-bold">
        Warnung: Dein Anschreiben wird länger als eine Seite. Dies wird von vielen Personalern nicht gerne gesehen und kann dazu führen, dass dein Anschreiben nicht gelesen wird.
      </div>
    }
    
                  {isLoading ? <LinearProgress /> : null}
                </Box>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
    </ThemeProvider>
    
    
    
    
  );
};

export default ApplicationTextPreviewSender;
