import * as React from "react";

/* import makeStyles from '@mui/styles/makeStyles'; */
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router } from "react-router-dom";

import Footer from "../../footer/Footer";
/* const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
})); */

export default function Standort() {
/*   const classes = useStyles(); */

  return (
    <div style={{ backgroundColor: "white" }} >
      <Typography variant="h4" gutterBottom>
        Standort
      </Typography>
      <Typography variant="body1" gutterBottom>
        Unser Standort ist: Gustav-Adolf-Straße 7, 01219 Dresden
      </Typography>
      <Router>
        <Footer />
      </Router>
    </div>
  );
}
