import { makeStyles } from "@mui/styles";


export const emailModalStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3),
    },
    rootModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      overflowX: "auto",
      width: "100%",
      zIndex: 999999,
      outline: "none",
      overflowY: "hidden",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "90vh",
      },
    },
    box: {
      backgroundColor: "white",
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[6],
      display: "flex",
      width: "50%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        top: "50%",
        height: "90%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflowX: "scroll",
        marginLeft: "0",
        marginRight: "0",
      },
    },
    title: {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      width: "100%",
      color: theme.palette.text.primary,
      borderRadius: "0.375rem",
      padding: "0.75rem 1.5rem",
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
        boxShadow: theme.shadows[4],
      },
    },
  }));

  export default emailModalStyles;