//https://github.com/mui/material-ui/tree/v5.10.12/docs/data/material/getting-started/templates/dashboard
import React from "react";
import IconButton from "@mui/material/IconButton";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import Grid from "@mui/material/Grid";
import useWindowDimensions from "../../helpers/UseWindowDimensions";
export const DarkModeToggler = ({ darkMode, toggleDarkMode }) => {
  const {  width } = useWindowDimensions();

  return (
    <Grid style={{ display: "flex", justifyContent: "right", alignItems: "right", marginRight: "5%", }} container >
      <IconButton style={{ backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`, }} onClick={toggleDarkMode} >
        {darkMode ? (
          <div> <WbSunnyIcon style={{ color: "white" }} /> </div>
        ) : (
          <div> <DarkModeIcon style={{ color: `${darkMode ? "white" : "#1b1b1b"}`, }} /> </div>
        )}
      </IconButton>
      <div style={{ width: `${width > 750 ? "2%" : "5%"}`, }} ></div>
    </Grid>
  );
};
export default DarkModeToggler;
