import React, { useState, useEffect, useContext } from "react";
import { FaBars, FaTimes,FaUserAlt } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button, /* LangButton */ } from "../../globalStyles";
import { Context } from "../Wrapper";
import { FormattedMessage } from "react-intl";
import tableIcon from "./logo.png";
import Cookies from "js-cookie";
import { Link } from 'react-router-dom';
import {
  Nav,
  NavBtn,
  RegisterButton,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavBtnLink,
  NavLogoImage,
  NavbarContainerEnd

} from "./Navbar2.elements";

import useTransformedHostname from "../../helpers/useTransformedHostname";
function Navbar2() {
  const [click, setClick] = useState(false);
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const transformedHostname = useTransformedHostname();


  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos > currentScrollPos;
    setNavbarVisible(visible);
    setPrevScrollPos(currentScrollPos);
  };

  const isLoggedIn = () => {
    const username = localStorage.getItem("username");
    const token = localStorage.getItem("token");
    return username && token;
  };
  const [loggedIn] = useState(isLoggedIn());

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function logoutUserOut() {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
      <Nav style={{ display: navbarVisible ? "flex" : "none", zIndex:"99999" }}>


          <NavbarContainer id="navcontainer">
            <NavLogoImage src={tableIcon} alt="Logo" />
          <NavLogo style={{ justifyContent: "flex-start" }} onClick={closeMobileMenu} to="/" > Bewerbr </NavLogo>
         
   
         
          </NavbarContainer>


          <NavbarContainerEnd id="navendcontainer">
          <MobileIcon>
  {click ? (
    <FaTimes onClick={handleClick} />
  ) : (
<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", position: "absolute", right: 20, height: "80px", }} >
      <Link to="/dashboard" onClick={closeMobileMenu}>
        <FaUserAlt style={{ marginRight: "5px" }} />
      </Link>
      <FaBars onClick={handleClick} />
    </div>
  )}
</MobileIcon>

           <NavMenu
              style={{ zIndex: 99999 }}
              onClick={handleClick}
              click={click}
            >
              <NavItem>
                <NavLinks to="/" onClick={closeMobileMenu}>
                  <FormattedMessage id="navbar.home"></FormattedMessage>
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/jobfinder" onClick={closeMobileMenu}>
                  <FormattedMessage id="navbar.services"></FormattedMessage>
                </NavLinks>
              </NavItem>
              <NavItem>
              <NavBtn style={{color:"white"}} onClick={() => window.open('https://lebenslaufgenerator.netlify.app/de/', '_blank')}>
                                <FormattedMessage id="navbar.cvgenerator"></FormattedMessage>
              </NavBtn>
              </NavItem>
              <NavItem>
                <NavLinks to="/dashboard" onClick={closeMobileMenu}>
                  <FormattedMessage id="navbar.products"></FormattedMessage>
                </NavLinks>
              </NavItem>
            
              <NavItemBtn>
                {isLoggedIn() ? (
                  <NavBtnLink to="/login">
                    <Button primary onClick={logoutUserOut}>
                      Logout
                    </Button>
                  </NavBtnLink>
                ) : (
                  

                  <div style={{display:"flex", flexDirection:"row"}}>


<NavBtnLink to="/login">
                    <Button onClick={closeMobileMenu} fontBig primary>
                      Login
                    </Button>
                  </NavBtnLink>


                  <NavBtnLink to="/signup">
                    <RegisterButton onClick={closeMobileMenu} fontBig primary>
                      Registrieren
                    </RegisterButton>
                  </NavBtnLink>
                  </div>
             
                )}
              </NavItemBtn>

              {/*     <LangButton value={context.locale = "de-DE"} onClick={context.selectLang}>🇩🇪</LangButton>
                            <LangButton value={context.locale = "en-US"} onClick={context.selectLang}>🇺🇸</LangButton>
                            <LangButton value={context.locale = "es-MX"} onClick={context.selectLang}>🇪🇸</LangButton>
                            <LangButton value={context.locale = "ru-RU"} onClick={context.selectLang}>🇷🇺</LangButton>
 */}
            </NavMenu>
          </NavbarContainerEnd>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar2;
