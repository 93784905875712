import React, { useState, useEffect} from "react";
import ApplicationText from "./ApplicationText";
import SignpostIcon from "@mui/icons-material/Signpost";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BusinessIcon from "@mui/icons-material/Business";
import Skeleton from "@mui/material/Skeleton";
import PersonIcon from "@mui/icons-material/Person";
import Tooltip from "@mui/material/Tooltip";
import  Paper from "@mui/material/Paper";
import Zoom from '@mui/material/Zoom';

import {  StyledText, InvalidText } from "../../../styles/ApplicationTextStyles";

export default function ApplicationTextPreview({
  userProfilePic,
  setUserProfilePic,
  loading,
  setLoading,
  userData,
  setUserData,
  darkMode,
  darkTheme,
  lightTheme,
}) {
  const [applicationTextEditor, setApplicationTextEditor] = useState(null);



  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}.${month}.${year}`;

  const activateModal = () => {
    setApplicationTextEditor(
      <ApplicationText
        applicationTextEditor={applicationTextEditor}
        setApplicationTextEditor={setApplicationTextEditor}
        userData={userData}
        setUserData={setUserData}
        darkMode={darkMode}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
      />
    );
  };

  return (
    <div
      className="p-4 md:p-24 text-center lg:text-center max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0"
      style={{
        borderRadius: "25px",
        display: "block",
        padding: 30,
        backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
        position: "relative",
   
        left: 0,
        right: 0,
  
        width: "100%",
        height: "100%",
      }}
    >
      {applicationTextEditor}
      <h1
        id="username"
        style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
        className="text-3xl text-left font-bold pt-8 lg:pt-0"
      >
        So könnte dein manuelles Anschreiben aussehen:
      </h1>
      <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-green-500 opacity-25" />

      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="flex">
        <div className="w-1/2">
          {loading ? (
            <div style={{ marginTop: "3%" }}>
              <Skeleton variant="rounded" animation="wave" />
            </div>
          ) : (
            <p className="text-left pt-4 text-gray-600 text-xs lg:text-sm flex items-left justify-left lg:justify-start">
              <svg
                className="h-4 fill-current text-green-700 pr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <PersonIcon></PersonIcon>
              </svg>

              {userData.firstname && userData.lastname ? (
                `${userData.firstname} ${userData.lastname}`
              ) : (
                <a color="red">Bitte deinen Vor- und Nachnamen hinterlege! </a>
              )}
            </p>
          )}

          {loading ? (
            <div style={{ marginTop: "3%" }}>
              <Skeleton variant="rounded" animation="wave" />
            </div>
          ) : (
            <p className="text-left pt-2 text-gray-600 text-xs lg:text-sm flex items-left justify-left lg:justify-start">
              <svg
                className="h-4 fill-current text-green-700 pr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <SignpostIcon></SignpostIcon>
              </svg>
              {userData.street ? (
                userData.street
              ) : (
                <p
                  className="text-left"
                  style={{ color: "red", fontWeight: "bold" }}
                >
                  Hinterlege deine Straße!
                </p>
              )}
            </p>
          )}

          {loading ? (
            <div style={{ marginTop: "3%" }}>
              <Skeleton variant="rounded" animation="wave" />
            </div>
          ) : (
            <p className="text-left pt-2 text-gray-600 text-xs lg:text-sm flex items-left justify-left lg:justify-start">
              <svg
                className="h-4 fill-current text-green-700 pr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <LocationCityIcon></LocationCityIcon>
              </svg>

              {userData.plz && userData.city ? (
                `${userData.plz} ${userData.city}`
              ) : (
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Hinterlege deine PLZ und Stadt!
                </p>
              )}
            </p>
          )}
        </div>

        <div className="w-1/2 mt-auto mr-auto">
          <br></br>
          <br></br>
          <br></br>

          <Tooltip TransitionComponent={Zoom}  followCursor title="Ein Profilbild auf dem Anschreiben wird demnächst optional">
            {userProfilePic === null ? (
              <div className="items-center flex flex-col items-stretch justify-center">
                <Skeleton
                  variant="circular"
                  width={150}
                  height={150}
                  animation="wave"
                ></Skeleton>
              </div>
            ) : (
              <div>
              <img
  className="rounded-full h-48 w-48 object-cover shadow-lg mx-auto -mt-16 border-0 sm:border-2 sm:border-green-500"
                  src={userProfilePic}
                alt="User Profile"
              />
            </div>

            )}
          </Tooltip>
        </div>
      </div>

      <br></br>

      <p className="pt-4 text-gray-600 text-xs lg:text-sm flex text-left items-left justify-left lg:justify-start">
        <svg
          className="h-4 fill-current text-green-700 pr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <BusinessIcon></BusinessIcon>
        </svg>{" "}
        Musterarbeitgeber GmbH
      </p>
      <p className="pt-2 text-gray-600 text-xs lg:text-sm flex text-left items-left justify-left lg:justify-start">
        <svg
          className="h-4 fill-current text-green-700 pr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <SignpostIcon></SignpostIcon>
        </svg>{" "}
        Musterstraße 27a
      </p>
      <p className="pt-2 text-gray-600 text-xs lg:text-sm flex text-left items-left justify-left lg:justify-start">
        <svg
          className="h-4 fill-current text-green-700 pr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <LocationCityIcon></LocationCityIcon>
        </svg>{" "}
        12345 Musterarbeitgeber
      </p>
      <br></br>
      <br></br>
      <br></br>

      <p
        style={{ textAlign: "right" }}
        className="pt-2 text-gray-600 text-xs lg:text-sm flex text-right justify-right justify-end"
      >
        {/*    {userData.plz ? (<div>{userData.plz} &nbsp;</div>) : (<div>12345&nbsp;</div>)} */}{" "}
        {userData.city ? userData.city : "Musterstadt"}, {currentDate}
      </p>
      <h1
        style={{ color: `${darkMode ? "white" : "#1b1b1b"}`, fontWeight: "bold" }}
        className="pt-2 text-black-600 text-xs lg:text-xl flex text-left items-left justify-left lg:justify-start"
      >
        Bewerbung als Musterstellenbezeichnung
      </h1>
      <p className="pt-2 text-gray-600 text-xs lg:text-sm flex text-left items-left justify-left lg:justify-start">
        Sehr geehrte Damen und Herren,
      </p>
      <p className="pt-2 text-gray-600 text-xs lg:text-sm flex text-left justify-left lg:justify-start max-w-4xl mx-auto">
  {userData.applicationTextParagraph1 ? userData.applicationTextParagraph1 : "Trag einen Absatz für dein Anschreiben ein!"}
</p>
{/* <p className="pt-2 text-gray-600 text-xs lg:text-sm flex text-left justify-left lg:justify-start max-w-4xl mx-auto">
  {userData.applicationTextParagraph1 ? userData.applicationTextParagraph2 : "Trag einen Absatz für dein Anschreiben ein!"}
</p> */}



      <p className="pt-2 text-gray-600 text-xs lg:text-sm flex text-left justify-left lg:justify-start">
        Mit freundlichen Grüßen
      </p>

      <StyledText darkMode={darkMode} component="p"> {userData.firstname ? ( userData.firstname ) : ( <InvalidText>Vorname ungültig</InvalidText> )}{" "} {userData.lastname ? ( userData.lastname ) : ( <InvalidText>Nachname ungültig</InvalidText> )} </StyledText>

      <br />
      <br />
      <button
        onClick={() => activateModal()}
        className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full"
      >
        Anschreiben bearbeiten
      </button>
    </div>
  );
}
