import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "react-router-dom/Link"
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import CircularProgress from "@mui/material/CircularProgress";
import { registerUser } from "../../api/Auth";
import ErrorModal from "../../helpers/NotificationModals/ErrorModal";
import { GoogleLogin } from '@react-oauth/google';
import { returnUsernameFromJWT } from "../../helpers/jwt";
import { makeStyles } from "@mui/styles";

import { createTheme } from "@mui/material/styles";
import useTransformedHostname from "../../helpers/useTransformedHostname";
function Copyright(props) {
  const transformedHostname = useTransformedHostname();
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="/">
      {transformedHostname}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({

});


export default function SignUp() {



  const responseMessage = async (response) => {
    try {
      let myResponse = response;
      const idToken = myResponse.credential;
      localStorage.setItem('token', idToken);
  
      const apiResponse = await fetch(
        `https://data.bewerbr.de/api/v1/user/register`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/text' },
          body: idToken,
        }
      );
  
      if (apiResponse.status === 200) {
        const userData = await apiResponse.json();
  
        // Call the handleGoogleLogin method
        await handleGoogleLogin(response);
      } else {
        console.error('Failed to register user with status:', apiResponse.status);
      }
    } catch (error) {
      console.error('Failed to register user:', error);
      await handleGoogleLogin(response);
    }
  };
  


  const handleGoogleLogin = async (response) => {
    try {
      let myResponse = response;
  
      const idToken = myResponse.credential;
  
  
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/text");
  
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: idToken ,
        redirect: 'follow'
      };
  
      const apiResponse = await fetch("https://data.bewerbr.de/api/v1/user/googleLogin", requestOptions);
  
      const token = await apiResponse.text();
  
  
  
  
      if (token) {
  
        localStorage.setItem('token', token);    
    
    
        const username = returnUsernameFromJWT();
    
  
        localStorage.setItem('username', username);
        setLoadingAnimation(<CircularProgress />);
        setTimeout(() => {
          setLoadingAnimation(null);
           window.open("/dashboard", "_self");
        }, 500);
      } else {
        setLoadingAnimation(null);
        alert('Falsche Email oder Passwort');
                  

      }
    } catch (error) {
      console.error(error);
    }
  };
  

const errorMessage = (error) => {
    alert(error);
};

 // const classes = useStyles(theme);

 const classes = "";

  const useStyles = makeStyles((theme) => ({
    link: {
      textDecoration: 'none',
      color: "black",
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }));
  
  const [loadingAnimation, setLoadingAnimation] = React.useState(null);
  const history = useHistory();
  const [isRegistered, setIsRegistered] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState(''); // state to keep track of the confirmation password
  const [errorModal, setErrorModal] = useState(null);

  const [checked, setChecked] = React.useState(false);

  const handlePasswordConfirm = (event) => {
    setPasswordConfirm(event.target.value);
  };
  

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  useEffect(() => {
    if (isRegistered) {
      history.push("/login");
    }
  }, [isRegistered, history]);


  function clearForm() {
    const form = document.querySelector("form");
    form.reset();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  
    let regexEmail =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    
    let regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/;
    
    let email = data.get("email").match(regexEmail);
    let password = data.get("password").match(regexPassword);
    let confirmPassword = data.get("passwordConfirm").match(regexPassword);
  
    // Check if both password fields match
    if (password && confirmPassword && password[0] !== confirmPassword[0]) {
      alert('Die Passwörter stimmen nicht überein.');
      return;
    }
  
    if (email && checked && password && confirmPassword) {
      registerUser({
        username: data.get("email"),
        password: data.get("password"),
        email: data.get("email"),
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
      })
        .then(setLoadingAnimation(<CircularProgress />))
        .then(() => clearForm())
        .then(() => setLoadingAnimation(null))
        .then(() => setIsRegistered(true))
        .catch((error) => console.log(error));
    } else {
      if (!email) {
        alert("Ungültige Email.");
      }
      if (!checked) {
        alert("Bitte akzeptiere die AGB.");
      }
      if (!password) {
        alert("Das Passwort ist nicht stark genug. Es sollte mindestens 8 Zeichen lang sein, mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.");
      }
      if (!confirmPassword) {
        alert('Die Passwörter stimmen nicht überein.');
      }
    }
  };
  
  




  return (
    <Container
      style={{ backgroundColor: "white", borderRadius: "25px" }}
      component="main"
      maxWidth="xs"
    >
      {errorModal}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrieren
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                placeholder="Vorname"
                id="firstName"
                label="Vorname"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                placeholder="Nachname"
                label="Nachname"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                placeholder="Email"
                id="email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                placeholder="Passwort"
                name="password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
             <Grid item xs={12}>
            <TextField
              required
              fullWidth
              placeholder="Passwort bestätigen"
              name="passwordConfirm"
              type="password"
              id="passwordConfirm"
              onChange={handlePasswordConfirm}
              autoComplete="new-password"
            />
          </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="Ich will via Email über Neuigkeiten informiert werden."
              />
              <FormControlLabel
      control={
        <Checkbox
          value="acceptAGB"
          color="primary"
          onChange={handleChange}
          required
        />
      }
      label={
        <>
          Hiermit bestätige ich die{' '}
          <a href="/agb" className={classes.link}>
            AGB
          </a>
          .
        </>
      }
    />
            </Grid>
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loadingAnimation}
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Registieren
          </Button>

                      <GoogleLogin onSuccess={responseMessage} onError={errorMessage} disabled={!checked}  onClick={() => {
    if (!checked) {
      alert("Akzeptiere bitte die AGB, bevor du fortfährst.");
    }
  }}/>


          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Du hast bereits einen Account?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
      <br></br>
    </Container>
  );
}
