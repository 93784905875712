import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import useTransformedHostname from '../../helpers/useTransformedHostname';
export default function CopyRight(props) {
  const transformedHostname = useTransformedHostname();
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="#">
        {transformedHostname} 
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  