import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ApiService from "../../APIService";
import RocketLaunch from "@mui/icons-material/RocketLaunch";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../../../tailwind.css";
import { fetchArgeToken, fetchArgeData } from "../api";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "../../footer/Footer";
import { GoogleLogin } from '@react-oauth/google';
import MobileDetailCard from "./MobileDetailCard";
import IconButton from "@mui/material/IconButton";
import CheckUserData from "../../general/MultiApplicationSenderMainTable/CheckUserData";
import { MainTableMobile } from "./MainTableMobile";
import DarkModeToggler from "../../general/DarkModeToggler";
import LinearProgress from '@mui/material/LinearProgress';
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const AdvancedTableMobile = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [advice, setAdvice] = useState([]);
  const [controllAdvice, setControllAdvice] = useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [modal, setModal] = React.useState(null);
  const [umkreis, setUmkreis] = useState(0);
  const [darkMode, setDarkMode] = useState(false);
  const [pagination, setPagination] = useState(1);

  const [arbeitgeber, /* setArbeitgeber */] = React.useState("");
/*   const [checkUser, setCheckUser] = React.useState(false);
 
  const [checked, setChecked] = React.useState(false); */
  const [argeToken, setArgeToken] = useState(null);
  const [maxErgebnisse, setMaxErgebnisse] = useState(null);

  const [arbeitszeit, setArbeitszeit] = React.useState({
    snw: false,
    vz: false,
    ho: false,
    tz: false,
    mj: false,
  });



  const [isLoading, setIsLoading] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const generaleDetailSiteLinks = () => {
    setModal(
      <CheckUserData
        selectedJobs={advice}
        modal={modal}
        setModal={setModal}
        darkMode={darkMode}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
      />
    );
  };

  const handleChangeArbeitszeit = (event) => {
    setArbeitszeit({
      ...arbeitszeit,
      [event.target.name]: event.target.checked,
    });
  };
  const [userData, setUserData] = useState({
    firstname: "No Name",
    lastname: "No Name",
    cv: "no cv",
  });


  useEffect(() => {
    updateUserData();
    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, []);







  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);



  const updateUserData = async () => {
    try {


      let username = localStorage.getItem("username");

      let user = null;
      if (username) {
         user = await ApiService.getUserData(username);
      }
  
      if (user.data === null) {
        let tmpUser = {
          firstname: "No Name",
          lastname: "No Name",
          cv: "no cv",
        };
  
        setUserData(tmpUser);
        return;
      }
      setUserData(user.data);
    } catch (error) {
   /*    if (!(error instanceof UserNotFoundError)) {
        console.error(error);
      } */
    }
  };


  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleSearchChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleUmkreisChange = (event) => {
    setUmkreis(event.target.value);
  };

  const argeApiCallBtn = () => {
    try {
      fetchArgeToken().then((token) => {
        setArgeToken(token);
        fetchArgeData(
          token,
          inputValue,
          location,
          umkreis,
          pagination,
          arbeitszeit
        ).then((data) => {
      
          if (data.stellenangebote) {
            

            setAdvice(data.stellenangebote);

            setControllAdvice(data.stellenangebote);

            setPagination(pagination + 1);
      
            setMaxErgebnisse(data.maxErgebnisse);

          } else {
            alert("Keine Stellenangebote gefunden");
          }
          
        });
      });
    } catch (error) {
    //  console.log(error);
    }
    setTimeout(() => {
      document
        .getElementById("advancedTable")
        .scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div
        style={{
          overflowX: "hidden",
          backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
        }}
      >
        {/* Modal für Multi Bewerbung */}
        {modal}

        <IntroTextMobile darkMode={darkMode} toggleDarkMode={toggleDarkMode} userData={userData} />


          <MainTableMobile
            inputValue={inputValue}
            handleSearchChange={handleSearchChange}
            location={location}
            handleLocationChange={handleLocationChange}
            umkreis={umkreis}
            handleUmkreisChange={handleUmkreisChange}
            argeApiCallBtn={argeApiCallBtn}
            expanded={expanded}
            handleExpandClick={handleExpandClick}
            arbeitszeit={arbeitszeit}
            handleChangeArbeitszeit={handleChangeArbeitszeit}
          />

        <div
          id="advancedTable"
          style={{ backgroundColor: `${darkMode ? "#1b1b1b" : "white"}` }}
        >
          <div style={{ height: "30px" }}></div>
          <Typography variant="h6" align="center" style={{color: darkMode ? "white" : "black"}} paragraph>
          {maxErgebnisse !== null ? `${maxErgebnisse} Stellenangebote gefunden` : null}   
                 </Typography>

          <Container sx={{ py: 8 }} maxWidth="md">
            <Grid
              container
              spacing={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
           {advice.length > 0 && advice.map((advice) => (
                <Grid item key={advice.refnr} xs={12} sm={6} md={4}>
                  <MobileDetailCard
                    arbeitgeber={advice.arbeitgeber}
                    arbeitgeberChar={arbeitgeber}
                    arbeitsortStrasse={advice.arbeitsort.strasse}
                    arbeitsortPlz={advice.arbeitsort.plz}
                    arbeitsortOrt={advice.arbeitsort.ort}
                    beruf={advice.beruf}
                    titel={advice.titel}
                    aktuellesVeroeffentlichungsdatum={
                      advice.aktuelleVeroeffentlichungsdatum
                    }
                    eintrittsdatum={advice.eintrittsdatum}
                    refnr={advice.refnr}
                    advice={controllAdvice}
                    userFirstname={userData.firstname}
                    userLastname={userData.lastname}
                    userCV={userData.cv}
                    userData={userData}
                    externeUrl={advice.externeUrl}
                    argeToken={argeToken}
                    logoHashId={advice.logoHashId}
                    darkMode={darkMode}
                    darkTheme={darkTheme}
                    lightTheme={lightTheme}
                    updatedAdvice={advice} // stellenausschreibung an sich.
                  ></MobileDetailCard>


                </Grid>
              ))}

              {pagination !== 1 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    p: "4px",
                  }}
                >
                  <React.Fragment>
                    <div className="flex items-center justify-center">
                      <button
                        disabled={advice.length === 0}
                        onClick={() => generaleDetailSiteLinks()}
                        style={{
                          marginTop: "25px",
                          marginLeft: "25px",
                          height: "100px",
                        }}
                        type="button"
                        className="rounded-full text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium text-sm px-2 py-2.5 text-center mr-2 mb-2 bg-green-500 dark:border-green-600 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-900"
                      >
                        <Tooltip
                          title="Sende mehrere deiner vorgemerkten Bewerbungen mit nur einem Klick - es werden nur Stellen geladen welche eine Email hinterlegt haben"
                          placement="top"
                        >
                          <Typography
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: `${darkMode ? "white" : "white"}`,
                            }}
                            align="center"
                            component="h1"
                            variant="h5"
                          >
                            <RocketLaunch
                              style={{ display: "flex", flexDirection: "row" }}
                            ></RocketLaunch>
                            Multibewerbung starten
                            <RocketLaunch
                              style={{ display: "flex", flexDirection: "row" }}
                            ></RocketLaunch>
                          </Typography>
                        </Tooltip>
                      </button>
                    </div>

{!isLoading ? (
                    <IconButton
  onClick={() => {
    setIsLoading(true); // Start loading animation
    setPagination((prevPagination) => {
      const newPagination = prevPagination + 1;
      fetchArgeToken().then((token) => {
        setArgeToken(token);
        fetchArgeData(
          token,
          inputValue,
          location,
          umkreis,
          pagination,
          arbeitszeit
        ).then((data) => {
          if (data.stellenangebote) {
            if (data.stellenangebote[0].arbeitsort.entfernung === undefined) {
              data.stellenangebote = data.stellenangebote.map((stellenangebot) => {
                if (stellenangebot.arbeitsort && !stellenangebot.arbeitsort.entfernung) {
                  stellenangebot.arbeitsort.entfernung = 0;
                }
                if (stellenangebot.arbeitsort && !stellenangebot.arbeitsort.ort) {
                  stellenangebot.arbeitsort.ort = " ";
                }
                if (stellenangebot.arbeitsort && !stellenangebot.arbeitsort.strasse) {
                  stellenangebot.arbeitsort.strasse = " ";
                }
                return stellenangebot;
              });
            }
            setAdvice((prevAdvice) => [...prevAdvice, ...data.stellenangebote]);
            setIsLoading(false); // End loading animation after set operations
          } else {
            alert("Keine Stellenangebote gefunden");
            setIsLoading(false); // End loading animation when no data found
          }
        }).catch(error => {
         // console.log(error);
          setIsLoading(false); // End loading animation when fetch operation fails
        });
      }).catch(error => {
       // console.log(error);
        setIsLoading(false); // End loading animation when fetch operation fails
      });
      return newPagination;
    });
  }}
>
  <div
    style={{
      width: "75%",
      border: `2px solid ${darkMode ? "white" : "black"}`,
      marginTop: "70px",
      borderRadius: "50%",
    }}
  >
    <ExpandMoreIcon
      style={{ color: `${darkMode ? "white" : "black"}` }}
    />
    <Typography
      variant="h6"
      style={{ color: `${darkMode ? "white" : "black"}` }}
    >
      weitere Jobs laden
    </Typography>
    <ExpandMoreIcon
      style={{ color: `${darkMode ? "white" : "black"}` }}
    />
  </div>
</IconButton>) : <LinearProgress /> }
                  </React.Fragment>
                </Box>
              )}
            </Grid>
          </Container>
        </div>

        <Router>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default AdvancedTableMobile;

function IntroTextMobile({ darkMode, toggleDarkMode, userData }) {



  const responseMessage = async (response) => {
    


    let myResponse = response;



    try {

      //const idToken = myResponse.credential;
      const idToken = myResponse.credential;

      localStorage.setItem('token', idToken);
  
      const apiResponse = await fetch(
        `https://data.bewerbr.de/api/v1/user/register`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/text' },
          body: idToken,
        }
      );
  
      const userData = await apiResponse.json();
      

      alert("Du hast dich erfolgreich registriert")

      //redirect to login 

      setTimeout(() => {
       // setLoadingAnimation(null);
         window.open("/login", "_self");
      }, 500);

      //login user and redirect to dashboard
      // accept abg required
       

    } catch (error) {
      console.error('Failed to register user:', error);
    }
  

  };

const errorMessage = (error) => {
    alert(error);
};



  return (

    
    <div>
      {!localStorage.getItem("username") ? (

    <Container
      maxWidth="sm"
      style={{ backgroundColor: `${darkMode ? "#1b1b1b" : "white"}` }}
    >
      <DarkModeToggler darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

      <div
        style={{
          backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
          height: "30px",
        }}
      ></div>

      <Typography
        component="h6"
        variant="h6"
        align="center"
        color={`${darkMode ? "white" : "#1b1b1b"}`}
        gutterBottom
      >
        Sende hunderte Bewerbungen mit nur einem Klick.
      </Typography>
      <Typography
        variant="p"
        align="center"
        color={`${darkMode ? "white" : "#1b1b1b"}`}
        paragraph
      >
        1. Erstell dir einen Account und logge dich ein. <br></br>
        2. Lade deine Bewerbungsunterlagen hoch. <br></br>
        3. Suche dir passende Stellen aus. <br></br>
        4. Klick auf Absenden und sende deine Bewerbungen. <br></br>
      </Typography>


<div style={{display:"flex", flexDirection:"column"}}>     

<GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> 

<Typography     variant="p" align="center"   paragraph color={`${darkMode ? "white" : "#1b1b1b"}`} style={{top:"10x", bottom:"10px"}}>oder </Typography>
      
      <Button href="/signup" variant="contained">
              Jetzt Registieren
            </Button>
</div>
 

      <Stack
        sx={{
          pt: 4,
        }}
        direction="row"
        spacing={2}
        justifyContent="center"
      ></Stack>
    </Container>
     ) : (
      <Typography
      component="h5"
      variant="h5"
      align="center"
      color={`${darkMode ? "white" : "black"}`}
      gutterBottom
    >
{/*       {'Hallo ' + userData.firstname} */}
      <DarkModeToggler darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
    </Typography>

    )}
  </div>
  );
}
