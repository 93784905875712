import { returnUsernameFromJWT } from "../../helpers/jwt";
//import Cookies from "js-cookie";





export const checkSubscriptionStatus = async () => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = localStorage.getItem("username");

  try {
    const response = await fetch(`https://data.bewerbr.de/api/subscriptions/getStatus/${usernameLS}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenLS}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    //
    
    
    
    return data;
  } catch (error) {
    console.error("Error fetching subscription status:", error);
    return error;
  }
};


export const getAIApplicationCounter = async () => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = localStorage.getItem("username");

  try {
    const response = await fetch(`https://data.bewerbr.de/api/v1/employee/e1/getAIApplicationCounter/${usernameLS}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenLS}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if(data > 40){
      window.location.reload();
    }
    return data;
  } catch (error) {
    console.error("Error fetching AI application counter:", error);
    return error;
  }
};

const setAIApplicationCounter = async () => {
  let tokenLS = localStorage.getItem("token");
  let username = localStorage.getItem("username");

  fetch(`https://data.bewerbr.de/api/v1/employee/e1/setApplicationCounter/${username}`, {
    method: "PUT",


    headers: {
      Authorization: `Bearer ${tokenLS}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {


    });
  }





export const generateAIApplicationText = async (
  applicationText,
  firstname,
  lastname,
  cv
) => {
  let finalOutput = "";

  const counter = await getAIApplicationCounter();
  if (counter >= 4) {
    finalOutput = "You have reached the maximum limit of AI-generated applications.";
    return finalOutput;

  } else {
    setAIApplicationCounter();

    let question1 =
      "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
      applicationText;
  
    let json = {
      model: "text-davinci-003",
      prompt: question1,
      temperature: 0,
      max_tokens: 2048,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  
    const res = await generateApplcationText(json);
    let parsedRes = JSON.parse(res);
  
    let promptConversation =
      "The following is a conversation with an AI assistant. The assistant is truthfull-, clever, and very friendly";
    let humanQ =
      "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
      applicationText;
    let aiA = parsedRes.choices[0].text;
  
    let humanQ2 =
      "Passe das Anschreiben auf den Lebenslauf von " +
      firstname +
      " " +
      lastname +
      " an: \n\n" +
      cv;
  
    let secondPrompt = {
      model: "text-davinci-003",
      prompt:
        promptConversation +
        " Human: " +
        humanQ +
        " AI: " +
        aiA +
        " Human: " +
        humanQ2,
      temperature: 0,
      max_tokens: 2048,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0.6,
      stop: ["Human:", "AI:"],
    };
  
    const secondRes = await generateApplcationText(secondPrompt);
    let secondParsedRes = JSON.parse(secondRes);
    let aiA2 = secondParsedRes.choices[0].text;
  
    finalOutput = aiA2;
    return finalOutput;

  }

  
};


export const generateApplicationText = async (applicationText) => {

  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;



  setAIApplicationCounter();

  getAIApplicationCounter();


  const response = await fetch(`https://api.openai.com/v1/chat/completions`, {

    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`, 
    },
    body: JSON.stringify(applicationText),
  });

  const text = await response.text();
  //console.log(text)
  return text;
};





export const generateApplcationText = async (applicationText) => {

  setAIApplicationCounter();

  getAIApplicationCounter();

  // Use an environment variable for the API key
  const apiKey = process.env.OPENAI_API_KEY;

  const response = await fetch(`https://api.openai.com/v1/completions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`, 
    },
    body: JSON.stringify(applicationText),
  });

  const text = await response.text();
  return text;
};


// export const generateAIApplicationText = (
//   applicationText,
//   firstname,
//   lastname,
//   cv
// ) => {
//   let finalOutput = "";

//   let question1 =
//     "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
//     applicationText;

//   let json = {
//     model: "text-davinci-003",
//     prompt: question1,
//     temperature: 0,
//     max_tokens: 2048,
//     top_p: 1,
//     frequency_penalty: 0,
//     presence_penalty: 0,
//   };

//   generateApplcationText(json).then((res) => {
//     let parsedRes = JSON.parse(res);

//     let promptConversation =
//       "The following is a conversation with an AI assistant. The assistant is truthfull-, clever, and very friendly";
//     let humanQ =
//       "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
//       applicationText;
//     let aiA = parsedRes.choices[0].text;

//     let humanQ2 =
//       "Passe das Anschreiben auf den Lebenslauf von " +
//       firstname +
//       " " +
//       lastname +
//       " an: \n\n" +
//       cv;

//     let secondPrompt = {
//       model: "text-davinci-003",
//       prompt:
//         promptConversation +
//         " Human: " +
//         humanQ +
//         " AI: " +
//         aiA +
//         " Human: " +
//         humanQ2,
//       temperature: 0,
//       max_tokens: 2048,

//       top_p: 1,
//       frequency_penalty: 0,
//       presence_penalty: 0.6,
//       stop: ["Human:", "AI:"],
//     };

//     generateApplcationText(secondPrompt).then((res) => {
//       let parsedRes = JSON.parse(res);
//       let aiA2 = parsedRes.choices[0].text;

//       finalOutput = aiA2;
//     });
//   });
//   return finalOutput;
// };

export const fetchArgeLogo = async (token, logoHashId) => {
  if (!logoHashId) {
    logoHashId = "00000000-0000-0000-0000-000000000000";
    return;
  }

  try {
    const myHeaders = new Headers();
    myHeaders.append("oauthaccesstoken", token);
    myHeaders.append("Accept", "image/gif,image/jpeg,image/jpg,image/png");
    const response = await fetch(
      `https://cors.bewerbr.de/rest.arbeitsagentur.de/jobboerse/jobsuche-service/ed/v1/arbeitgeberlogo/${logoHashId}`,
      {
        headers: myHeaders,
        method: "GET",
        mode: "cors",
      }
    );
    const data = await response.blob();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchArgeToken = async () => {
  try {
    const responseToken = await fetch(
      "https://cors.bewerbr.de/rest.arbeitsagentur.de/oauth/gettoken_cc",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          "Access-Control-Allow-Credentials": "true",
        },

        body: "grant_type=client_credentials&client_id=dcdeacbd-2b62-4261-a1fa-d7202b579848&client_secret=9b24cdad-5323-4c7a-a4cb-cf0e6db04958",
        method: "POST",
      }
    );
    const jsonToken = await responseToken.json();
    let token = jsonToken.access_token;
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const fetchArgeData = async (
  token,
  searchValue,
  location,
  radius,
  page,
  arbeitszeit
) => {
  let queryString =
    "angebotsart=1&was=" + searchValue + "&page=" + page + "&size=25&pav=false";

  let arbeitszeitString = "";
  if (arbeitszeit) {
    arbeitszeitString = Object.entries(arbeitszeit)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join(";");
  }

  if (location) {
    queryString += "&wo=" + location;
  }
  if (radius) {
    queryString += "&umkreis=" + radius;
  }
  if (arbeitszeit) {
    queryString += "&arbeitszeit=" + arbeitszeitString;
  }

  const url =
    "https://cors.bewerbr.de/rest.arbeitsagentur.de/jobboerse/jobsuche-service/pc/v4/jobs?" +
    queryString;

  try {
    const response = await fetch(url, {
      headers: {
        oauthaccesstoken: token,
      },
      method: "GET",
      mode: "cors",
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    return []; // Return empty array instead of throwing an error
  }
};

export const findUserByUsername = async () => {
  let usernameLS = returnUsernameFromJWT();
  let tokenLS = localStorage.getItem("token");
  const response = await fetch(
    `https://data.bewerbr.de/api/v1/employee/e1/findEmployeeByUsername/${usernameLS}`,
    {
      headers: {
        Authorization: `Bearer ${tokenLS}`,
      },
      method: "GET",
    }
  );

  try {
    const json = await response.json();
    return json;
  } catch (error) {
    const json = await response.text();
    return json;
  }
};

export const getLastApplication = async () => {
  let usernameLS = returnUsernameFromJWT();
  let tokenLS = localStorage.getItem("token");

  try {
    const response = await fetch(
      `https://data.bewerbr.de/api/v1/application/getRandomApplicationByUser/${usernameLS}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenLS}`,
        },
      }
    );

    const json = await response.json();
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const getApplicationByApplicationId = async (applicationId) => {
  let tokenLS = localStorage.getItem("token");

  try {
    const response = await fetch(
      `https://data.bewerbr.de/api/v1/application/findByApplicationid/${applicationId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenLS}`,
        },
      }
    );

    const json = await response.json();
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const updateApplicationText = async (applicationText, applicationid) => {
  let tokenLS = localStorage.getItem("token");
  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/updateApplication/${applicationid}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenLS}`,
      },
      body: JSON.stringify(applicationText),
    }
  );
  const json = await response.json();
  return json;
};

export const fetchEmailFromDetailSite = async (url) => {
  var mailArray = [];

  await fetch(url)
    .then(function (response) {
      return response.text();
    })
    .then(function (data) {
      let regexEmail =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      let email = data.match(regexEmail);
      if (email) {
        mailArray.push(url);
        mailArray.push(email[0]);
      } else mailArray.push(url);
      mailArray.push("Trage die Email manuell ein");
    });
  return mailArray;
};

/* export const fetchContent = async (url) => {

let shortText = "Keine Beschreibung vorhanden."
  await fetch(url).then(function (response) {
    return response.text();
  }).then(function (data) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(data, 'text/html');


    var elem = doc.getElementById("jobdetails-beschreibung");

    if(typeof elem !== 'undefined' && elem !== null) {
    shortText = doc.getElementById("jobdetails-beschreibung").innerHTML;

    }


  });
  return shortText;

} */
/* 
export const fetchEmailAndContent = async (url) => {
  let mailArray = [];
  let shortText = "Keine Beschreibung vorhanden.";

  await fetch(url).then(async (response) => {
    const data = await response.text();
    let regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    let email = data.match(regexEmail);
    if (email) {
      mailArray.push(url);
      mailArray.push(email[0]);
    } else {
      mailArray.push(url);
      mailArray.push("Trage die Email manuell ein");
    }
    var parser = new DOMParser();
    var doc = parser.parseFromString(data, 'text/html');
    var elem = doc.getElementById("jobdetails-beschreibung");
    if(typeof elem !== 'undefined' && elem !== null) {
    shortText = doc.getElementById("jobdetails-beschreibung").innerHTML;
    }


  });

  return { email: mailArray[1], shortText: shortText };
} */

export const fetchEmailAndContent = async (url) => {
  let mailArray = [];
  let shortText = " ";
  /*  let shortTextAG = " "; */
  let requirementsFaehigkeiten = [];
  await fetch(url).then(async (response) => {
    const data = await response.text();
    let regexEmail =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    let email = data.match(regexEmail);
    if (email) {
      mailArray.push(url);
      mailArray.push(email[0]);
    } else {
      mailArray.push(url);
      mailArray.push("Trage die Email manuell ein");
    }
    var parser = new DOMParser();
    var doc = parser.parseFromString(data, "text/html");
   // console.log("doc: ", doc.body);

    let elem = doc.querySelector("jb-steadetail-beschreibung");


    if (typeof elem !== "undefined" && elem !== null) {
      shortText = elem.innerHTML;
    }
    for (var j = 0; j <= 10; j++) {
      var requirementsList = doc.querySelectorAll(
        "[id='jobdetail-fertigkeiten-" + j + "-eintraege0']"
      );
      for (var i = 0; i < requirementsList.length; i++) {
        requirementsFaehigkeiten.push(requirementsList[i].innerHTML);
      }
    }
  });

  return {
    email: mailArray[1],
    shortText: shortText,
    requirementsFaehigkeiten:
      requirementsFaehigkeiten /* shortTextAG: shortTextAG */,
  };
};


/* Ansprechpartner reicht -  */
/* export const fetchEmailAndContent = async (url) => {
  let mailArray = [];
  let shortText = " ";
  let gender= " ";
let contactPerson= "";

  let requirementsFaehigkeiten = [];
  await fetch(url).then(async (response) => {
    const data = await response.text();
    let regexEmail =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    let email = data.match(regexEmail);
    if (email) {
      mailArray.push(url);
      mailArray.push(email[0]);
    } else {
      mailArray.push(url);
      mailArray.push("Trage die Email manuell ein");
    }

    let regexContactPerson = /(?:Frau|Herr)n?\s+((?:[A-ZÄÖÜ][a-zäöüß]+[\-\s]?)+)/; //(?:Frau|Herr)n?\s+([\w\s-]+)|\b([A-Z][a-z]+)\s+([A-Z][a-z]+)|Ansprechpartner:\s+([\w\s-]+)
    let regexGender = /(Frau|Herr)n?/;

     contactPerson = data.match(regexContactPerson);
     gender = data.match(regexGender);

    

    var parser = new DOMParser();
    var doc = parser.parseFromString(data, "text/html");

    var elem = doc.getElementById("jobdetails-beschreibung");
    if (typeof elem !== "undefined" && elem !== null) {
      shortText = doc.getElementById("jobdetails-beschreibung").innerHTML;
    }


    for (var j = 0; j <= 10; j++) {
      var requirementsList = doc.querySelectorAll(
        "[id='jobdetail-fertigkeiten-" + j + "-eintraege0']"
      );
      for (var i = 0; i < requirementsList.length; i++) {
        requirementsFaehigkeiten.push(requirementsList[i].innerHTML);
      }
    }
  });

  return {
    email: mailArray[1],
    shortText: shortText,
    requirementsFaehigkeiten:
      requirementsFaehigkeiten ,
      gender: gender,
      contactPerson: contactPerson
  };
};
 */

export const fetchEmailFromDetailSiteString = async (url) => {
  let detailSiteEmail = "Trage die Email manuell ein";
  await fetch(url)
    .then(function (response) {
      return response.text();
    })
    .then(function (data) {
      let regexEmail =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      let email = data.match(regexEmail);
      if (email) {
        detailSiteEmail = email[0];
      }
    });

  return detailSiteEmail;
};

export const postApplicationRequest = async (body) => {
  //https://data.bewerbr.de/api/v1/application/postapplication
  let tokenLS = localStorage.getItem("token");
  let username = returnUsernameFromJWT();



  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/multiplepostapplications/${username}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenLS}`,
      },
      body: JSON.stringify(body),
    }
  ).then(
    (resp) => resp.json()
  );
  return response;
};

//https://data.bewerbr.de/api/v1/application/insertMailByRefnr/10000-1189334451-S
export const insertEmailByRefnr = async (email, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = email;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertMailByRefnrAndUsername/${refnr}/${usernameLS}/`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the email");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const insertStreetByRefnr = async (street, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = street;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertStreetByRefnrAndUsername/${refnr}/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the street");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const insertPLZByRefnr = async (plz, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = plz;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertPLZByRefnrAndUsername/${refnr}/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the PLZ");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const insertOrtByRefnr = async (ort, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = ort;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertOrtByRefnrAndUsername/${refnr}/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the Ort");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const insertTitleByRefnr = async (title, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = title;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertTitleByRefnrAndUsername/${refnr}/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the title");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const insertCompanyByRefnr = async (company, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = company;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertCompanyByRefnrAndUsername/${refnr}/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the company");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const insertContactPersonByRefnr = async (contactPerson, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = contactPerson;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertContactPersonByRefnrAndUsername/${refnr}/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the contact person");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const insertGenderByRefnr = async (gender, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);



  if(gender == null || gender == undefined){
      gender = ""; // Use = instead of ==
  }

  var raw = gender;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertGenderByRefnrAndUsername/${refnr}/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the contact person");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const insertCommentByRefnr = async (comment, refnr) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = comment;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/application/insertCommentByRefnrAndUsername/${refnr}/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the comment");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};





export const insertMailByRefnrAndUsername = (email, refnr, username) => {
  let tokenLS = localStorage.getItem("token");
  return fetch(
    `https://data.bewerbr.de/api/v1/application/sendingApplications/${refnr}/${username}/`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${tokenLS}`,
      },
      body: email,
    }
  ).then(
    (resp) => resp.json()
  );
};

export const insertSenderEmailText = async (emailSenderText) => {
  let tokenLS = localStorage.getItem("token");
  let usernameLS = returnUsernameFromJWT();

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${tokenLS}`);

  var raw = emailSenderText;


  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    `https://data.bewerbr.de/api/v1/employee/e1/setEmailText/${usernameLS}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("An error occurred while updating the street");
      }
      return response.text();
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
  return response;
};

export const sendApplicationByApplicationId = (email, applicationid) => {
  let tokenLS = localStorage.getItem("token");
  return fetch(
    `https://data.bewerbr.de/api/v1/application/sendingApplications/${applicationid}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${tokenLS}`,
      },
      body: email,
    }
  ).then(
    (resp) => resp.json()

  );
};


export const sendApplicationByApplicationIdWithTmpText = (
  email,
  senderEmail,
  senderPasswort,
  applicationid
) => {
  let tokenLS = localStorage.getItem("token");

  let myUsername = returnUsernameFromJWT();


/*   console.log("calling endpoint with id: ", applicationid); */


  const requestBody = {
    email: email,
    senderEmail: senderEmail,
    senderPassword: senderPasswort,
    username: myUsername
  };

  return fetch(
    `https://data.bewerbr.de/api/v1/application/sendingTmpApplications/${applicationid}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${tokenLS}`,
      },
      body: JSON.stringify(requestBody),
    }
  )
    .then((resp) => resp.json())
    .then((resp) => {
      return resp;
    });
};

export const uploadAttachmentsAPICall = (formData) => {
  //https://data.bewerbr.de/api/v1/application/postapplication
  let tokenLS = localStorage.getItem("token");
  let username = returnUsernameFromJWT();

  return fetch(`https://data.bewerbr.de/uploadFile/${username}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenLS}`,
    },
    body: formData,
  }).then(
    (resp) => resp.json()
  );
};



//    "three": "^0.150.1",