import axios from "axios";
import { saveAs } from "file-saver";


export default class ApiService {

  static getUserData(username) {
    let token = localStorage.getItem("token");
    return axios
      .get(
        `https://data.bewerbr.de/api/v1/employee/e1/findEmployeeByUsername/${username}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((error) => {
        if (error) {
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          //window.location.href = "/login"; // Redirect to login page
        } else {
          throw error;
        }
      });
  }

  static getAllApplicationsByUsername() {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");
    return axios.get(
      `https://data.bewerbr.de/api/v1/application/getByUsername/${username}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static uploadAttachmentsAPICall = (formData) => {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");
    

    return fetch(`https://data.bewerbr.de/uploadFile/${username}`, {
      method: "POST",

      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
      //redirect: "follow",
    }).then(
      (resp) => resp.json()
    );
  };

  static uploadCV = (formData) => {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");


    return fetch(`https://data.bewerbr.de/uploadCV/${username}`, {
      method: "POST",

      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
      //redirect: "follow",
    }).then((resp) => resp.json()
   //.then((resp) => console.log(resp))
   );
  };

  static uploadProfilePicture = (formData) => {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");

    if (formData.get("file") !== null) {
      return fetch(
        `https://data.bewerbr.de/uploadProfilePicture/${username}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
          redirect: "follow",
        }
      )
      .then((resp) => resp.json())
      .catch((error) => {
       // console.error('Error:', error);
        throw error;  // throwing the error so that it can be caught in the catch block of the calling function
      });
    }
  };

  static deleteAllApplications() {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var raw = "";

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://data.bewerbr.de/api/v1/application/deleteApplicationByUsername/${username}`,
      requestOptions
    )
      .then((response) => response.text())
    //  .catch((error) => console.log("error", error));
  }

  static deleteApplication(applicationid) {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://data.bewerbr.de/api/v1/application/deleteApplicationByApplicationid/${applicationid}`,
      requestOptions
    )
      .then((response) => response.text())
     // .catch((error) => console.log("error", error));
  }


  static downloadFile(filename) {
    let token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      responseType: "blob",
    };
  
    return fetch(
      `https://data.bewerbr.de/downloadFile/${filename}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((myBlob) => {
  
        if (myBlob.size > 20) {
          const objectURL = URL.createObjectURL(myBlob);
          return objectURL;
        } else {
          alert("Lade eine PDF hoch!");
          return null;
        }
      });
  }
  

  static profilePicture(filename) {
    let token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      responseType: "blob",
    };

    fetch(
      `https://data.bewerbr.de/downloadFile/${filename}`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((myBlob) => {

      });
  }

  static updateUser(body) {
    return axios
      .put(
        "https://data.bewerbr.de/api/v1/employee/e1/updateEmployee",
        body
      )
      .then((resp) => {
        if (resp.status === 200) {
          alert("Registrierung erfolgreich");
          }
      })
      .catch((error) => {
        alert("Registierung nicht erfolgreich weil: " + error);
      });
  }

  static postmanUpdate(data) {
    let token = localStorage.getItem("token");
    let myData = JSON.stringify(data);
    return (
      axios.put(
        "https://data.bewerbr.de/api/v1/employee/e1/updateEmployee"
      ),
      myData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }



  static setApplicationTexts(data1) {
    let username = localStorage.getItem("username");
    let token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        applicationTextParagraph1: data1,
        //applicationTextParagraph2: data2,
      }),
    };

    fetch(
      `https://data.bewerbr.de/api/v1/employee/e1/setApplicationTexts/${username}`,
      requestOptions
    )
      .then((response) => response.json())
     // .then((result) => console.log(result))
     // .catch((error) => console.log("error", error));
  }

  static setTmpApplicationTextParagraph(tmpApplicationTextParagraph) {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");



    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: tmpApplicationTextParagraph,
      redirect: "follow",
    };

    fetch(
      `https://data.bewerbr.de/api/v1/employee/e1/setTmpApplicationTextParagraph/${username}`,
      requestOptions
    )
      .then((response) => response.text())
      //.then((result) => console.log(result))
     // .catch((error) => console.log("error", error));
  }
}
