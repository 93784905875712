import React from "react";
import useStyles from "../../components/general/Styles/MultiApplicationSenderTableStyles";
import {  Modal, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";


import { makeStyles, ThemeProvider } from "@mui/styles";





import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme({

});


export const ErrorModal = ({ text, subtext, errorModal, setErrorModal }) => {
  const classes = useStyles(theme);

  const redirectToRegister = () => {
    window.location.href = `/signup`;
  };

  const cancelModal = () => {
    setErrorModal(false);
  };

  return (
    <Modal open={true} aria-labelledby="exampleModalScrollableLabel" aria-hidden={true} className={classes.rootModal} id="exampleModalScrollable" tabIndex={-1} >
      <Box className={classes.box} component="form" noValidate>
        <Typography component="h1" variant="h5">
          {text}
        </Typography>
        <Grid item xs={12} sm={6}>
          <div id="separator" style={{ marginTop: "6%" }}></div>
        </Grid>

        <button style={{ marginTop: "6%" }} className="justify-left bg-transparent text-green-600 font-semibold py-2 px-4 border border-green-500 rounded hover:bg-green-600 hover:text-green-800 border-green-600" type="button" onClick={() => cancelModal()} >
          Nochmal versuchen
        </button>

        <Grid item xs={12} sm={6}>
          <button style={{ marginTop: "6%" }} className="justify-left bg-transparent text-green-600 font-semibold py-2 px-4 border border-green-500 rounded hover:bg-green-600 hover:text-green-800 border-green-600" type="button" onClick={() => redirectToRegister()} >
            Jetzt Registrieren
          </button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
