import React from "react";
import { Paper } from "@mui/material";
import DetailCardCompany from "./DetailCardCompany";
import MapChart from "./MapChart";
import RecipeReviewCard from "./DetailCard";

export const TableDetailDesktop = ({ advice, userData, argeToken, row }) => {
  return (
<div className="mx-2 grid grid-cols-2" style={{ gridTemplateColumns: '3fr 1fr', gap: '10px' }}> {/* Adjusted gap to 10px */}
  {/* Grid cell for RecipeReviewCard */}
  <div className="p-2 overflow-hidden">
    <RecipeReviewCard
      beruf={row.original.beruf}
      titel={row.original.titel}
      aktuellesVeroeffentlichungsdatum={row.original.aktuelleVeroeffentlichungsdatum}
      eintrittsdatum={row.original.eintrittsdatum}
      refnr={row.original.refnr}
      advice={advice}
      arbeitgeber={row.original.arbeitgeber}
      userFirstname={userData.firstname}
      userLastname={userData.lastname}
      userCV={userData.cv}
      userData={userData}
      externeUrl={row.original.externeUrl}
    />
  </div>

  {/* Grid cell for DetailCardCompany */}
  <div className="p-2 overflow-hidden">
    <DetailCardCompany
      arbeitgeber={row.original.arbeitgeber}
      arbeitsortStrasse={row.original.arbeitsort.strasse}
      arbeitsortPlz={row.original.arbeitsort.plz}
      arbeitsortOrt={row.original.arbeitsort.ort}
      logoHashId={row.original.logoHashId}
      argeToken={argeToken}
    />
  </div>
</div>

  );

{/*       <div className="flex-initial w-12"></div>
      <div className="flex-initial" style={{ width: "500px"}}>
        <Paper elevation={9} className="flex">
          <DetailCardCompany arbeitgeber={row.original.arbeitgeber} arbeitsortStrasse={row.original.arbeitsort.strasse} arbeitsortPlz={row.original.arbeitsort.plz} arbeitsortOrt={row.original.arbeitsort.ort} logoHashId={row.original.logoHashId} argeToken={argeToken} ></DetailCardCompany>
          <MapChart lon={row.original.arbeitsort.koordinaten.lon} lat={row.original.arbeitsort.koordinaten.lat} ></MapChart>
        </Paper>
      </div> */}

  /* Für kleine Desktops:*/

  
    /*                      <Grid container justify-content="center" alignItems="center">
      <Grid item xs={12} sm={4}>
        <Paper elevation={24}>
          <RecipeReviewCard beruf={row.original.beruf} titel={row.original.titel} aktuellesVeroeffentlichungsdatum={row.original.aktuelleVeroeffentlichungsdatum} eintrittsdatum={row.original.eintrittsdatum} refnr={row.original.refnr} advice={advice} arbeitgeber={row.original.arbeitgeber} userFirstname={userData.firstname} userLastname={userData.lastname} userCV={userData.cv} userData={userData} />
        </Paper>
  
        <div className="flex-initial" style={{ width: "500px" }}>
  
  <Paper elevation={12} className="flex">
    <DetailCardCompany arbeitgeber={row.original.arbeitgeber} arbeitsortStrasse={row.original.arbeitsort.strasse} arbeitsortPlz={row.original.arbeitsort.plz} arbeitsortOrt={row.original.arbeitsort.ort}></DetailCardCompany>
    <MapChart lon={row.original.arbeitsort.koordinaten.lon} lat={row.original.arbeitsort.koordinaten.lat}></MapChart>
  </Paper>
  
  </div>
      </Grid>
  
    </Grid> */
  

};

export default TableDetailDesktop;
