import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import PrevNextBtnDesktopTable from "./PrevNextBtnDesktopTable";
import TableDetailDesktop from "./TableDetailDesktop";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import MaterialReactTable from "material-react-table";
import { Box, Typography } from "@mui/material";
import Modal from "../../general/MultiApplicationSenderMainTable/CheckUserData";
import RocketLaunch from "@mui/icons-material/RocketLaunch";
import useWindowDimensions from "../../../helpers/UseWindowDimensions";
import Tooltip from "@mui/material/Tooltip";
import  Checkbox  from '@mui/material/Checkbox';
import GoogleLoginPopup from "./GoogleLoginPopup";
import FormControlLabel from '@mui/material/FormControlLabel';
import { zhCN } from "@mui/x-data-grid";
import { Z_BINARY } from "zlib";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

export const MainTableDesktop = ({ darkMode, setDarkMode, toggleDarkMode, columns, advice, MRT_Localization_DE, maxErgebnisse, pagination, setPagination, scrollToAdvancedTable, setArgeToken, setAdvice, processData, handleFetchArgeToken, fetchArgeData, inputValue, location, umkreis, userData, argeToken, }) => {
  
  const [modal, setModal] = React.useState(null); 
  const { width } = useWindowDimensions();
    const [isHidden, setIsHidden] = React.useState(false);
  useEffect(() => {
    // Select the elements using the class names in the XPath
    let elements = document.querySelectorAll('.MuiTableRow-root.MuiTableRow-hover');
  
    // Loop through the elements and set their display style depending on isHidden
    elements.forEach(element => {
      element.style.display = !isHidden ? "none" : "";
    });
  }, [advice, isHidden]) // also add isHidden to the dependency array
  

  const generaleDetailSiteLinks = () => {
    setModal(
      <Modal
        selectedJobs={advice}
        modal={modal}
        setModal={setModal}
        darkMode={darkMode}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
      />
    );
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <GoogleLoginPopup />
      {modal}
      <Typography
        style={{
          left: `${width / 2}px`,
          marginTop: "20px",
          color: darkMode ? "white" : "black",
        }}
        variant="h6"
        align="center"
        paragraph
      >
        {maxErgebnisse !== 0 && `${maxErgebnisse} Stellenangebote gefunden`}
      </Typography>

      <MaterialReactTable
        sx={{ zIndex: 100}}
        style={{ zIndex: 100}}
        columns={columns}
        data={advice}
        localization={MRT_Localization_DE}
        pageSize={100}
        enablePagination={false}
           renderRowData={() => null} 
        renderTopToolbarCustomActions={(table) => (

          <Box sx={{ display: "flex", gap: "1rem", p: "4px", }} >
            <IconButton onClick={toggleDarkMode}> {darkMode ? ( <div> <Typography> </Typography> <WbSunnyIcon /> </div> ) : ( <div> <Typography> </Typography> <DarkModeIcon /> </div> )} </IconButton>
           
            {advice.length > 0 ? (
             <FormControlLabel
              style={{marginTop: "10px", marginLeft: "10px"}}
             control={
               <Checkbox
                 checked={isHidden}
                 onChange={(event) => setIsHidden(event.target.checked)}
               />
             }
             label={!isHidden ? "Zeilen einblenden" : "Zeilen ausblenden"}
           />) : null}

            {advice.length > 0 && localStorage.getItem("username") !== null ? (
              <div /* style={{ marginLeft: `${(width - 580) / 2}px` }} */>
                <button disabled={advice.length === 0 || userData === null} onClick={() => generaleDetailSiteLinks()} style={{ marginTop: "25px", marginLeft: "25px", height: "50px", }} type="button" className="rounded-full text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium text-sm px-2 py-2.5 text-center mr-2 mb-2 bg-green-500 dark:border-green-600 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-900" >
                  <Tooltip title="Sende mehrere deiner vorgemerkten Bewerbungen mit nur einem Klick - es werden nur Stellen geladen welche eine Email hinterlegt haben" placement="top" >
                    <Typography style={{ display: "flex", flexDirection: "row", color: `${darkMode ? "white" : "white"}`, }} align="center" component="h1" variant="h5" >
                      <RocketLaunch style={{ display: "flex", flexDirection: "row" }} ></RocketLaunch>
                      Multibewerbung starten
                      <RocketLaunch style={{ display: "flex", flexDirection: "row" }} ></RocketLaunch>
                    </Typography>
                  </Tooltip>
                </button>
              </div>
            ) : 
            null
        }
          </Box>
        )}

        renderBottomToolbarCustomActions={() => (
          <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth="100%"
        
          >
            <React.Fragment>
     {/*          {pagination >= 2 && (
                <PrevNextBtnDesktopTable
                  pagination={pagination}
                  setPagination={setPagination}
                  scrollToAdvancedTable={scrollToAdvancedTable}
                  setArgeToken={setArgeToken}
                  setAdvice={setAdvice}
                  processData={processData}
                  handleFetchArgeToken={handleFetchArgeToken}
                  fetchArgeData={fetchArgeData}
                  inputValue={inputValue}
                  location={location}
                  umkreis={umkreis}
                  title={"Vorherige"}
                  prevOrNext={-1}
                />
              )} */}
              {pagination >= 1 && (
                <PrevNextBtnDesktopTable
                  pagination={pagination}
                  setPagination={setPagination}
                  scrollToAdvancedTable={scrollToAdvancedTable}
                  setArgeToken={setArgeToken}
                  setAdvice={setAdvice}
                  processData={processData}
                  handleFetchArgeToken={handleFetchArgeToken}
                  fetchArgeData={fetchArgeData}
                  inputValue={inputValue}
                  location={location}
                  umkreis={umkreis}
                  title={"Weitere Stellenangebote laden"}
                  prevOrNext={1}
                />
              )}
            </React.Fragment>
          </Box>
        )}
        positionToolbarAlertBanner="bottom"
        renderDetailPanel={({ row }) => (
          <Box  justifyContent="center" alignItems="center" maxWidth={"75vw"} marginLeft={"10%"}>
            <TableDetailDesktop advice={advice} userData={userData} argeToken={argeToken} row={row} />
          </Box>
        )}
      />
    </ThemeProvider>
  );
};

export default MainTableDesktop;
