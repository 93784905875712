import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "../../globalStyles";
import { blue } from '@mui/material/colors';




export const Nav = styled.nav`
  background: #121212;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 9999;

  @media screen and (max-width: 768px) {
    height: 60px;
    font-size: 1rem;
    z-index: 9999;

  }
`;


export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-start;
  height: 80px;

  @media screen and (max-width: 768px) {
    height: 60px;
  }

  ${Container}
`;
export const NavLogoImage = styled.img`

display: flex;


  @media screen and (max-width: 524px) {

    position: relative;
    display: flex;
    justify-self: flex-start;
    justify-content: flex-start;
    top: 20%;
    height: 35px;
    width: 35px;
`;



export const NavbarContainerEnd = styled(Container)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  height: 80px;
  pointer-events: none;
  ${Container}
`;

export const NavLogo = styled(Link)`
  color: #fff;
  margin-left: 5px;
  justify-self: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1224px) {
    font-size: 1.5rem;
    position: "absolute";
    display: flex;
    justify-self: flex-start;
    justify-content: flex-start;
  }
`;
/* export const IconPlay = styled(tableIcon)`
  margin-right: 0.5rem;
`;
 */
export const MobileIcon = styled.div`
  display: none;
  pointer-events: auto;
  @media screen and (max-width: 1224px) {
      pointer-events: auto;

    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
    pointer-events: auto;
    z-index: 9999;
    position: relative; /* Or absolute/fixed/sticky as needed */

  list-style: none;
  text-align: center;
  @media screen and (max-width: 1024px) {
    display: flex;
    pointer-events: auto;
    z-index: 9999;
    position: relative; /* Or absolute/fixed/sticky as needed */

    flex-direction: column;
    width: 90%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #121212;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid #4b59f7;
  }
  @media screen and (max-width: 1224px) {
    width: 100%;
    &:hover {
      border: none;
      background: white;
    }
  }
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 1224px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavLinks = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  @media screen and (max-width: 1224px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    &:hover {
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;


export const NavBtn = styled.button`
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
padding: 8px 16px;
height: 100%;
width: 100%;
border: none;
outline: none;
`;


export const RegisterButton = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? blue[500] : blue[700])};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    background: ${({ primary }) => (primary ? blue[700] : blue[500])};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
