import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {returnUsernameFromJWT} from "../../../helpers/jwt";
import PersonIcon from "@mui/icons-material/Person";
import SignpostIcon from "@mui/icons-material/Signpost";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import LockIcon from "@mui/icons-material/Lock";
import useWindowDimensions from "../../../helpers/UseWindowDimensions";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import ApiService from "../../APIService";
import Zoom from '@mui/material/Zoom';
import Cookies from "js-cookie";

import Tooltip from "@mui/material/Tooltip";
import LinearProgress from '@mui/material/LinearProgress';
export const ButtonStyled = styled(Button)((props) => ({
  borderRadius: "9999px",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  backgroundColor: "#3f51b5",
  color: "white",
  fontWeight: "bold",

  "&:hover": {
    backgroundColor: "#047857",
    color: "white",
  },
}));

export default function UserProfileMobile({ userData, loading, downloadAttachments, downloadCV, uploadProfilePic, userEditor, uploadBtn, editUserdata, userProfilePic, setProfilePic, handleSubmit, darkMode, darkTheme, lightTheme, loadingImage }) {
  const { width } = useWindowDimensions();

  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfUrlAttachments, setPdfUrlAttachments] = useState(null);
  
  const [numPages, setNumPages] = useState(null);
  const [numPagesAttachments, setNumPagesAttachments] = useState(null);
  
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfAttachments, setShowPdfAttachments] = useState(false);
  
  const [showModal, setShowModal] = useState(false);
  const [showModalAttachments, setShowModalAttachments] = useState(false);
  
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  
  const toggleModalAttachments = () => {
    setShowModalAttachments(!showModalAttachments);
  };
  
  const fetchPdf = async () => {
    let fileName = "CV_" + returnUsernameFromJWT() + ".pdf";
    const url = await ApiService.downloadFile(fileName);
    setPdfUrl(url);
  };
  
  const fetchPdfAttachments = async () => {
    let fileName = "Anlagen_" + returnUsernameFromJWT() + ".pdf";
    const url = await ApiService.downloadFile(fileName);
    setPdfUrlAttachments(url);
  };
  
  const handleButtonClick = () => {
    if (!pdfUrl) {
      fetchPdf();
    }
    setShowPdf(!showPdf);
    toggleModal();
  };
  
  const handleButtonClickAttachments = () => {
    if (!pdfUrlAttachments) {
      fetchPdfAttachments();
    }
    setShowPdfAttachments(!showPdfAttachments);
    toggleModalAttachments();
  };
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  function onDocumentLoadSuccessAttachments({ numPages }) {
    setNumPagesAttachments(numPages);
  }
  

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      className="items-center flex flex-col items-stretch justify-center"
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            {loading ? (
              <Skeleton
                width={width * 0.5}
                variant="rounded"
                animation="wave"
              />
            ) : (
              <h1
                id="username"
                style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
                className="items-left justify-left text-center text-3xl font-bold pt-8 lg:pt-0"
              >
                Hallo {userData.firstname}
              </h1>
            )}
          </Grid>
          <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-green-500 opacity-25" />

          <Grid item xs={12}>
            {loading ? (
              <div style={{ marginTop: "3%" }}>
                <Skeleton
                  width={width * 0.5}
                  variant="rounded"
                  animation="wave"
                />
              </div>
            ) : (
              <p
                style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
                className="pt-4 text-base font-bold flex items-left justify-left lg:justify-start"
              >
                <svg
                  className="h-4 fill-current text-green-700 pr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <PersonIcon></PersonIcon>
                </svg>
                {userData.firstname && userData.lastname ? (
                  `${userData.firstname} ${userData.lastname}`
                ) : (
                  <a color="red">Hinterlege Vor- und Nachnamen! </a>
                )}
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            {" "}
            {loading ? (
              <div style={{ marginTop: "3%" }}>
                <Skeleton
                  width={width * 0.5}
                  variant="rounded"
                  animation="wave"
                />
              </div>
            ) : (
              <p
                style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
                className="text-left pt-2 text-gray-600 text-xs lg:text-sm flex items-left justify-left lg:justify-start"
              >
                <svg
                  className="h-4 fill-current text-green-700 pr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <SignpostIcon></SignpostIcon>
                </svg>
                {userData.street ? (
                  userData.street
                ) : (
                  <p
                    className="text-left"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    Hinterlege deine Straße!
                  </p>
                )}
              </p>
            )}
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <div style={{ marginTop: "3%" }}>
                <Skeleton
                  width={width * 0.5}
                  variant="rounded"
                  animation="wave"
                />
              </div>
            ) : (
              <p
                style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
                className="pt-2 text-left text-xs lg:text-sm flex items-left justify-left lg:justify-start"
              >
                <svg
                  className="h-4 fill-current text-green-700 pr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <LocationCityIcon></LocationCityIcon>
                </svg>
                {userData.plz && userData.city ? (
                  `${userData.plz} ${userData.city}`
                ) : (
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Hinterlege deine PLZ und Stadt!
                  </p>
                )}
              </p>
            )}
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <div style={{ marginTop: "3%" }}>
                <Skeleton
                  width={width * 0.5}
                  variant="rounded"
                  animation="wave"
                />
              </div>
            ) : (
              <p
                style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
                className="pt-2 text-gray-600 text-xs lg:text-sm flex text-left items-left justify-left lg:justify-start"
              >
                <svg
                  className="h-4 fill-current text-green-700 pr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <AttachEmailIcon></AttachEmailIcon>
                </svg>{" "}
                {Cookies.get("senderEmail") !== null &&
            Cookies.get("senderEmail") !== undefined ? (
              Cookies.get("senderEmail")
            ) : (
              <p style={{ color: "red", fontWeight: "bold" }}>
               
               Du hast deine Outlook-Email für diese Session noch nicht hinterlegt!
              </p>
            )}
              </p>
            )}
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <div style={{ marginTop: "3%" }}>
                <Skeleton
                  width={width * 0.5}
                  variant="rounded"
                  animation="wave"
                />
              </div>
            ) : (
              <p
                style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
                className="pt-2 text-gray-600 text-xs lg:text-sm flex text-left items-left justify-left lg:justify-start"
              >
                <svg
                  className="h-4 fill-current text-green-700 pr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <LockIcon></LockIcon>
                </svg>{" "}
                {Cookies.get("senderPasswort") !== null &&
            Cookies.get("senderPasswort") !== undefined ? (
              Cookies.get("senderPasswort") ? (
                <div>*********</div>
              ) : (
                <p style={{ color: "red", fontWeight: "bold" }}>
                                Du hast deine Outlook-Passwort für diese Session noch nicht hinterlegt!

                </p>
              )
            ) : (
              <p style={{ color: "red", fontWeight: "bold" }}>
                Ein Outlook Password ist notwendig für die Automatische
                Bewerbung!
              </p>
            )}
              </p>
            )}
          </Grid>

          <Grid item xs={12}>




<div style={{display:"flex", flexDirection:"row"}}>
<div className="pt-12 pb-8 flex items-stretch justify-center">
      <Tooltip
        TransitionComponent={Zoom}
        followCursor
        title="Ein Lebenslauf ist zwingend erforderlich für die automatische Bewerbung"
      >
        <button
          id="downloadCV"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
          onClick={handleButtonClick}
        >
          Lebenslauf ansehen
        </button>
      </Tooltip>
      {showModal && (
  <div
    className="fixed z-10 inset-0 overflow-y-auto overflow-x-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        onClick={toggleModal}
      ></div>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-x-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
        <div>
          {pdfUrl && (
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              noData={<p>Lade deinen Laufhoch, dieser ist zwingend erforderlich für automatische Bewerbungen!</p>}
              loading={<p>Lade Lebenslauf...</p>}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
            onClick={toggleModal}
          >
            Schließen
          </button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>



          <div
            id="space"
            style={{
              backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
              width: "5%",
            }}
          ></div>

<div className="pt-12 pb-8 flex items-stretch justify-center">
  <Tooltip
    TransitionComponent={Zoom}
    followCursor
    title="Anlagen anzeigen"
  >
    <button
      id="downloadAttachments"
      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
      onClick={handleButtonClickAttachments}
    >
      Anlagen ansehen
    </button>
  </Tooltip>
  {showModalAttachments && (
  <div
    className="fixed z-10 inset-0 overflow-y-auto overflow-x-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        onClick={toggleModalAttachments}
      ></div>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-x-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
        <div>
          {pdfUrlAttachments && (
            <Document
              file={pdfUrlAttachments}
              onLoadSuccess={onDocumentLoadSuccessAttachments}
              noData={<p>Keine Anlagen gefunden!</p>}
              loading={<p>Lade Anlagen...</p>}
            >
              {Array.from(new Array(numPagesAttachments), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            onClick={toggleModalAttachments}
          >
            Schließen
          </button>
        </div>
      </div>
    </div>
  </div>
)}

</div>
                  

</div>


          </Grid>

          <Grid item xs={12}>
            <p
              style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
              className="pt-8 text-sm"
            >
              Bitte beachte, dass automatische Bewerbungen bisher nur mit
              Outlook oder Hotmail gesendet werden können!
            </p>
          </Grid>

          <Grid item xs={12}>
            <div className="items-center flex flex-col items-stretch justify-center">
              <ButtonStyled
                className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full"
                variant="contained"
                component="label"
                color="success"
                onClick={() => editUserdata()}
              >
                Profil bearbeiten
              </ButtonStyled>
              {userEditor}
            </div>
          </Grid>

          <Grid item xs={12} sx={{marginTop:"25%"}}>
            {userProfilePic === null ? (
              <Skeleton
                variant="circular"
                width={200}
                height={200}
                animation="wave"
                className="mx-auto"
              />
            ) : (
              <img
                className="rounded-full h-48 w-48 object-cover border-2 border-green-500 shadow-lg mx-auto -mt-16"
                src={userProfilePic}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {!userEditor ? (
              <div className="items-center flex flex-col items-stretch justify-center">
                {!uploadBtn ? (
                  <ButtonStyled
                    className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full"
                    variant="contained"
                    component="label"
                    color="primary"
                  >
                    Profilbild hochladen...
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept="image/jpeg,image/jpg,image/png"
                       onChange={(e) => setProfilePic(e)}
                    ></input>
                  </ButtonStyled>
                ) : (
                  <button
                    onClick={() => uploadProfilePic()}
                    className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full"
                  >
                    {" "}
                    Bestätigen!{" "}
                  </button>
                )}
              </div>
            ) : (
              <p></p>
            )}
          </Grid>

        </Grid>
      </Container>
      {loadingImage ? <div style={{display:"flex", flexDirection:"row"}}><p>Userdaten werden gespeichert...</p><LinearProgress/></div> : <p></p>}
    </Box>
  );
}
