import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";
import {
  postApplicationRequest,
  sendApplicationByApplicationIdWithTmpText,
} from "../../AutoApplication/api";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { returnUsernameFromJWT } from "../../../helpers/jwt.js";
// Generate Order Data
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";

import {Modal , Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import useWindowDimensions from "../../../helpers/UseWindowDimensions";

import Cookies from "js-cookie";

/* Austauschen mit Styles */
const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    overflowX: "auto",
    height: "100%",
    width: "100%",
    zIndex: 40,
    display: "flex",
    outline: "none",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100% - 3rem)",
      /*        overflowX: "scroll", */
      justifyContent: "flex-start",
    },
  },
  innerContainer: {
    position: "relative",
    width: "auto",
    height: "calc(100% - 3rem)",
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(6),
    pointerEvents: "none",
    overflow: "hidden",
  },
  innerPaper: {
    backgroundColor: "white",
    /*  padding: theme.spacing(4), */
    width: "100%",
    /*    minHeight: 800, */
    overflowX: "auto",
    height: "100%",
    maxHeight: "100%",
    border: "none",
    boxShadow: theme.shadows[6],
    display: "flex",
    flexDirection: "column",
    pointerEvents: "auto",
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflowX: "scroll",
      left: `calc(50% - 450px)`,
    },
  },
}));

const columns = [
  {
    accessorKey: "titel",
    header: "Titel",
    size: 120,
  },
  {
    accessorKey: "arbeitgeber",
    header: "Arbeitgeber",
    size: 123,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 123,
  },
  {
    //CELL FUNKTION
    Cell: ({ row }) => {
      const date = new Date(row.original.aktuelleVeroeffentlichungsdatum);
      return date.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    },
    accessorKey: "aktuelleVeroeffentlichungsdatum",
    header: "Veröffentlichungsdatum",
  },
  {
    Cell: ({ row }) => {
      const date = new Date(row.original.eintrittsdatum);
      return date.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    },
    accessorKey: "eintrittsdatum",
    header: "Eintrittsdatum",
    size: 220,
  },
];

/* function preventDefault(event) {
  event.preventDefault();
} */

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

export default function ApplicationRequestPreview({
  selectedJobs,
  applicationModal,
  setApplicationModal,
  modal,
  setModal,
}) {
  const classes = useStyles();

  const [advice, setAdvice] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {

   /*  console.log("selectedJobs", selectedJobs); */
    const storedDarkMode = localStorage.getItem("darkMode");

    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, [darkMode]);

  useEffect(() => {
    const filteredJobs = selectedJobs.filter(
      (job) => job.email !== "Trage die Email manuell ein"
    );
    setAdvice(filteredJobs);
  }, [selectedJobs]);

/*   const deleteSelectedRows = (rows) => {
    rows.map((row) => {
      ApiService.deleteApplication(row.original.refnr);
    });
  };
 */
  const handleExportRows = (rows) => {
    setLoading(
      <CircularProgressWithLabel
        value={0}
        max={100}
        loadingText={"Deine Bewerbungen werden abgesendet..."}
      />
    );
    var delay = 0;
    let sentApplicationsCount = 0;
    rows.forEach(function (row) {
      setTimeout(function () {
        postApplicationRequest([row.original]).then(() => {
          let applicationId = row.original.refnr + returnUsernameFromJWT();
          console.log("applicationId", applicationId)

          sendApplicationByApplicationIdWithTmpText(
            row.original.email,
          /*   "artur_pfeifer1@gmx.de", */
            Cookies.get("senderEmail"),
            Cookies.get("senderPasswort"),
            applicationId
          );
          sentApplicationsCount++;
          setLoading(
            <CircularProgressWithLabel
              value={(sentApplicationsCount / rows.length) * 100}
              max={100}
              loadingText={"Deine Bewerbungen werden abgesendet..."}
            />
          );
          if (sentApplicationsCount === rows.length) {
            setLoading(null);
            setApplicationModal(false);
            setModal(false);
            alert(
              "Deine Bewerbungen wurden erfolgreich abgeschickt, gehe zu deinem Profil um den Status zu prüfen"
            );
          }
        });
      }, 3000 + delay);
      delay += 3000;
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setApplicationModal(false);
    setModal(false);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {showModal ? (
        <Modal
          open={true}
          aria-labelledby="exampleModalScrollableLabel"
          aria-hidden={true}
          className={classes.root}
          id="exampleModalScrollable"
          tabIndex={-1}
          style={{
            zIndex: 100000,
            left: `calc(50% - ${width / 2}px)`,
            top: 0,
            height: "100%",
            width: "100%",
            backgroundColor: `${darkMode ? "black" : "white"}`,
          }}
        >
          <Paper
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: `${darkMode ? "black" : "white"}`,
            }}
            className={classes.innerPaper}
          >
            <MaterialReactTable
              enableFullScreenToggle={true}
              columns={columns}
              localization={MRT_Localization_DE}
              data={advice}
              enableRowSelection
              positionToolbarAlertBanner="bottom"
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                 
                 {loading !== null ? (
    <div>{loading}</div>
) : (
    <div style={{ display: "flex" }}>
        <Button
            color="success"
            disabled={
                !table.getIsSomeRowsSelected() &&
                !table.getIsAllRowsSelected()
            }
            onClick={() =>
                handleExportRows(table.getSelectedRowModel().rows)
            }
            startIcon={<RocketLaunchIcon />}
            endIcon={<RocketLaunchIcon />}
            variant="contained"
        >
            Bei ausgewählten Unternehmen bewerben
        </Button>
    </div>
)}




                </Box>
              )}
              renderBottomToolbarCustomActions={() => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "4px",
                  }}
                >
                  <React.Fragment>
                    <button
                      type="button"
                      onClick={() => closeModal()}
                      className="inline-block px-6 py-3 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                      data-bs-dismiss="modal"
                    >
                      Abbruch
                    </button>
                  </React.Fragment>
                </Box>
              )}
            />
          </Paper>
        </Modal>
      ) : null}
    </ThemeProvider>
  );
}
