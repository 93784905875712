import React, { useState, useEffect } from "react";
import "../../tailwind.css";
import { postApplicationRequest, sendApplicationByApplicationIdWithTmpText, updateApplicationText, } from "./api";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import useWindowDimensions from "../../helpers/UseWindowDimensions";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LoginRequiredModal from "../../helpers/NotificationModals/LoginRequiredModal";
import LinearProgress from "@mui/material/LinearProgress";
import Cookies from "js-cookie";
import EmailSenderModal from "../../helpers/NotificationModals/EmailSenderModal";
import Zoom from '@mui/material/Zoom';
import CircularProgress from '@mui/material/CircularProgress';


export function AITextPreview({ AIText, email, refnr, setAITextPreviwModal, userData, applicationData, }) {



  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const { height } = useWindowDimensions();
  const [darkMode, setDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSenderModal, setEmailSenderModal] = useState(true);

  const [charCount, setCharCount] = useState(0);


  useEffect(() => {
    //console.log("AIText", AIText)
    if (AIText) {
      // Split the text by line breaks
      const lines = AIText.split('\n');
    
      // Calculate the initial character count
      let initialCount = 0;
      for (let line of lines) {
        // Add the length of the line to the count
        initialCount += line.length;

        // If the line is shorter than 66 characters, add the remaining space
        if (line.length < 66) {
          initialCount += 66 - line.length;
        }
      }
    
      setCharCount(initialCount);
    }
  }, [AIText]);



  
  const handleTextChange = (event) => {
    // Split the text by line breaks
    const lines = event.target.value.split('\n');
    
    // Calculate the total character count
    let count = 0;
    for (let line of lines) {
      // Add the length of the line to the count
      count += line.length;

      // If the line is shorter than 66 characters, add the remaining space
      if (line.length < 66) {
        count += 66 - line.length;
      }
    }

    setCharCount(count);
  };

  const [showLoginRequiredModal, setShowLoginRequiredModal] = useState(true);

  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkMode");

    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, [darkMode]);

  /* Disable Button on click! */

  


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let aiText = data.get("aiText");
    applicationData.refLink = aiText;
    applicationData.applicationid = refnr;
 /*    const jsonString = JSON.stringify(applicationData); */
    //das eigentliche senden der Bewerbung
    updateApplicationText(applicationData, refnr); //refnr ist hier die applicationId

  };

  const sendApplicationClick = () => {
  
    /*   setIsLoading(true); */

    /* Sonst setLoading mit useRef auf den Btn. */
    sendApplication();
  };

  const sendApplication = async () => {
    //hier muss die application bereits geupdated sein




    if (
      Cookies.get("provider") == null ||
      Cookies.get("senderEmail") == null ||
      Cookies.get("senderPasswort") == null
    ) {
      setEmailSenderModal(
        <EmailSenderModal
          checkEmailCookie={"Deine Bewerbung wird gesendet..."}
          loginRequiredModal={EmailSenderModal}
          setLoginRequiredModal={setEmailSenderModal}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {

      if (
        userData.firstname === "No Name" ||
        userData.lastname === "No Name" ||
        userData.cv === "no cv" ||
        userData.cv === null ||
        userData.plz === null ||
        userData.street === null ||
        userData.city === null
      ) {
  
  
  
        let text = "Du musst dein Profil zuerst vervollständigen!";
    
  
        if (userData.cv === "no cv" || userData.cv === null) {
              text = "Du musst dein Profil zuerst vervollständigen! Lade ebenfalls deinen Lebenslauf hoch!";
        }
  
        setShowLoginRequiredModal(
          <LoginRequiredModal
            text={text}
            subtext={"Du kannst dein Profil auf dem Dashboard bearbeiten."}
            redirectPath={"Dashboard"}
            loginRequiredModal={showLoginRequiredModal}
            setLoginRequiredModal={setShowLoginRequiredModal}
            darkMode={darkMode}
            darkTheme={darkTheme}
            lightTheme={lightTheme}
          />
        );

        
      setIsLoading(false);
    } else {
      setIsLoading(true);
      await postApplicationRequest([applicationData]);
      
      const response = await sendApplicationByApplicationIdWithTmpText(
        email,
        Cookies.get("senderEmail"),
        Cookies.get("senderPasswort"),
        refnr
      );

      if (response.status === 500) {
        //alert("Bewerbung konnte nicht gesendet werden. Prüfe deine Daten!")
        setShowLoginRequiredModal(
          <LoginRequiredModal
            text={"Bewerbung konnte nicht gesendet werden. Prüfe deine Daten!"}
            subtext={
              "Du kannst deine Bewerbungen über das Bewerberboard verwalten."
            }
            redirectPath={"Dashboard"}
            loginRequiredModal={showLoginRequiredModal}
            setLoginRequiredModal={setShowLoginRequiredModal}
            darkMode={darkMode}
            darkTheme={darkTheme}
            lightTheme={lightTheme}
          />
        );
        setIsLoading(false);
      } else {
        setShowLoginRequiredModal(
          <LoginRequiredModal
            text={"Deine Bewerbung hat den Status: " + response.status + ""}
            subtext={
              "Du kannst deine Bewerbungen über das Bewerberboard verwalten."
            }
            redirectPath={"Dashboard"}
            loginRequiredModal={showLoginRequiredModal}
            setLoginRequiredModal={setShowLoginRequiredModal}
            darkMode={darkMode}
            darkTheme={darkTheme}
            lightTheme={lightTheme}
          />
        );
        setIsLoading(false);
      }

      setTimeout(() => {
        setAITextPreviwModal(false);
      }, 5000);
    }

  }
  };

  const closeModal = () => {
    setAITextPreviwModal(false);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
          {emailSenderModal}
      {showLoginRequiredModal}
      <div
        style={{ zIndex: 1300, height: "100%" }}
        className="fixed z-auto bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
      >
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          {isLoading ? <LinearProgress /> : null}
        </div>
        <div
          className={`bg-${
            darkMode ? "black" : "white"
          } rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-full sm:w-full`}
        >
          <div
            className={`bg-${
              darkMode ? "black" : "white"
            } px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
          >
            <h3
              style={{ marginTop: "5%" }}
              className={`text-lg leading-6 font-medium text-${
                darkMode ? "white" : "black"
              }`}
            >
              Sende deine Bewerbung jetzt an: {email}{" "}
            </h3>
            <div className="flex flex-col">
              {isLoading ? <LinearProgress /> : null}
              <Box
                style={{ width: "100%", height: "100%" }}
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                {/*      <div style={{backgroundColor:`${darkMode ? '#1b1b1b' : 'white'}`}}> */}

                <TextField
                  style={{
                    width: "100%",
                    height: height * 0.45,
                    overflowY: "scroll",
                    backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
                  }}
                  required
                  multiline
                  minRows={4}
                  rowsMax={20}
                  aria-label="maximum height"
                  autoComplete="given-name"
                  name="aiText"
                  defaultValue={AIText}
                  fullWidth
                  id="aiText"
                  autoFocus
                  onChange={handleTextChange} // Add this line
                />

{charCount > 1850 && 
  <div className="mt-4 text-red-500 font-bold">
    Warnung: Dein Anschreiben wird länger als eine Seite. Dies wird von vielen Personalern nicht gerne gesehen.
  </div>
}



   
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {isLoading ? <CircularProgress /> : (
                  <Tooltip TransitionComponent={Zoom}  followCursor title="Sende deine Bewerbung mit allen Anlagen zur vom Arbeitgeber hinterlegten Email">
                    <button
                      type="submit"
                      onClick={() => sendApplicationClick()}
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-800"
                    >
                           {Cookies.get("provider") == null || Cookies.get("senderEmail") == null || Cookies.get("senderPasswort") == null ? 
                        ("Email Provider auswählen") : ("Bewerbung abschicken")
                        }
                    </button>
                  </Tooltip>
                  
                  )}
                  <button


                    type="button"
                    onClick={closeModal}
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-800"
                  >
                    Verwerfen
                  </button>
                </div>
                {isLoading ? <LinearProgress /> : null}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default AITextPreview;
