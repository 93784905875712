import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Title from "../OverviewDashboard/Title";
import makeStyles from "@mui/styles/makeStyles";
import { List, ListItem, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import SubscriptionModal from "./SubscriptionModal";
import "../../../tailwind.css";
import { ThemeProvider } from "@mui/material/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

import { checkSubscriptionStatus } from "../../AutoApplication/api";
import PremiumSettingsModal from "./PremiumSettingsModal";

const PremiumAd = ({ darkMode, darkTheme, lightTheme, isPremium, subscriptionID }) => {

  


  useEffect(() => {
    checkSubscriptionStatus();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      backgroundColor: `${darkMode ? "black" : "white"}`,
    },
    listItem: {
      padding: theme.spacing(1, 2),
      backgroundColor: `${darkMode ? "black" : "white"}`,
    },
    darkModeText: {
      marginTop: 8,
      color: `${darkMode ? "white" : "black"}`,
    },
  }));

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(Array(6).fill(false));

  const [subscriptionModal, setSubscriptionModal] = React.useState(null);
  const [premiumSettingsModal, setPremiumSettingsModal] = React.useState(null);

  const handleExpandClick = (index) => {
    setExpanded((prev) => {
      prev[index] = !prev[index];
      return [...prev];
    });
  };

  const handleSubmit = () => {
    setSubscriptionModal(
      <SubscriptionModal
        subscriptionModal={subscriptionModal}
        setSubscriptionModal={setSubscriptionModal}
      />
    );
  };

  const handleSubmitPremiumSettings = () => {
    setPremiumSettingsModal(
      <PremiumSettingsModal
      premiumSettingsModal={premiumSettingsModal}
      setPremiumSettingsModal={setPremiumSettingsModal}
        subscriptionID={subscriptionID}
      />
    );
  };

  const renderListItem = (index, label, content) => (
    <ListItem className={classes.listItem}>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => handleExpandClick(index)}
            aria-expanded={expanded[index]}
            aria-label="show more"
          >
            {expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            <Typography 
            style={{fontWeight: "bold"}}
            color="text.primary"
            maxWidth="sm"
    
           variant="body2" className={classes.darkModeText}>

            
            {label}
          </Typography>
          <IconButton
            onClick={() => handleExpandClick(index)}
            aria-expanded={expanded[index]}
            aria-label="show more"
          >
            {expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          </IconButton>

        </div>
        <Collapse className={classes.root} in={expanded[index]}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              align="center"
              color="text.secondary"
              maxWidth="sm"
              variant="body2"
              className={classes.darkModeText}
            >
              {content}
            </Typography>
          </div>
        </Collapse>
      </div>
    </ListItem>
  );

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper
        className={classes.root}
        sx={{ p: 2, display: "flex", flexDirection: "column" }}
      >
        <Title  >
          { isPremium ? "Premium - Du kannst beliebig viele Bewerbungen senden!" : "Upgrade zu Premium - werde Premium Nutzer und sende unbegrenzt viele Bewerbungen mit nur einem Klick"}
    </Title>


    { !isPremium ? (

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <List className={classes.root}>
    {renderListItem(
      1,
      "Sende beliebig viele Bewerbungen",
      "Wähle als einer Liste deine Bewerbungen aus und sende sie alle mit nur einem Klick statt jede Bewerbung einzeln zu versenden. Als Premium Nutzer kannst du unbegrenzt viele Bewerbungen versenden. Normale Nutzer können nur 10 normale und 2 KI Bewerbungen pro Tag versenden."
    )}
    
    {renderListItem(
      2,
      "bald verfügbar",
      "Verschiedene Templates - Vorstellungsgesprächplanung - Bewerbungsmanagement"
    )}
  </List>
  
  <Button onClick={() => handleSubmit()} variant="outlined">
    Jetzt anfordern
  </Button>
</div>

    ) : (
  
      <Button onClick={() => handleSubmitPremiumSettings()} variant="outlined">
      Zu deinen Premiumeinstellungen
    </Button>
  
    )}

    {subscriptionModal}
    {premiumSettingsModal}
  </Paper>
</ThemeProvider>

);

};

export default PremiumAd;



