import React, {  useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { generateApplcationText, generateApplicationText } from "../../AutoApplication/api";
import Tooltip from "@mui/material/Tooltip";
import { LinearProgress } from "@mui/material";
import DropdownMenu from "./DropDown";
import AITextPreview from "../../AutoApplication/AITextPreview";
import Zoom from '@mui/material/Zoom';

export default function SingleApplicationSender({ advice, userData, setUserData, darkMode, darkTheme, lightTheme, }) {

  const [linearIndeterminate, setLinearIndeterminate] = useState(false);
  const [aiTextPreviewModal, setAiTextPreviewModal] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");

  const handleMenuItemClick = (value) => {
    setSelectedValue(value);
  };

  const filteredAdvice = advice.filter(
    (item) => item.email !== "Trage die Email manuell ein" && item.status !== "gesendet"
  );



const handleSubmit = async () => {

  if (userData.aiApplicationCounter >= 4 && !userData.user.isPremium) {
    alert("Du hast deine tägliche Anzahl an Bewerbungen überschritten. Bitte versuche es morgen erneut oder hol dir das Upgrade auf Premium");
  } else {

    if (userData.cv === null) {
      alert("Bitte füge zuerst einen Lebenslauf hinzu!");
    } else {
      setLinearIndeterminate(true);
      document
        .getElementById("sendAIApplicationButton")
        .setAttribute("disabled", "disabled");

      let applicationText = selectedValue.shortText;
      let firstname = userData.firstname;
      let lastname = userData.lastname;

      if (applicationText !== null) {
        applicationText = applicationText.substring(0, 512);
      }

      let question1 =
        "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
        applicationText;

      let json = {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "The following is a conversation with an AI assistant. Der Assistent hilft dabei, ein aussagekräftiges Anschreiben zu verfassen, welches den Mehrwert des Bewerbers für das Unternehmen hervorhebt."          },
          {
            role: "user",
            content: question1
          }
        ],
        temperature: 0.7,
        max_tokens: 2048,
      };

      generateApplicationText(json).then((res) => {
        let parsedRes = JSON.parse(res);


        let cv = userData.cv.substring(0, 1024); // Trim the CV to avoid exceeding the token limit
     
        let humanQ2 =
          "Passe das Anschreiben auf den Lebenslauf von " +
          firstname +
          " " +
          lastname +
          " an: \n\n" +
          cv;

        let secondPrompt = {
          model: "gpt-3.5-turbo",
          messages: [
            ...json.messages,
            {
              role: "assistant",
              content: parsedRes.choices[0].message.content.trim()
            },
            {
              role: "user",
              content: humanQ2
            }
          ],
          temperature: 0.7,
          max_tokens: 2048,
        };

        generateApplicationText(secondPrompt).then((res) => {
          let parsedRes = JSON.parse(res);

          if (parsedRes.choices[0].message.content === null) {
                        selectedValue.refLink = "Leider konnte kein Anschreiben generiert werden.";
          } else {
            selectedValue.refLink = parsedRes.choices[0].message.content;          }

            let parsedText2 = parsedRes.choices[0].message.content;
                      parsedText2 = parsedText2.replace(/^AI: /, "");

          if (parsedText2.includes("AI: ")) {
            parsedText2 = parsedText2.substring(6);
          }

          setAiTextPreviewModal( <AITextPreview
            AIText={parsedText2}
            email={selectedValue.email}
            refnr={selectedValue.applicationid}
            setAITextPreviwModal={setAiTextPreviewModal}
            userData={userData}
            applicationData={selectedValue}
          ></AITextPreview>
        );

        let dummyArray = []; //weil backend nur arrays akzeptiert
        dummyArray.push(selectedValue);

        setLinearIndeterminate(false);
        document.getElementById("sendAIApplicationButton").removeAttribute("disabled");
      });
    });
};
}
};

  /* --------------------------------------------------------------------------------------------------------------------------- */

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Container
        style={{
          borderRadius: "25px",
          backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
        }}
        component="main"
        maxWidth="xs"
      >
        {/* <CssBaseline /> */}
        <Box
          style={{
            borderRadius: "25px",
            backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`,
          }}
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <RocketLaunchIcon />
          </Avatar>
          <Typography
            style={{ color: `${darkMode ? "white" : "#1b1b1b"}` }}
            align="center"
            component="h1"
            variant="h5"
          >
            Lasse dein Anschreiben perfekt zugeschnnitten aus einer Kombination
            von Lebenslauf und Stellenanzeige generieren.
          </Typography>
          <br></br> <br></br>
          <Box noValidate sx={{ mt: 1 }}>
          
            <DropdownMenu
              menuItems={filteredAdvice}
              onMenuItemClick={handleMenuItemClick}
            />

            {selectedValue ? (
              <div>
                <br></br> <br></br>
                <Tooltip TransitionComponent={Zoom}  followCursor title="Bestätigen und KI-Anschreiben anzeigen lassen!">
                  {selectedValue.email === "Trage die Email manuell ein" ? (
                    <p>Wähle eine andere Stelle!</p>
                  ) : (
                    <button
                      id="sendAIApplicationButton"
                      style={{ width: "100%" }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none"
                      onClick={handleSubmit}
                    >
                      Anschreiben für {selectedValue.arbeitgeber} generieren
                      lassen!
                    </button>
                  )}
                </Tooltip>
                <br></br> <br></br>
                {linearIndeterminate ? <LinearProgress /> : null}
              </div>
            ) : null}
          </Box>
        </Box>
      </Container>

      {aiTextPreviewModal}
    </ThemeProvider>
  );
}
