import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { BrowserRouter as Router } from "react-router-dom";

import Footer from "../../footer/Footer";
import CardMedia from "@mui/material/CardMedia";
const theme = createTheme();


export default function About() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Container maxWidth="lg">
          <Typography variant="h4" component="h1" gutterBottom>
            <a href="https://pfeiferartur.web.app/">Über mich</a>
          </Typography>
        </Container>
      </AppBar>
      <Box sx={{ p: 4 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography
                style={{ color: "white" }}
                variant="h5"
                component="h2"
              >
                Unser Unternehmen
              </Typography>
              <Typography style={{ color: "white" }} variant="body1">
                Wir sind ein dynamisches Unternehmen, das sich auf die
                Entwicklung von Softwarelösungen spezialisiert hat. Seit unserer
                Gründung haben wir uns durch Innovation und Qualität einen Namen
                gemacht und arbeiten stets daran, unseren Kunden die
                bestmögliche Unterstützung zu bieten.
              </Typography>
              <Typography style={{ color: "white" }} variant="body1">
                Unser Team besteht aus erfahrenen Entwicklern und Designer, die
                sich durch Kreativität und Leidenschaft für ihre Arbeit
                auszeichnen. Wir arbeiten eng mit unseren Kunden zusammen, um
                ihre Bedürfnisse und Anforderungen zu verstehen und
                maßgeschneiderte Lösungen zu entwickeln.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                style={{ color: "white" }}
                variant="h5"
                component="h2"
              >
                Unser Team
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardMedia
                      image="/static/images/team1.jpg"
                      title=" Artur Pfeifer"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Artur Pfeifer
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        CEO
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardMedia
                      image="/static/images/team2.jpg"
                      title="Artur Pfeifer"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Artur Pfeifer
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        CTO
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardMedia
                      image="/static/images/team2.jpg"
                      title="Artur Pfeifer"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Artur Pfeifer
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        CMO
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardMedia
                      image="/static/images/team2.jpg"
                      title="Artur Pfeifer"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Artur Pfeifer
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        CFO
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Router>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
