import React from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import "../../../tailwind.css";
import SearchIcon from "@mui/icons-material/Search";
import Filter from "../DesktopStartPage/Filter";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  margin: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const MainTableMobile = ({
  inputValue,
  handleSearchChange,
  location,
  handleLocationChange,
  umkreis,
  handleUmkreisChange,
  argeApiCallBtn,
  expanded,
  handleExpandClick,
  arbeitszeit,
  handleChangeArbeitszeit,
}) => {
  return (



<Box className="items-center flex flex-col items-stretch justify-center">
          <Grid
            container
            spacing={2}
            className="items-center flex flex-col items-stretch justify-center"
          >
            <Grid
              className="items-center flex flex-col items-stretch justify-center"
              item
              xs={12}
            >
              <TextField
                id="outlined-basic"
                label="Job"
                variant="outlined"
                value={inputValue}
                onChange={handleSearchChange}
                sx={{
                  width: "95%",
                }}
              />
            </Grid>

            <Grid
              className="items-center flex flex-col items-stretch justify-center"
              item
              xs={12}
              style={{paddingLeft:"10px"}}
            >
              <TextField
                style={{
                  width: "80%",
               
                }}
                id="outlined-basic"
                label="Ort"
                variant="outlined"
                value={location}
                onChange={handleLocationChange}
              />
              <FormControl variant="outlined">
                <InputLabel id="radius-label">KM</InputLabel>

                <Select
                  style={{
                    width: "120%",
                  }}
                  labelId="radius-label"
                  id="demo-simple-select-outlined"
                  value={umkreis}
                  onChange={handleUmkreisChange} // IconComponent={KeyboardDoubleArrowDownIcon}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              className="items-center flex flex-col items-stretch justify-center"
              item
              xs={12}
            >
              <Button
                style={{
                  width: "95%",
                }}
                color="secondary"
                onClick={() => argeApiCallBtn()}
                startIcon={<SearchIcon />}
                variant="contained"
              >
                Jobs finden
              </Button>
            </Grid>
          </Grid>
   
        
    <Card

    >

      <CardActions
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        disableSpacing
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
          Filter
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Filter
            arbeitszeit={arbeitszeit}
            handleChangeArbeitszeit={handleChangeArbeitszeit}
          />
        </CardContent>
      </Collapse>
    </Card>
    </Box>
  );
};

export default MainTableMobile;
