import React, { useState, useEffect } from "react";

import { MRT_Localization_DE } from "material-react-table/locales/de";

import Button from "@mui/material/Button";
import ApiService from "../../APIService";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";
import { sendApplicationByApplicationIdWithTmpText } from "../../AutoApplication/api";
import { returnUsernameFromJWT } from "../../../helpers/jwt.js";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CircularProgressWithLabel } from "../MultiApplicationSenderMainTable/CircularProgressWithLabel";
import {  Modal, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useWindowDimensions from "../../../helpers/UseWindowDimensions";
import Cookies from "js-cookie";
const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    overflowX: "auto",
    height: "100%",
    width: "100%",
    zIndex: 40,
    outline: "none",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100% - 3rem)",
      /*        overflowX: "scroll", */
      justifyContent: "flex-start",
    },
  },
  innerContainer: {
    position: "relative",
    width: "auto",
    height: "calc(100% - 3rem)",
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(6),
    pointerEvents: "none",
    overflow: "hidden",
  },
  innerPaper: {
    backgroundColor: "white",
    width: "100%",
    overflowX: "auto",
    height: "100%",
    maxHeight: "100%",
    border: "none",
    boxShadow: theme.shadows[6],
    display: "flex",
    flexDirection: "column",
    pointerEvents: "auto",
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflowX: "scroll",
      left: `calc(50% - 450px)`,
    },
  },
}));

const columns = [
  {
    accessorKey: "titel",
    header: "Titel",
    size: 120,
  },
  {
    accessorKey: "arbeitgeber",
    header: "Arbeitgeber",
    size: 123,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 123,
  },
];

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

export default function ApplicationModalOrders({ modal, setModal, multiApplicationTextPreviewModal, setMultiApplicationTextPreviewModal, }) {

  const [darkMode, setDarkMode] = useState(false);
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(null);
  const [advice, setAdvice] = useState([]);
  const [showModal, /* setShowModal */] = useState(true);


  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkMode");

    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }
  }, [darkMode]);


  /* https://stackoverflow.com/questions/32079084/how-to-find-distinct-rows-with-field-in-list-using-jpa-and-spring */
  useEffect(() => {
    ApiService.getAllApplicationsByUsername().then((data) => {
      data.data.map((advices) => {
        if (
          advices.email !== "Trage die Email manuell ein" &&
          advices.status !== "gesendet"
        ) {
          setAdvice((advice) => [...advice, advices]);
        }
      });
    });
  }, []);

  const handleExportRows = (rows) => {
    let percent = 0;
    setLoading(
      <CircularProgressWithLabel
        value={percent}
        max={rows.length}
        loadingText={"Deine Bewerbungen werden versendet..."}
      />
    );

    var delay = 0;
    rows.forEach(function (row) {

      setTimeout(function () {




        let applicationId;
        if(row.original.refnr == "" || row.original.refnr == " " || row.original.refnr == null || row.original.refnr == undefined){
           applicationId = row.original.applicationid;

        } else{

    
        applicationId = row.original.refnr + returnUsernameFromJWT();
         }



        sendApplicationByApplicationIdWithTmpText(
          row.original.email,
          Cookies.get("senderEmail"),
          Cookies.get("senderPasswort"),
          applicationId
        ).then(() => {
          percent += 100 / rows.length;
          setLoading(
            <CircularProgressWithLabel value={percent} max={rows.length} />
          );
          if (percent >= 100) {
            setLoading(null);
            setModal(false);
            setMultiApplicationTextPreviewModal(false);
            alert(
              "Alle Bewerbungen wurden  automatisch abgeschickt, lade die Seite neu um die neuen Daten zu sehen."
            );
          }
        });
      }, 3000 + delay);
      delay += 3000;
    });
  };

  const closeModal = () => {
    setModal(false);
    setMultiApplicationTextPreviewModal(false);
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {showModal ? (
        <Modal
          open={true}
          aria-labelledby="exampleModalScrollableLabel"
          aria-hidden={true}
          className={classes.root}
          id="exampleModalScrollable"
          tabIndex={-1}
          style={{
            left: `calc(50% - ${width / 2}px)`,
            top: 0,
            height: "100%",
            width: "100%",
            backgroundColor: `${darkMode ? "black" : "white"}`,
          }}
        >
          <Paper
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: `${darkMode ? "black" : "white"}`,
            }}
            className={classes.innerPaper}
          >
            <MaterialReactTable
              enableFullScreenToggle={true}
              columns={columns}
              localization={MRT_Localization_DE}
              data={advice}
              enableRowSelection
              positionToolbarAlertBanner="bottom"
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >

{loading !== null ? (
    <div>{loading}</div>
) : (
    <div style={{ display: "flex" }}>
        <Button
            color="success"
            disabled={
                !table.getIsSomeRowsSelected() &&
                !table.getIsAllRowsSelected()
            }
            onClick={() =>
                handleExportRows(table.getSelectedRowModel().rows)
            }
            startIcon={<RocketLaunchIcon />}
            endIcon={<RocketLaunchIcon />}
            variant="contained"
        >
            Bei ausgewählten Unternehmen bewerben
        </Button>
    </div>
)}



                </Box>
              )}
              renderBottomToolbarCustomActions={() => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "4px",
                  }}
                >
                  <React.Fragment>
                    <button
                      type="button"
                      onClick={() => closeModal()}
                      className="inline-block px-6 py-3 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                      data-bs-dismiss="modal"
                    >
                      Abbruch
                    </button>
                  </React.Fragment>
                </Box>
              )}
            />
          </Paper>
        </Modal>
      ) : null}
    </ThemeProvider>
  );
}
