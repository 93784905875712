import React from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useWindowDimensions from "../../../helpers/UseWindowDimensions";
export const SearchBox = ({ inputValue, handleSearchChange, argeApiCallBtn, location, handleLocationChange, umkreis, handleUmkreisChange, KeyboardDoubleArrowDownIcon, arbeitszeit, handleChangeArbeitszeit, }) => {
  
  
  const { width } = useWindowDimensions();

  const getButtonWidth = () => {
    if (width >= 900 && width <= 1116) {
      return '350%';
    }
    return '70%';
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        p: "0.5rem",
        flexWrap: "wrap",
      }}
    >
      <TextField
        label="Job"
        variant="outlined"
        value={inputValue}
        onChange={handleSearchChange}
        style={{
          width: 360,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            argeApiCallBtn(1);
          }
        }}
      />

      <div>
        <TextField
          label="Ort"
          variant="outlined"
          value={location}
          onChange={handleLocationChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              argeApiCallBtn(1);
            }
          }}
        />
        <FormControl
          style={{
            width: "120px",
          }}
          variant="outlined"
        >
          <InputLabel id="radius-label">Umkreis</InputLabel>
          <Select
            labelId="radius-label"
            id="demo-simple-select-outlined"
            value={umkreis}
            onChange={handleUmkreisChange}
            IconComponent={KeyboardDoubleArrowDownIcon}
            placeholder="Umkreis"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                argeApiCallBtn();
              }
            }}
          >
            <MenuItem value={10}>10 KM</MenuItem>
            <MenuItem value={15}>15 KM</MenuItem>
            <MenuItem value={25}>25 KM</MenuItem>
            <MenuItem value={50}>50 KM</MenuItem>
            <MenuItem value={100}>100 KM</MenuItem>
            <MenuItem value={200}>200 KM</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div
        className="flex-row content-center justify-center items-center"
        style={{
          display: "flex",
        }}
      >
    <div className="content-center justify-center items-center">
      <Button
        style={{
          width: getButtonWidth(),
        }}
        color="secondary"
        type="submit"
        onClick={() => argeApiCallBtn(1)}
        startIcon={<SearchIcon />}
        variant="contained"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            argeApiCallBtn(1);
          }
        }}
      >
        Starte Jobsuche
      </Button>
    </div>

        {/*       <div>
        
                          {checkUser ? (
           <Button
             onClick={() => generaleDetailSiteLinks()}
             startIcon={<RocketLaunchIcon />}
             endIcon={<RocketLaunchIcon />}
             variant="contained"
             color="success">
             Autobewerbung
           </Button>
          ) :
           (
             <Button
               onClick={() => loginModal()}
               startIcon={<RocketLaunchIcon />}
               endIcon={<RocketLaunchIcon />}
               variant="contained"
               color="success">
               Autobewerbung
             </Button>
           )
                     }
        
      </div> */}
      </div>
    </Box>
  );
};

export default SearchBox;
