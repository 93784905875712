export const columns = [
  {
    accessorKey: 'beruf',
    header: 'Beruf',
    size: 120,
  },
  {
    accessorKey: 'titel',
    header: 'Titel',
    size: 120,
  },
  {
    accessorKey: 'arbeitgeber',
    header: 'Arbeitgeber',
    size: 300,
  },
  {
    //CELL FUNKTION
    Cell: ({ row }) => {
      const date = new Date(row.original.aktuelleVeroeffentlichungsdatum);
      return date.toLocaleDateString('de-DE', { day: '2-digit', month: 'long', year: 'numeric' });
    },
    accessorKey: 'aktuelleVeroeffentlichungsdatum',
    header: 'Veröffentlichungsdatum',
  },
  {
    Cell: ({ row }) => {
      const date = new Date(row.original.eintrittsdatum);
      return date.toLocaleDateString('de-DE', { day: '2-digit', month: 'long', year: 'numeric' });
    },
    accessorKey: 'eintrittsdatum',
    header: 'Eintrittsdatum',
    size: 220,
  },
  {
    accessorKey: 'arbeitsort.ort',
    header: 'Arbeitsort',
    size: 220,
  },
  {
    accessorKey: 'arbeitsort.entfernung',
    header: 'Entfernung in KM',
    size: 110,
  },

];

export default columns;
