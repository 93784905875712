import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import ApiService from "../../APIService";
import UserDataModal from "./UserDataModal";
import useWindowDimensions from "../../../helpers/UseWindowDimensions";
import UserProfileMobile from "./UserProfileMobile";
import UserProfileDesktop from "./UserProfileDesktop";
import UserProfilePicDesktop from "./UserProfilePicDesktop";
import { returnUsernameFromJWT } from "../../../helpers/jwt";

export const ButtonStyled = styled(Button)(() => ({
  borderRadius: "9999px",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  backgroundColor: "#3f51b5",
  color: "white",
  fontWeight: "bold",

  "&:hover": {
    backgroundColor: "#047857",
    color: "white",
  },
}));

export default function UserProfile({ userProfilePic, setUserProfilePic, loading, userData, setUserData, darkMode, darkTheme, lightTheme, }) {
  const [userEditor, setUserEditor] = useState(null);
  const [uploadBtn, setUploadBtn] = useState(false);
  const {  width } = useWindowDimensions();
  const [loadingImage, setLoadingImage] = useState(false);


  const editUserdata = () => {
    setUserEditor(
      <UserDataModal
        msg="Bearbeite dein Profil"
        userData={userData}
        userEditor={userEditor}
        setUserEditor={setUserEditor}
        setUserData={setUserData}
        darkMode={darkMode}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
      />
    );
  };
  const createRoundedImage = async (originalImage) => {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.onload = function() {
        let canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        let context = canvas.getContext('2d');
        
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, this.width / 2, 0, Math.PI * 2, true);
        context.closePath();
        context.clip();
  
        context.drawImage(this, 0, 0);
  
        canvas.toBlob(resolve, 'image/png'); // Specify PNG format here
      };
      image.onerror = reject;
      image.src = URL.createObjectURL(originalImage);
    });
  }
  
  
  const uploadProfilePic = async (formdataPic) => {
    setLoadingImage(true);
    try {
      let originalImage = formdataPic.get("file");
      let roundedImage = await createRoundedImage(originalImage);
      let roundedFormData = new FormData();
  
      // append the rounded image to the new FormData
      for (let [key, value] of formdataPic.entries()) {
        if (key === 'file') {
          roundedFormData.append(key, roundedImage, value.name);
        } else {
          roundedFormData.append(key, value);
        }
      }
  
      const response = await ApiService.uploadProfilePicture(roundedFormData);
      if ('fileName' in response && 'downloadUri' in response && 'size' in response) {
        setUserProfilePic(URL.createObjectURL(roundedImage));
      } else {
        alert("Fehler beim Hochladen des Profilbildes");
        throw new Error("Invalid response from the server");
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    } finally {
      setTimeout(() => {
        setLoadingImage(false);
      }, 500);
    }
  };





  const handleSubmit = (event) => {
    event.preventDefault();
    var formdataPic = new FormData();
    formdataPic.append("file", userProfilePic);
    uploadProfilePic(formdataPic);
  };
  const setProfilePic = (event) => {
    setUserProfilePic(event.target.files[0]);
    setUploadBtn(true);
  };

  const downloadCV = () => {
/*     let fileName = "CV_" + returnUsernameFromJWT() + ".pdf";
    ApiService.downloadFile(fileName); */
  };

  const downloadAttachments = () => {
/*     let fileName = "Anlagen_" + returnUsernameFromJWT() + ".pdf";
    ApiService.downloadFile(fileName); */
  };

  return (
    <div style={{ borderRadius: "25px", display: "block", backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`, }} >
      <div className="flex items-center" style={{ borderRadius: "25px", backgroundColor: `${darkMode ? "#1b1b1b" : "white"}`, }} >
        {width < 700 ? (
          <UserProfileMobile
            userData={userData}
            loading={loading}
            downloadAttachments={downloadAttachments}
            downloadCV={downloadCV}
            uploadProfilePic={uploadProfilePic}
            userEditor={userEditor}
            uploadBtn={uploadBtn}
            editUserdata={editUserdata}
            userProfilePic={userProfilePic}
            setProfilePic={setProfilePic}
            handleSubmit={handleSubmit}
            darkMode={darkMode}
            darkTheme={darkTheme}
            lightTheme={lightTheme}
            loadingImage={loadingImage}
          />
        ) : (
          <UserProfileDesktop
            userData={userData}
            loading={loading}
            downloadAttachments={downloadAttachments}
            downloadCV={downloadCV}
            uploadProfilePic={uploadProfilePic}
            userEditor={userEditor}
            uploadBtn={uploadBtn}
            editUserdata={editUserdata}
            userProfilePic={userProfilePic}
            setProfilePic={setProfilePic}
            darkMode={darkMode}
            darkTheme={darkTheme}
            lightTheme={lightTheme}
          />
        )}

        {width > 700 ? (
          <UserProfilePicDesktop
            userProfilePic={userProfilePic}
            userEditor={userEditor}
            uploadBtn={uploadBtn}
            handleSubmit={handleSubmit}
            setProfilePic={setProfilePic}
            uploadProfilePic={uploadProfilePic}
            darkMode={darkMode}
            darkTheme={darkTheme}
            lightTheme={lightTheme}
           loadingImage={loadingImage}

          ></UserProfilePicDesktop>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}
