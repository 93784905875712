import React, { useState, useEffect } from "react";
import { Box, TextareaAutosize, Paper, useTheme } from "@mui/material";
import Title from "../OverviewDashboard/Title";
import Button from "@mui/material/Button";
import { insertSenderEmailText } from "../../AutoApplication/api.js";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const EmailTextBox = ({ darkMode, userData, setUserData }) => {
  const firstname = userData.firstname; // Replace with your actual firstname data or state variable
  const lastname = userData.lastname; // Replace with your actual lastname data or state variable

  const emailText = userData.emailText;

  const greetingsName = `${firstname} ${lastname}`;

  const theme = useTheme();

  const handleSubmit = async () => {
    // Call the insertSenderEmailText method with the updated emailText
    try {
      await insertSenderEmailText(userData.emailText);
      // alert("Dein Emailtext wurde erfolgreich gespeichert.");
    } catch (error) {
      alert("Folgender Fehler trat auf: " + error.message);
    }
  };

  const handleChange = (e) => {
    setUserData({ ...userData, emailText: e.target.value });
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{ mt: 3 }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Title>Deine E-Mail an die Personalabteilung </Title>

      <Tooltip
        TransitionComponent={Zoom}
        followCursor
        title="Falls du in deinem Bewerberboard eine alternative Ansprache hinterlegt hast wird diese für deine Email verwendet!"
      >
        {/*               <TextareaAutosize maxRows={1} aria-label="maximum height" value={"Sehr geehrte Damen und Herren,"} readOnly autoComplete="given-name" name="text1" fullWidth id="text1" label="Absatz1" style={{ border: `2px solid ${ darkMode ? theme.palette.primary.main : theme.palette.primary.main }`, borderRadius: '10px', marginLeft: '5%', width: '90%', backgroundColor: `${darkMode ? '#808080' : 'white'}`, color: `${darkMode ? 'white' : 'black'}`, }} />
         */}

        <p style={{ color: `${darkMode ? "white" : "black"}` }}>
          Sehr geehrte Damen und Herren,
        </p>
      </Tooltip>
      <TextareaAutosize
        minRows={4} // Set the minimum number of rows
        maxRows={10} // Set the maximum number of rows (optional)
        aria-label="maximum height"
        placeholder="Schreibe deinen E-Mailtext hier"
        value={emailText}
        onChange={(e) => handleChange(e)}
        autoComplete="given-name"
        name="text1"
        fullWidth
        id="text1"
        label="Absatz1"
        style={{
          border: `2px solid ${
            darkMode ? theme.palette.primary.main : theme.palette.primary.main
          }`,
          borderRadius: "10px",
          marginLeft: "5%",
          width: "90%",
          // Removed the height property
          backgroundColor: `${darkMode ? "#808080" : "white"}`,
          color: `${darkMode ? "white" : "black"}`,
        }}
      />

      <p style={{ color: `${darkMode ? "white" : "black"}` }}>
        {greetingsName}
      </p>
      {/* 
            <TextareaAutosize
        maxRows={1}
        aria-label="maximum height"
        value={greetingsName}
        readOnly
        autoComplete="given-name"
        name="text1"
        fullWidth
        id="text1"
        label="Absatz1"
        style={{
          border: `2px solid ${
            darkMode
              ? theme.palette.primary.main
              : theme.palette.primary.main
          }`,
          borderRadius: '10px',
          marginLeft: '5%',
          width: '90%',
          backgroundColor: `${darkMode ? '#808080' : 'white'}`,
          color: `${darkMode ? 'white' : 'black'}`,
        }}
      /> */}
      <Button variant="outlined" onClick={() => handleSubmit()}>
        Speichern
      </Button>
    </Box>
  );
};

export default EmailTextBox;
