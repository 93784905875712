import React from "react";
import { Modal, Typography, Box, Grid } from "@mui/material";
import useStyles from "../../components/general/Styles/MultiApplicationSenderTableStyles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useWindowDimensions from "../UseWindowDimensions";


const theme = createTheme();
const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: '#81c784', // Light theme primary color
        // add light, dark, and contrastText if needed
      },
      secondary: {
        main: '#81c784', // Light theme secondary color
        // add light, dark, and contrastText if needed
      },
      // ...other colors like error, warning, info, success
    },
    // ...other theme customizations
  });
  
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: '#388e3c', // Dark theme primary color
        // add light, dark, and contrastText if needed
      },
      secondary: {
        main: '#388e3c', // Dark theme secondary color
        // add light, dark, and contrastText if needed
      },
      // ...other colors like error, warning, info, success
    },
    // ...other theme customizations
  });
  export const InfoModal = ({ title, content, tipp, open, setOpen, darkMode }) => {
    const classes = useStyles(); // Assuming useStyles is a hook that consumes the theme
    const { width, height } = useWindowDimensions();
    const closeModal = () => {
      setOpen(false);
    };
    const isMobile = height <= 600; // Define mobile as width of 600px or less
    // Select the correct theme based on darkMode
    const currentTheme = darkMode ? darkTheme : lightTheme;
  
    return (
    
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="info-modal-title"
          aria-describedby="info-modal-description"
          className={classes.rootModal}
          BackdropProps={{ style: { opacity: 0.5 } }} // Adjust the opacity as needed

        
        >
<Box
  sx={{
    position: 'fixed', // Ensure modal is fixed and overlays content
    top: '40%', // Better top positioning for all devices
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '60%' }, // 90% width on xs devices, 60% on sm and above
    maxHeight: '80vh', // Limit the height to 80% of the viewport height
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: { xs: 2, sm: 4 }, // Smaller padding on xs devices
    overflowY: 'auto', // Essential for scrolling
  }}
  component="form"
  noValidate
>

<Typography
  component="h1"
  variant="h5"
  id="info-modal-title"
  sx={{
    color: (theme) => theme.palette.text.primary, // Dynamically set based on theme
  }}
>
  {title}
</Typography>
<Typography
  variant="subtitle1"
  id="info-modal-description"
  sx={{
    marginTop: "20px",
    color: (theme) => theme.palette.text.primary, // Dynamically set based on theme
  }}
>
  {content}
</Typography>

<Typography
  variant="subtitle1"
  id="info-modal-description"
  sx={{
    marginTop: "20px",
    color: (theme) => theme.palette.text.primary, // Dynamically set based on theme
    border: "2px solid orange", // Adding an orange border
    padding: "8px", // Optional: Adds some padding inside the border, adjust as needed
    borderRadius: "4px", // Optional: Adds rounded corners to the border, adjust as needed
  }}
>
  {tipp}
</Typography>
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={6}>
                {/* Placeholder for any action buttons or content */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <button
                  style={{
                    marginTop: "6%",
                    color: currentTheme.palette.primary.contrastText,
                    backgroundColor: currentTheme.palette.primary.main,
                    borderColor: currentTheme.palette.primary.main,
                  }}
                  className="justify-left font-semibold py-2 px-4 rounded hover:bg-primary-dark" // Ensure you define or adjust these classes based on your CSS or MUI theme
                  type="button"
                  onClick={closeModal}
                >
                  Schließen
                </button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

    );
  };
  
  export default InfoModal;