import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red, yellow } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { fetchEmailAndContent, postApplicationRequest } from "../api";
import { returnUsernameFromJWT } from "../../../helpers/jwt.js";
import RocketLaunch from "@mui/icons-material/RocketLaunch";
import useWindowDimensions from "../../../helpers/UseWindowDimensions";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { generateApplcationText, generateApplicationText } from "../api";
import AITextPreview from "../AITextPreview";
import LinearProgress from "@mui/material/LinearProgress";
import ApplicationTextPreviewSender from "../ApplicationTextPreviewSender";
import { fetchArgeLogo } from "../api";
import PanToolIcon from "@mui/icons-material/PanTool";
import LoginRequiredModal from "../../../helpers/NotificationModals/LoginRequiredModal";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MobileDetailCard({ arbeitgeber, arbeitsortStrasse, arbeitsortPlz, arbeitsortOrt, beruf, titel, aktuelleVeroeffentlichungsdatum, eintrittsdatum, refnr, advice, userFirstname, userLastname, userCV, userData, externeUrl, argeToken, logoHashId, darkMode, darkTheme, lightTheme, updatedAdvice}) {
  const [expanded, setExpanded] = React.useState(false);
  let [shortText, setShortText] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [anchorElement, setAnchorElement] = React.useState(null);
  const [AITextPreviewModalOpen, setAITextPreviwModal] = useState(false);
  const [linearIndeterminate, setLinearIndeterminate] = useState(false);
  const [applicationTextEditor, setApplicationTextEditor] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loginRequiredModal, setLoginRequiredModal] = useState(null);
  let [requirementsArray, setRequirementsArray] = useState([]);

  const [imageSrc, setImageSrc] = useState("");

  const { height, width } = useWindowDimensions();




  useEffect(() => {
    const fetchData = async () => {
      const refLinkUrl =
        "https://cors.bewerbr.de/www.arbeitsagentur.de/jobsuche/jobdetail/";
      let refLinkFullUrl = refLinkUrl + refnr;
      setLoading(true);

      fetchEmailAndContent(refLinkFullUrl).then(
        ({ email, shortText, requirementsFaehigkeiten }) => {
          setEmail(email);
          setShortText(shortText);
          setRequirementsArray(requirementsFaehigkeiten);
          setLoading(false);
        }
      );
      if (!logoHashId) {
        return;
      }
      const response = await fetchArgeLogo(argeToken, logoHashId);
      const imgUrl = URL.createObjectURL(response);
      setImageSrc(imgUrl);
    };
    fetchData();
  }, [refnr, logoHashId, argeToken]);

  const openApplicationTextEditorModal = () => {
    setApplicationTextEditor(
      <ApplicationTextPreviewSender
        applicationTextEditor={applicationTextEditor}
        setApplicationTextEditor={setApplicationTextEditor}
        refnr={refnr}
        adviceJson={adviceJson}
        email={email}
        userFirstname={userFirstname}
        userLastname={userLastname}
        userData={userData}
      />
    );
  };

  const adviceJson = advice.map((item) => {
    let username = returnUsernameFromJWT();
    return {
      refnr: item.refnr,
      beruf: item.beruf,
      titel: item.titel,
      arbeitgeber: item.arbeitgeber,
      aktuelleVeroeffentlichungsdatum: item.aktuelleVeroeffentlichungsdatum,
      eintrittsdatum: item.eintrittsdatum,
      arbeitsort_ort: item.arbeitsort.ort,
      arbeitsort_plz: item.arbeitsort.plz,
      arbeitsort_strasse: item.arbeitsort.strasse,
      arbeitsort_region: item.arbeitsort.region,
      arbeitsort_land: item.arbeitsort.land,
      arbeitsort_entfernung: item.arbeitsort.entfernung,
      arbeitsort_koordinaten_lat: item.arbeitsort.koordinaten.lat,
      arbeitsort_koordinaten_lon: item.arbeitsort.koordinaten.lon,
      modifikationsTimestamp: item.modifikationsTimestamp,
      logoHashId: item.logoHashId,
      hashId: item.hashId,
      externeUrl: item.externeUrl,
      username: username,
      shortText: shortText,
      email: email,
    };

  });

 /*  async function sendAIApplication() {
    if (localStorage.getItem("username") === null) {
      // alert("Du musst dich zuerst einloggen");
      setLoginRequiredModal(
        <LoginRequiredModal
          text={"Du musst dich zuerst einloggen um KI Bewerbungen zu senden."}
          subtext={
            "Um Bewerberungen generieren und versenden zu können, musst du dich zuerst einloggen und deinen Lebenslauf hochladen. Aus einer Komibination aus deinem Lebenslauf und der Stellenanzeige wird eine Bewerbung generiert. Diese kannst du dann noch bearbeiten und versenden"
          }
          redirectPath={"Login"}
          loginRequiredModal={loginRequiredModal}
          setLoginRequiredModal={setLoginRequiredModal}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {
      let checkRef;
      document
        .getElementById("kiapplicationsend")
        .setAttribute("disabled", "disabled");
      let indexedAdvice = [];

      for (let i = 0; i < adviceJson.length; i++) {
     //   console.log("loop trough adviceJson to get application ", adviceJson[i].refnr, " and check if it matches the refnr of the clicked card");
        checkRef = adviceJson[i].refnr;

        if (checkRef === refnr) {
          indexedAdvice.push(adviceJson[i]);
          var jsonArray = JSON.parse(JSON.stringify(indexedAdvice));

          await postApplicationRequest(jsonArray);

          setLinearIndeterminate(true);
          shortText = shortText.substring(0, 512);
          let question1 =
            "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
            shortText;

          let json = {
            model: "text-davinci-003",
            prompt: question1,
            temperature: 0,
            max_tokens: 2048,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
          };

          generateApplcationText(json).then((res) => {
            let parsedRes = JSON.parse(res);

            let promptConversation =
              "The following is a conversation with an AI assistant. Der Assistent schreibt Anschreiben, wo er auf den Mehrwert des Bewerbers für das Unternehmen eingeht.";
            let humanQ =
              "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
              shortText;
            let aiA = parsedRes.choices[0].text;
            let cv = userCV;
            let humanQ2 =
              "Passe das Anschreiben auf den Lebenslauf von " +
              userFirstname +
              " " +
              userLastname +
              " an: \n\n" +
              cv;

            let secondPrompt = {
              model: "text-davinci-003",
              prompt:
                promptConversation +
                " Human: " +
                humanQ +
                " AI: " +
                aiA +
                " Human: " +
                humanQ2,
              temperature: 0.9,
              max_tokens: 2048,
              top_p: 1,
              frequency_penalty: 0,
              presence_penalty: 0.6,
              stop: [" Human:", " AI:"],
            };

            generateApplcationText(secondPrompt).then((res) => {
              let applicationid = refnr + returnUsernameFromJWT();

              let parsedRes = JSON.parse(res);
              setAITextPreviwModal(
                <AITextPreview
                  AIText={parsedRes.choices[0].text}
                  email={email}
                  refnr={applicationid}
                  setAITextPreviwModal={setAITextPreviwModal}
                  userData={userData}
                  applicationData={adviceJson[i]}
                ></AITextPreview>
              );
              setLinearIndeterminate(false);
              document
                .getElementById("kiapplicationsend")
                .removeAttribute("disabled");
            });
          });
        }
      }
    }
  }
 */



  async function sendAIApplication() {
    if (localStorage.getItem("username") === null) {
      // alert("Du musst dich zuerst einloggen");
      setLoginRequiredModal(
        <LoginRequiredModal
          text={"Du musst dich zuerst einloggen um KI Bewerbungen zu senden."}
          subtext={
            "Um Bewerberungen generieren und versenden zu können, musst du dich zuerst einloggen und deinen Lebenslauf hochladen. Aus einer Komibination aus deinem Lebenslauf und der Stellenanzeige wird eine Bewerbung generiert. Diese kannst du dann noch bearbeiten und versenden"
          }
          redirectPath={"Login"}
          loginRequiredModal={loginRequiredModal}
          setLoginRequiredModal={setLoginRequiredModal}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );
    } else {
      let checkRef;
      document
        .getElementById("kiapplicationsend")
        .setAttribute("disabled", "disabled");
      let indexedAdvice = [];
  
      for (let i = 0; i < adviceJson.length; i++) {
        checkRef = adviceJson[i].refnr;
  
        if (checkRef === refnr) {
          indexedAdvice.push(adviceJson[i]);
          var jsonArray = JSON.parse(JSON.stringify(indexedAdvice));
  
          await postApplicationRequest(jsonArray);
  
          setLinearIndeterminate(true);
          let applicationText = adviceJson[i].shortText.substring(0, 512);
  
          let question1 =
            "Schreibe mir ein Anschreiben basierend auf dem folgenden Text: \n\n" +
            applicationText;
  
          let json = {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content: "The following is a conversation with an AI assistant. Der Assistent hilft dabei, ein aussagekräftiges Anschreiben zu verfassen, welches den Mehrwert des Bewerbers für das Unternehmen hervorhebt."          },
              {
                role: "user",
                content: question1
              }
            ],
            temperature: 0.7,
            max_tokens: 2048,
          };
  
          generateApplicationText(json).then((res) => {
            let parsedRes = JSON.parse(res);
            
            let cv = userData.cv.substring(0, 1024); // Trim the CV to avoid exceeding the token limit
            let humanQ2 =
              "Passe das Anschreiben auf den Lebenslauf von " +
              userData.firstname +
              " " +
              userData.lastname +
              " an: \n\n" +
              cv;
  
            let secondPrompt = {
              model: "gpt-3.5-turbo",
              messages: [
                ...json.messages,
                {
                  role: "assistant",
                  content: parsedRes.choices[0].message.content.trim()
                },
                {
                  role: "user",
                  content: humanQ2
                }
              ],
              temperature: 0.7,
              max_tokens: 2048,
            };
  
            generateApplicationText(secondPrompt).then((res) => {
              let parsedRes = JSON.parse(res);
  
              if (parsedRes.choices[0].message.content === null) {
                adviceJson[i].refLink = "Leider konnte kein Anschreiben generiert werden.";
              } else {
                adviceJson[i].refLink = parsedRes.choices[0].message.content; 
              }
  
              let parsedText2 = parsedRes.choices[0].message.content;  

              parsedText2 = parsedText2.replace(/^AI: /, "");

if (parsedText2.includes("AI: ")) {
  parsedText2 = parsedText2.substring(6);
}
let applicationid = refnr + returnUsernameFromJWT();

setAITextPreviwModal(
  <AITextPreview
    AIText={parsedText2}
    email={email}
    refnr={applicationid}
    setAITextPreviwModal={setAITextPreviwModal}
    userData={userData}
    applicationData={adviceJson[i]}
  ></AITextPreview>
);

let dummyArray = []; //weil backend nur arrays akzeptiert
dummyArray.push(adviceJson[i]);

setLinearIndeterminate(false);
document
  .getElementById("kiapplicationsend")
  .removeAttribute("disabled");
          });
        });
      }
    }
  }
}


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (e) => {
    if (anchorElement === null) {
      setAnchorElement(e.target);
    } else {
      setAnchorElement(null);
    }
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  async function sendApplicationRequest() {


    if (!localStorage.getItem("username")) {
      // green("Du musst dich zuerst einloggen");
      setLoginRequiredModal(
        <LoginRequiredModal
          text={"Du musst dich zuerst einloggen um Bewerbungen vorzumerken."}
          subtext={
            "Vorgemerkte Bewerbungen werden unter deinem Profil gespeichert. Du kannst sie dann zu einem späteren Zeitpunkt bearbeiten und versenden."
          }
          redirectPath={"Login"}
          loginRequiredModal={loginRequiredModal}
          setLoginRequiredModal={setLoginRequiredModal}
          darkMode={darkMode}
          darkTheme={darkTheme}
          lightTheme={lightTheme}
        />
      );

      return;
    }

    await postApplicationRequest([updatedAdvice]); 

    setLoginRequiredModal(
      <LoginRequiredModal
        text={`Deine Bewerbung als ${updatedAdvice.titel} bei ${updatedAdvice.arbeitgeber} wurde unter deinem Profil vorgermerkt`}
        subtext={"Trage im Dashboard die Email ein um dich zu bewerben"}
        redirectPath={"Dashboard"}
        loginRequiredModal={loginRequiredModal}
        setLoginRequiredModal={setLoginRequiredModal}
        darkMode={darkMode}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
      />
    );

  }


  return (
    <div style={{ width: width * 0.9 }}>
      {loginRequiredModal}
      <Card sx={{ width: width * 0.9, alignItems: "center" }}>
        <CardHeader
          sx={{ width: width * 0.9 }}
          avatar={
            <Avatar
              sx={{ width: 50, height: 50, color: "white", bgcolor: red[500] }}
              aria-label="recipe"
            >
              {arbeitgeber && (
                <span>
                  {arbeitgeber.charAt(0).toUpperCase()}
                  {arbeitgeber.charAt(1).toUpperCase()}
                  {arbeitgeber.charAt(2).toUpperCase()}
                </span>
              )}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings" onClick={(e) => handleClick(e)}>
              <MoreVertIcon />
            </IconButton>
          }
          title={titel}
          subheader={`Gesucht zum ${new Date(eintrittsdatum).toLocaleDateString(
            "de-DE",
            { day: "2-digit", month: "long", year: "numeric" }
          )}`}
        />

        <Menu
          id="simple-menu"
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={handleClose}
        >
          {email !== "Trage die Email manuell ein" ? (
            <div>
              <MenuItem onClick={sendApplicationRequest}>
                <p className="text-white text-sm bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2">
                  Speichern & später Bewerben
                </p>
              </MenuItem>
              <MenuItem onClick={openApplicationTextEditorModal}>
                <p className="text-white text-sm  bg-gradient-to-r from-green-500 via-green-800 to-green-500 hover:bg-gradient-to-br hover:ring-4 focus:ring-4 focus:outline-none focus:ring-green-500 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2">
                  manuelles Anschreiben
                </p>
              </MenuItem>
              <MenuItem onClick={sendAIApplication}>
                <p className="text-white text-sm  bg-gradient-to-r from-green-500 via-green-800 to-green-500 hover:bg-gradient-to-br hover:ring-4 focus:ring-4 focus:outline-none focus:ring-green-500 dark:focus:ring-green-900 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2">
                  KI Anschreiben
                </p>
              </MenuItem>
            </div>
          ) : (
            <MenuItem>
              <p
                onClick={sendApplicationRequest}
                className="text-white text-sm bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2"
              >
                    Speichern & später Bewerben
              </p>
            </MenuItem>
          )}
        </Menu>
        {imageSrc ? (
          <CardMedia
            component="img"
            sx={{
              // 16:9

              objectFit: "cover",
              width: "100%",
              height: "100%",
              maxHeight: height * 0.3,
              maxWidth: width * 0.9,
            }} // specify styles
            image={imageSrc}
            alt="Logo"
          />
        ) : null}
        <CardContent>
          <Typography variant="body2" color="text.primary">
            {arbeitgeber}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {arbeitsortStrasse}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {arbeitsortPlz}&nbsp;{arbeitsortOrt}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {email === "Trage die Email manuell ein" ? (
            <div
              className="flex"
              onClick={() =>
                alert(
                  "Dieser Arbeitgeber hat keine Mail hinterlegt. Klick auf MEHR und speicher die Stelle in deinem Dashboard"
                )
              }
            >
              <PanToolIcon sx={{ color: yellow[800] }}></PanToolIcon>
            </div>
          ) : (
            <div
              className="flex"
              onClick={() =>
                alert(
                  "Klicke auf MEHR und bewerbe dich direkt mit einem manuellen oder ein vom KI generiertem Anschreiben"
                )
              }
            >
              <RocketLaunch color="success"></RocketLaunch>
            </div>
          )}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
            MEHR
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ width: width * 0.9 }}>
            <Typography paragraph style={{ whiteSpace: "pre-line" }}>
            <div dangerouslySetInnerHTML={{ __html: shortText }} />
              {linearIndeterminate ? <LinearProgress /> : null}
            </Typography>
            {requirementsArray.length > 0 ? (
              <Grid style={{ marginTop: "100px" }} container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    Gewünschte Kenntnisse:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={2}>
                    {requirementsArray.map((requirement, index) => (
                      <Grid item key={index}>
                        <button
                          style={{ maxWidth: "90%" }}
                          type="button"
                          className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                        >
                          {requirement}
                        </button>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <div> {loading}</div>

            <Typography
              variant="h6"
              color="textSecondary"
              style={{ whiteSpace: "pre-line" }}
            >
              Erzeuge dein Anschreiben:
            </Typography>

            <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-black">
              {" "}
            </div>
            <br></br>

            {email === "Trage die Email manuell ein" ? (
              <button
                onClick={() => sendApplicationRequest()}
                className="text-white text-base bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-5 py-3  text-center mr-2 mb-2"
              >
                Speichern & später Bewerben
              </button>
            ) : (
              <div className="items-center justify-center lg:justify-center" style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gridTemplateRows: "repeat(2, 1fr)", gap: "10px" }}>
  
              {/* ROW1 */}
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <IconButton onClick={openApplicationTextEditorModal}>
                  <Typography variant="body2" color="text.primary">
                    <Button
                      className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center mr-2 mb-2"
                      style={{color:"white"}}
                    >
                      {" "}
                      <RocketLaunch color="white"></RocketLaunch> Manuellem
                      Text
                    </Button>
                  </Typography>
                </IconButton>
                <IconButton id="kiapplicationsend" onClick={sendAIApplication}>
                  <Typography variant="body2" color="text.primary">
                    <Button
                      className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center mr-2 mb-2"
                      style={{color:"white"}}
                    >
                      {" "}
                      <PrecisionManufacturingIcon color="white"></PrecisionManufacturingIcon>
                      KI-Text
                    </Button>
                  </Typography>
                </IconButton>
              </div>
                          
              {/* ROW2 */}
              <div>
                <button
                  onClick={() => sendApplicationRequest()}
                  className="text-white text-base bg-gradient-to-r from-yellow-600 via-yellow-800 to-yellow-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-700 dark:focus:ring-yellow-800 font-medium rounded-lg text-sm px-5 py-3  text-center mr-2 mb-2"
                >
                  Speichern & später Bewerben
                </button>
              </div>
              
     
            </div>
            
            
            )}

            {externeUrl != null ? (
              <div>
                Du kannst dir die Stellenanzeige hier anschauen:{" "}
                <a
                  href={externeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  Zur Stellenanzeige
                </a>
              </div>
            ) : null}
          </CardContent>
        </Collapse>
        {AITextPreviewModalOpen}
              {applicationTextEditor}
      </Card>
    </div>
  );
}
