import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { BrowserRouter as Router } from 'react-router-dom'

import Footer from '../../footer/Footer';
/* const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
})); */

export default function Support() {
/*   const classes = useStyles(); */

  return (
    <div style={{ backgroundColor: "white" }} >
      <Typography variant="h4" gutterBottom>
        Support
      </Typography>
      <Typography variant="body1" gutterBottom>
        Wenn Sie Fragen oder Anregungen haben, können Sie uns gerne per Email unter <Link to="mailto:info@bewerbr.de">info@bewerbr.de</Link> kontaktieren. Wir werden uns schnellstmöglich bei Ihnen melden und Ihnen gerne weiterhelfen.
      </Typography>
      <Router>
        <Footer />
      </Router>
    </div>
  );
}